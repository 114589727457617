import { IS_DEV } from "./constants";
import { broadcaster } from "./utils/events";
import axios from 'axios';

const EVENT_ALLSPARK_MESSAGE_RECEIVED = 1;
const ALLSPARK_ENDPOINT = "https://nl0.pubsub.live"; // IS_DEV ? "https://localhost:8076": "https://nl0.pubsub.live";
var connection = null;
var current_connection_allspark_auth_key = null;

const AllsparkMessageFlags = {RESEND: 4}
function connectToAllsparkRt(_allspark_auth_key){
    if(_allspark_auth_key) window.localStorage?.setItem("allspark_auth_key", _allspark_auth_key);
    const allspark_auth_key = _allspark_auth_key || window.localStorage?.getItem("allspark_auth_key");

    if(_allspark_auth_key && current_connection_allspark_auth_key !== _allspark_auth_key){
        connection?.close();
        connection = null;
        current_connection_allspark_auth_key = null;
    }

    if(!allspark_auth_key || current_connection_allspark_auth_key) return;
    current_connection_allspark_auth_key = allspark_auth_key;

    //auth_key = auth_key.substring(0,auth_key.length-3);
    axios.get(
        ALLSPARK_ENDPOINT+"/connect?auth_key=" + encodeURIComponent(allspark_auth_key) + "&get_pre_connection_info=true"
    ).then(
        ({data}) => {
            if(allspark_auth_key !== current_connection_allspark_auth_key) return; // ignore if it's here and key changed
            var ws_url = "wss://" + data.server + "/connect?auth_key=" + encodeURIComponent(allspark_auth_key);
            connection = new WebSocket(ws_url);
            connection.onopen = function () {};
            connection.onerror = function (error) {
                current_connection_allspark_auth_key = null;
                setTimeout(() => connectToAllsparkRt(allspark_auth_key), 5000); // try again after 5 seconds
            };
            connection.onclose = function (e) {current_connection_allspark_auth_key = null;}
            connection.onmessage = function (e) {
                if(!e?.data) return;
                var msg = JSON.parse(e.data);
                if(msg.payload5) msg.data = JSON.parse(atob(msg.payload5));
                if(msg.payload6) msg.data1 = JSON.parse(atob(msg.payload6));
                msg._type = msg.type && parseInt(msg.type);
                msg.inbox_id = msg.dest_session_id;// 
                msg.created_at = parseInt(msg.timestamp);// could be string!, issue with protobuf to json
                msg.src_id = msg.x_src_id || msg.src_id;
                msg._id = msg.id;
                if(msg.flags) msg.flags = parseInt(msg.flags);
                broadcaster.broadcast_event(EVENT_ALLSPARK_MESSAGE_RECEIVED, msg);
            };
        }
    ).catch(
        () => { current_connection_allspark_auth_key = null; }
    );  
};

function isAllsparkConnected(){
    return connection && connection.readyState === connection.OPEN;
}

export {connectToAllsparkRt, EVENT_ALLSPARK_MESSAGE_RECEIVED, isAllsparkConnected, AllsparkMessageFlags}
