function binarySearch(arr, item, cmp=(a,b) => a-b){
    let low = 0;
    let high = arr.length;

    while (high - low > 1) {
        let mid = (low + high) >>> 1;
        if (cmp(arr[mid], item) <= 0) low = mid;
        else high = mid;
    }
    return low;
}

function sortedList(list, items, cmp=(a,b) => a-b) {
    /* insert in batches rather than changing array frequently, inplace */
    let splice_at_pos = {}
    for (let item of items) {
        let pos = binarySearch(list, item, cmp);
        if(list[pos] && cmp(item, list[pos]) > 0) pos++; // if item is greater than list[pos] then insert after pos
        let to_insert = splice_at_pos[pos]; 
        if(!to_insert) to_insert = splice_at_pos[pos] = [];
        to_insert.push(item);
    }
    let splice_at_pos_entries = Object.entries(splice_at_pos);
    splice_at_pos_entries.sort(([a], [b]) => b - a); // sort in descending order
    for (let [pos, items] of splice_at_pos_entries) {
        items.sort(cmp);
        list.splice(pos, 0, ...items);
    }
    return list;
}

function flatten(arr) {
    return arr.reduce((acc, val) => Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val), []);
}



export { sortedList, binarySearch, flatten };