import React from 'react'
import { useCurrentUser } from 'base/app';
import { TrackStaffLocation } from './StaffUser';

const RoutePlanStaffView = ({delivery_route_id}) => {
    const created_at = new Date().setUTCHours(0,0,0,0);
    const user = useCurrentUser();


    return (
        <div className='page-height tw-relative'>
            <TrackStaffLocation delivery_route_id={delivery_route_id} staff={user} date={new Date()} hide_summary={true} />
            {/* <div className='tw-px-4 tw-py-2 tw-absolute tw-bottom-0 tw-border-t tw-bg-white tw-w-full'>
                <button className='tw-bg-secondary-lighter tw-text-white tw-text-sm tw-px-4 tw-py-2 tw-rounded tw-w-full'>
                    Checkout
                </button>
            </div> */}
        </div>
    )
}

export default RoutePlanStaffView