import React from 'react';
import { broadcaster } from 'base/utils/events';

function NoSlotsShortView(){
    return (
        <div className='w3-center'>
            <span className='w3-bold w3-text-red tw-text-sm'>Delivery closed</span>
            <div className='w3-tiny'>Slots will open shortly, we will notify you</div>
        </div>
    );
}

export default NoSlotsShortView