import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { loadScript } from "base/utils/common";

import decorator from "../../../images/decorator.svg";
import FullSukhibaLogo from "../../../images/ic_logo_sukhiba.svg";
import bgBoxes from "../../../images/landing/boxes_bg.svg";
import bgPage1 from "../../../images/landing/page1_bg.svg";
import Footer from "../../../pages/landing/Footer";
import { InfoText } from "base/ui/misc";

function WhatsappLoginButton() {
	useEffect(() => {loadScript("/wa-login.js");}, []);

	return (
		<div
			className="tw-scroll-smooth tw-scrollbar-none font-figtree font-inter !tw-font-light"
			style={{ backgroundImage: "url(" + bgPage1 + ")", backgroundSize: "cover" }}
		>
			<div
				className="tw-sticky tw-pl-4 md:tw-pl-20 tw-pt-8 tw-cursor-pointer "
				onClick={() => window.open("https://sukhiba.com/home")}
			>
				<img src={FullSukhibaLogo} alt="sukhiba-logo" className="tw-h-[33px] max-md:tw-mb-6" />
			</div>
			<div
				id="home"
				style={{ backgroundImage: "url(" + bgPage1 + ")", backgroundSize: "cover" }}
				className="tw-relative lg:tw-flex md:tw-justify-center lg:tw-gap-16 max-lg:tw-py-8 tw-px-8 lg:tw-px-10 lg:tw-py-10"
			>
				<div className="tw-text-landing-black tw-font-semibold lg:tw-mt-14">
					<div className="tw-text-[36px] lg:tw-text-[48px] xl:tw-text-[58px] tw-leading-tight">
						<p className="tw-text-[#005BD3] tw-text-[36px] tw-flex tw-gap-2">
							Zero OTP Cost <LazyLoadImage src={decorator} alt="decorator" className="tw-mb-3" />
						</p>
						<p className="tw-text-landing-green">WhatsApp Login</p>
						<p className="md:tw-whitespace-pre">for your website</p>
					</div>
					<div className="sm:tw-whitespace-pre tw-mt-4 tw-font-light tw-text-[15px] md:tw-text-[15px]  sm:tw-text-[18px] lg:tw-text-[22px]">
						<p>Enjoy seamless login with WhatsApp on your website with</p>
						<p>
							<span className="tw-text-landing-black tw-font-semibold">ZERO COST for OTPs.</span> This service is
							completely free.
						</p>
					</div>
					<div className="login-by-sukhiba tw-my-7" data-onloginurl="/whatsapp-login" />
				</div>
				<div className="lg:tw-w-[750px]">
					<ul className="tw-flex tw-flex-col tw-text-[#666666] tw-font-light tw-text-md tw-bg-white tw-border tw-border-[#E3E3E3] tw-rounded-xl tw-mt-8">
						<li className="tw-px-9 tw-py-4 ">
							<p className="tw-text-primary-text tw-text-xl tw-font-semibold tw-mb-4">Step 1:</p>
							<p className="tw-text-[#666] tw-text-[18px] tw-font-medium">
								Copy the following script and paste it into your website where you need the login. You can modify the{" "}
								<span className="tw-text-[#005BD3]">data-onloginurl</span> to point to your url after logged in.
							</p>
							<pre
								className="tw-bg-[#FDFAEF] tw-p-4 tw-rounded-lg tw-text-sm tw-text-primary-text tw-my-4"
								style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
							>
								<code>
									{'<div class="login-by-sukhiba" data-onloginurl="https://your-api.com/doLogin"></div>'}
									<br />
									{'<script src="https://sukhiba.com/wa-login.js" async></script>'}
								</code>
							</pre>
						</li>
						<hr className="tw-bg-slate-400 tw-m-0" />
						<li className="tw-px-9 tw-py-4 ">
							<p className="tw-text-primary-text tw-text-xl tw-font-semibold tw-mb-4">Step 2:</p>
							<p className="tw-text-[#666] tw-text-[18px] tw-font-medium">
								After a successful login with whatsapp, the widget will redirect to the given URL along with{" "}
								<span className="tw-text-[#005BD3]">login_code</span> parameter.
							</p>
						</li>
						<hr className="tw-bg-slate-400 tw-m-0" />
						<li className="tw-px-9 tw-py-4">
							<p className="tw-text-primary-text tw-text-xl tw-font-semibold tw-mb-4">Step 3:</p>
							<p className="tw-text-[#666] tw-text-[18px] tw-font-medium">
								In your API, you can verify the login by calling {" "}
								<div className="tw-my-2">
									<div className="tw-text-[#005BD3] tw-p-1 tw-border tw-inline">
										https://sukhiba.com/api/wa/login?login_code=XXX
									</div>
								</div>
								<span>In response, you will receive the user&apos;s</span>{" "}
								<span className="tw-text-[#005BD3]">Phone Number,</span>{" "}
								<span className="tw-text-[#005BD3]">User Name</span>,{" "}
								<span className="tw-text-[#005BD3]">Country Phone Code</span>,{" "}
								<span className="tw-text-[#005BD3]">Country Code</span>,{" "}
								<span className="tw-text-[#005BD3]">City Name</span>
								<InfoText text={
									<pre className="tw-p-2 tw-m-2 tw-border">
										{
											JSON.stringify({
												"login_code": "Br37xvXX",
												"name": "Customer Name",
												"phone_number": "91768097XXXX",
												"country_phone_code": "91",  
												"national_number": "768097XXXX",
												"country_code": "IN",
												"city": "XXX"
											}, null, 2)
										}
									</pre>
								}
								/>
							</p>
						</li>
					</ul>
				</div>
			</div>
			<div
				className="tw-p-12 tw-bg-[#052F2F] tw-text-white tw-text-center lg:tw-mt-14"
				style={{
					backgroundImage: "url(" + bgBoxes + ")",
					backgroundRepeat: "repeat",
				}}
			>
				<h1 className="tw-text-[28px] md:tw-text-[36px] font-figtree-semibold md:tw-whitespace-pre tw-leading-tight">
					Try WhatsApp login flow using Sukhiba Bot and
					{"\ncomplete above steps to integrate."}
				</h1>
			</div>
			<Footer />
		</div>
	);
}

export default WhatsappLoginButton;
