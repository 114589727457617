/* crudely detects a swipe event on an element */
export function swipeDetect(el, cb) {
  var touch_start_time = 0;
  var touch_start_x = 0;
  var touch_start_y = 0;
  var mostly_swipe = 0;
  const onTouchStart = function (event) {
    if (event.changedTouches.length > 1) {
      return;
    }
    touch_start_x = event.changedTouches[0].clientX;
    touch_start_y = event.changedTouches[0].clientY;
    touch_start_time = new Date().getTime();
    mostly_swipe = 0;
  }

  const onTouchEnd = function (event) {
    if (event.changedTouches.length > 1) return;

    let touch_end_x = event.changedTouches[0].clientX;
    let touch_end_y = event.changedTouches[0].clientY;

    let elapsed_time = new Date().getTime() - touch_start_time;
    let x_diff = touch_start_x - touch_end_x;
    let y_diff = touch_start_y - touch_end_y;

    let x_diff_u = Math.abs(x_diff);
    let y_diff_u = Math.abs(y_diff);

    let speed_x = x_diff_u / elapsed_time;
    let speed_y = y_diff_u / elapsed_time;

    if (speed_x > 0.6 && speed_y < 0.6) {
      //swipe
      cb && cb(x_diff > 0 ? "left" : "right")
    }
  }

  el.addEventListener('touchstart', onTouchStart, false);
  el.addEventListener('touchend', onTouchEnd, false);

  // return cleanup function
  return function () {
    el.removeEventListener('touchstart', onTouchStart)
    el.removeEventListener('touchend', onTouchEnd);
  }
};
