import React from 'react'
import ProductPriceQty from './ProductPriceQty';

const SubProduct = ({config_index, price, cart, order,
    delivery_route, catalog_product, setCp, buying_for_user}) => {
    return (
        <div className='tw-bg-white tw-px-4 tw-py-2'>
            <ProductPriceQty
                key={config_index}
                source="from sub product"
                price={price}
                show_config_description={true}
                config_index={config_index}
                cart={cart} order={order}
                delivery_route={delivery_route}
                catalog_product={catalog_product}
                setCp={setCp}
                buying_for_user={buying_for_user}
            />
        </div>
    )
}

export default SubProduct