import axios from "axios";
import { useRef, useState } from "react";
import { GenericException } from "./ui/errors";
import { isImage } from "./utils/common";
import Clipboard from "./utils/clipboard";
import { ImageCompressor } from "./utils/image_compressor";
import { Popup } from "./ui/popups";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCopy } from '@fortawesome/free-solid-svg-icons'
import "./utils/polyfills/dndpolyfill.js"  /* load polyfill */
import { useCurrentUser } from "./app";
import { EmptyView } from "./ui/status";

const STANDARD_MIME_TYPE = {
    "image/vnd.microsoft.icon": "image/x-icon"
}


// get the cdn upload url, then uploads, shows progress
const uploadFile = function(file_data_obj, setStatus, setUploadProgress, files = [], setFiles){ 
    let file_name_splits = file_data_obj.name.split("/");
    let file_name = file_name_splits[file_name_splits.length-1];
    let file_mime_type = STANDARD_MIME_TYPE[file_data_obj.type] || file_data_obj.type;
    setStatus("Uploading...");
    axios.post(
        "/get_upload_url", 
        {"file_mime_type": file_mime_type, "file_name_hint": file_name}
    ).then(async (resp) => {
        if(file_mime_type !== resp.data.file_mime_type){//should have same extension as the generated url
            Popup.show("Error uploading", <GenericException ex={{"Error": "Mismatching mime types"}} />);
            return false;
        }
        let req = null;            
        if(!resp.data.upload_data.fields && resp.data.upload_data.method === "PUT"){
            // put request direct file data
            req = axios.put(resp.data.upload_data.url, file_data_obj, {
                headers: resp.data.upload_data.headers,
            })
        } else {
            // aws
            var form_data = new FormData();
            var upload_fields =  resp.data.upload_data.fields;
            for(var i in upload_fields){
                form_data.append(i, upload_fields[i]);
            }
            form_data.set("file", file_data_obj);    
            req = axios.post(resp.data.upload_data.url, form_data, {
                headers: resp.data.upload_data.headers,
                onUploadProgress: function(progress_event) {
                  var percent_completed = Math.round((progress_event.loaded * 100) / progress_event.total);
                  setUploadProgress(percent_completed);
                }
            });
        }
        setUploadProgress(0);

        req.then((resp_cdn_upload) => {
            setFiles([{"url": resp.data.file_url, "title": resp.data.title}, ...files]);
        }).catch((err)=>{
            Popup.show("Uploading Error", <GenericException ex={{"Error": err+""}} />)
        }).finally(() => {
            setUploadProgress(0);
        });
    }).catch((err)=>{
        Popup.show("Uploading Error", <GenericException ex={{"Error": err+""}} />)
    }).finally(() => {
        setStatus(null);
    });
}

const validateAndUploadFile = async ({
        file_data_obj, 
        allowed_mime_types, max_file_size, 
        preUploadValidator, no_compress, 
        setStatus = () => {}, 
        setUploadProgress = () => {},
        files,
        setFiles
    }) => {
    if(!file_data_obj){
        return; // nothing selected yet ? Unknown event
    }
    let file_mime_type = STANDARD_MIME_TYPE[file_data_obj.type] || file_data_obj.type;
    let is_valid_file = false;

    if(!allowed_mime_types || allowed_mime_types.length === 0){
        allowed_mime_types = ["image/jpeg", "image/png", "image/webp"];
    }
    for(let i=0;i<allowed_mime_types.length;i++){
        if(allowed_mime_types[i] === file_mime_type){
            is_valid_file = true;
        }
    }
    if(!is_valid_file){
        Popup.show(
            "Invalid File Type",
            <div className="w3-padding">
                Invalid file type, allowed: {allowed_mime_types.join(",")}
            </div>
        );
        return;
    }
    /* max file size */
    if(!max_file_size) max_file_size = 1024 * 1024 * 15; // 15MB
    if(file_data_obj.size > max_file_size){
        Popup.show(
            "File too large",
            <div className="w3-padding">
                File too large, max is {Math.round(max_file_size/1024)}KB
            </div>
        );
        return;
    }

    if(preUploadValidator){
        let _err = preUploadValidator(file_data_obj);
        if(!(_err === undefined || _err === true)){ 
            GenericException.showPopup(_err || "Invalid", "Cannot Upload this file");
            return;
        }
    }

    if((file_mime_type === "image/jpeg" || file_mime_type === "image/png" || file_mime_type === "image/webp" ) && !no_compress){
        setStatus("Optimizing..");
        new ImageCompressor(file_data_obj, {
            maxWidth: 1200,
            success(new_file_data_obj) {
                setStatus("Optimized..");
                uploadFile(new_file_data_obj, setStatus, setUploadProgress, files, setFiles);
            },
            error(e) {
                setStatus("Unable to optimize. Uploading as is..");
                uploadFile(file_data_obj, setStatus, setUploadProgress, files, setFiles);					    	
            },
        });            
    }
    else{
        uploadFile(file_data_obj, setStatus, setUploadProgress, files, setFiles);
    }
}

function FileUploader({
    className, files:_files, 
    allowed_mime_types, no_compress, onFilesUpdated,
    max_files, max_file_size, preUploadValidator
}){
    const [files, _setFiles] = useState(_files || []);

    const setFiles = (files) => {
        _setFiles(files);
        onFilesUpdated && onFilesUpdated(files);
    };

    const [status, setStatus] = useState(null);
    const [uploader_border_color, setUploaderBorderColor] = useState("black");
    const [upload_progress, setUploadProgress] = useState(null);
    const input_field = useRef();

    const onFileSelected = function(evt){
        let file_data_obj = evt.target?.files[0] || evt.files[0];
        input_field.current.value = ''; // clear the input field
        validateAndUploadFile({
            file_data_obj, 
            allowed_mime_types, 
            max_file_size, 
            preUploadValidator, 
            no_compress, 
            setStatus, 
            setUploadProgress,
            files,
            setFiles
        });
    };

    return (
        <div className={"w3-row " + className ? className : ""}>
            {
                status && <div className="w3-row w3-text-grey w3-center">{status}</div>
            }
            {
                files.length < (max_files || 1)
                ?   <div className="w3-row w3-margin-bottom-16" >
                        <input type="file" className="w3-display-none" ref={input_field} onChange={onFileSelected}/>
                        
                        <div className="w3-relative w3-margin-bottom-8" style={{"border": "1px dotted " + uploader_border_color, "minHeight": "100px", "width": "100%" }}
                            onClick={() => input_field.current.click()}
                            onDragEnter={(evt) => setUploaderBorderColor("green")}
                            onDragOver={(evt) => {evt.preventDefault(); setUploaderBorderColor("green");}}
                            onDragLeave={(evt) => setUploaderBorderColor("black")}
                            onDragEnd={(evt) => setUploaderBorderColor("black")}
                            onDrop={(evt) => {
                                evt.preventDefault();
                                setUploaderBorderColor("black");
                                evt.dataTransfer && evt.dataTransfer.files && onFileSelected(evt.dataTransfer);
                            }}
                        >
                            <div className="w3-display-middle w3-center">
                                <div className="w3-bold">Upload File</div>
                                <div className="w3-row w3-center w3-text-grey w3-small">
                                    Click/Drag drop
                                </div>
                            </div>
                        </div>
                        {
                            upload_progress
                            ?   <div className="w3-row w3-margin-bottom-8 w3-border w3-border-light-grey w3-round w3-overflow-hidden">
                                    <div style={{
                                            "backgroundColor": "green",
                                            //  just show small width percentage to display color
                                            "width": upload_progress + "%",
                                            "height": "10px",
                                        }}
                                    ></div>
                                </div>
                            :   null

                        }
                    </div>
                :   null
            }
            <ReorderableFiles files={files} setFiles={setFiles} />  
        </div>
    )
}


function ReorderableFiles({files, setFiles}){
    const [drag_id, setDragId] = useState();
    /* supports reordering files */
    const handleDrag = (evt) => {
        evt.dataTransfer.effectAllowed = "move";
        setDragId(parseInt(evt.currentTarget.getAttribute("index")));
    };

    const handleDrop = (evt) => {
        evt.stopPropagation && evt.stopPropagation();
        evt.stopImmediatePropagation && evt.stopImmediatePropagation();
        evt.preventDefault && evt.preventDefault();

        let j = parseInt(evt.currentTarget.getAttribute("index"));
        let temp = files[j];
        files[j] = files[drag_id];
        files[drag_id] = temp;
        setFiles([...files]);
    };
        
    return (
        <div className="w3-flex-row-wrap">
            {
                files.map((file, i) => {
                    let file_url = file.url || file || "";
                    return <div className="w3-relative w3-left w3-border w3-margin-sides-4" style={{maxWidth: '100%'}}
                        draggable="true"
                        onDragOver={(evt) => evt.preventDefault && evt.preventDefault()}
                        onDragStart={handleDrag}
                        onDrop={handleDrop}
                        key={file_url}
                        index={i}
                    >
                        {
                            isImage(file)
                            ?   <img className="w3-image" src={file_url} 
                                    style={{"width": "auto", "maxHeight": "200px"}} 
                                    alt="Unable to render"
                                />
                            :   <div className="w3-padding w3-relative w3-border w3-bold"
                                    style={{"width": "auto", "maxHeight": "200px"}} 
                                >
                                    <div className="">
                                        <a href={file_url} target="_blank" rel="noreferrer" className="w3-can-break">
                                            {"Download " + file_url.replace(/^.*[\\/]/, '')}
                                        </a>
                                    </div>
                                </div>
                        }
                        <div className="w3-display-topright w3-white w3-flex-row-wrap w3-list-horizontal w3-padding-left-4" >
                            <FontAwesomeIcon icon={faCopy} onClick={() => Clipboard.copy(file_url) } />
                            <FontAwesomeIcon icon={faTimes} onClick={() => {files.splice(i, 1);setFiles([...files])}} />
                        </div>                    
                    </div>
                })
            }
        </div>
    )
}

function FileUploaderPage(){
    const user = useCurrentUser();
    if(!user){
        return <EmptyView title="You need to be logged in" height={"300px"} />
    }
    return (
        <div className="w3-occupy">
            <div className="w3-expand w3-relative">
                <div className="w3-display-middle w3-bounded" style={{"width": "500px"}}>
                    <FileUploader max_files={5} />
                </div>
            </div>
        </div>
    )
}

export {FileUploader, FileUploaderPage, validateAndUploadFile};
