import { faDollar, faEllipsisV, faMagicWandSparkles, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
    ChatSession, INBOX_MESSAGE_TYPE_WA_INCOMING_MSG,
    INBOX_MESSAGE_TYPE_WA_MSG,
    SESSION_USER_ADMIN, SimpleMessageContextMenu
} from "base/ui/chat";
import { GenericException } from "base/ui/errors";
import { SelectTags } from "base/ui/misc";
import { Popup } from "base/ui/popups";
import { round } from "base/utils";
import { getRandomColor, useRerender } from "base/utils/common";
import { useNavigate } from "react-router-dom";
import AddNewCustomer from "./AddNewCustomer";
import DeliveryRouteUserChatButtons from "./DeliveryRouteUserChatButtons";
import { broadcaster } from "base/utils/events";
import { DateView } from "base/ui/date";
import { FormErrorText } from "../../ui/FormErrorText";
import { ReactComponent as PointsIcon } from '../../../images/ic_points.svg';
import { getPrice } from "../../../helpers/StoreHelper";
import { MILLIS_IN_A_DAY } from "base/constants";
import { useCurrentUser } from "base/app";
import CustomerTagsChatIndicator from "./CustomerTagsChatIndicator";
import { WalletLedgerEntries } from "base/payments";

function SimpleMessageContextMenuOptions({ im, session_data }) {
    return <>
        {
            im.data1?.type === "order_request"
                ? <div className="w3-padding-8" onClick={() => {
                    window.open(`/${im.data1.delivery_route_id}?buying_for_user_id=${im.data1.user_id}&cart_id=${im.data1.cart_id}`)
                }}>
                    <FontAwesomeIcon icon={faDollar} className="w3-padding-right-4 w3-text-amber" />
                    Add Pricing
                </div>
                : session_data.delivery_route_user && im._type === INBOX_MESSAGE_TYPE_WA_INCOMING_MSG
                    ?
                    <div className="w3-padding-8" onClick={() => session_data.scanForItems?.(im.data.txt)}>
                        <FontAwesomeIcon icon={faMagicWandSparkles} className="w3-padding-right-4 w3-text-amber" />
                        AI Cart
                    </div>
                    : null
        }
    </>
}

SimpleMessageContextMenu.Options = SimpleMessageContextMenuOptions;

function SurveyPopup({ delivery_route, user, survey_questions, onSelect }) {
    const ctx = useRef({}).current;
    const [current_index, setCurrentIndex] = useState(0)
    const [error, setError] = useState()

    function submitAnswers() {
        var msg = "\n"
        survey_questions.map((question, i) => {
            msg = msg + (i + 1) + ". " + question.title + "\n"
            msg = msg + "Ans: " + question.answer + "\n"
        })
        const im = {
            payload: msg,
            dest_session_id: `customer_notes_${delivery_route._id}_${user._id}`,
        }

        axios.post("/session/send", im).then(
            (resp) => {
                if (resp.data.errors) {
                    Popup.show("Errors", <GenericException ex={resp.data.errors} />)
                    return;
                }
                axios.post(`/api/admin/user/${delivery_route._id}`,
                    {
                        user_id: user._id,
                        created_at: resp.data.inbox_message.created_at,
                        inbox_id: resp.data.inbox_message.inbox_id,
                        action: 'log_customer_note'
                    }
                )
            }
        )
    }

    return (
        <div className='tw-p-4'>
            <p className="tw-mt-2">{survey_questions[current_index].title}</p>
            <p className="tw-text-xs tw-text-gray-500">{survey_questions[current_index].description}</p>
            <textarea
                className="tw-border tw-px-4 tw-py-2 tw-w-full tw-rounded tw-text-md tw-mt-1"
                type={survey_questions[current_index]._type || "text"}
                inputMode={survey_questions[current_index]._type === "number" ? "numeric" : "text"}
                id="survey_answer"
                defaultValue=""
                placeholder='Enter your answer here..'
                onChange={(evt) => {ctx.answer = evt.target.value}}
            />
            {error && <p className="tw-text-xs tw-text-red-500 tw-text-center">{error}</p>}
            <p className="">{(current_index + 1) + "/" + survey_questions.length}</p>
            <button className="tw-mx-4 tw-mb-4 tw-px-4 tw-py-2 tw-bg-yellow-500 tw-rounded tw-text-sm w3-right"
                type="button"
                onClick={() => {
                    if (survey_questions[current_index].required && !ctx.answer) {
                        setError("Please enter answer to continue")
                        return
                    }
                    setError("")
                    survey_questions[current_index].answer = ctx.answer || "-"
                    if (current_index === (survey_questions.length - 1)) {
                        submitAnswers()
                        onSelect()
                    } else {
                        document.getElementById("survey_answer").value = ""
                        ctx.answer = ""
                        setCurrentIndex(current_index + 1)
                    }
                }}>
                {current_index == (survey_questions.length - 1) ? "Finish" : "Next"}
            </button>
        </div>
    );
}

function GiveCredit({ credit_wallet, delivery_route_id, user_id, onUpdate }) {
    const [credit_amount, setCreditAmount] = useState((credit_wallet?.minimum_wallet_amount || 0) * -1);
    const [billing_cycle, setBillingCycle] = useState(credit_wallet?.repayment_period || 'WEEK');

    const [is_loading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const updateCredit = () => {
        if (is_loading) return;
        if (Number.isNaN(credit_amount || credit_amount < 0)) {
            setError('Please enter valid amount.');
            return;
        }
        setIsLoading(true);
        axios.post(
            `/api/admin/give_credit/${delivery_route_id}/${user_id}`,
            { credit_amount, repayment_period: billing_cycle }
        ).then((resp) => {
            if (resp.data.errors) {
                Popup.show("Errors", <GenericException ex={resp.data.errors} />);
                return;
            }
            onUpdate(resp.data.credit_wallet);
        }).finally(() => setIsLoading(false));
    }

    return (
        <div className='tw-pt-4 tw-pb-10'>
            <div className='tw-px-4'>
                <div className='tw-mb-1 tw-text-sm tw-text-gray-600'>Credit Limit</div>
                <input type='number' className='w3-input tw-text-sm tw-outline-0 tw-mb-4' defaultValue={credit_amount}
                    onChange={(evt) => { setCreditAmount(parseInt(evt.target.value, 10)); setError(null) }}
                    placeholder='Amount'
                />
                {error ? <FormErrorText msg={error} /> : null}
                <div className='tw-mb-1 tw-text-sm tw-text-gray-600'>Billing Cycle</div>
                <select className='w3-input tw-text-sm tw-outline-0 tw-mb-2' defaultValue={billing_cycle}
                    onChange={(evt) => setBillingCycle(evt.target.value)}
                >
                    <option value='WEEK'>One Week</option>
                    <option value='FORTNIGHT'>Fortnight</option>
                    <option value='MONTH'>One Month</option>
                </select>
            </div>
            <div className='tw-flex tw-gap-4 tw-absolute tw-bottom-0 tw-w-full tw-p-4'>
                <button className={`tw-border tw-border-secondary-bg tw-bg-secondary-lighter tw-rounded tw-grow tw-px-4 tw-py-2 tw-text-white ${is_loading ? 'tw-opacity-50' : ''}`}
                    type='button'
                    disabled={is_loading}
                    onClick={updateCredit}>
                    {is_loading ? <FontAwesomeIcon className="tw-mr-2 tw-animate-spin" icon={faSpinner} /> : null}
                    Save
                </button>
            </div>
        </div>
    )
}

function CollectCreditDue({ credit_wallet, delivery_route_id, user_id, onUpdate }) {
    const [due_amount, setDueAmount] = useState(getPrice(credit_wallet.wallet_currency, credit_wallet.due_amount));

    const [is_loading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const updateCredit = () => {
        if (is_loading) return;
        if (isNaN(due_amount || due_amount < 0)) {
            setError('Please enter valid amount.');
            return;
        }
        setIsLoading(true);
        axios.post(
            `/api/admin/delivery_route/${delivery_route_id}/user/${user_id}/repay_credit`,
            { "repayment_amount": due_amount }
        ).then((resp) => {
            if (resp.data.errors) {
                Popup.show("Errors", <GenericException ex={resp.data.errors} />)
                return;
            }
            onUpdate(resp.data.repaying_user_credit_wallet);
        })
            .finally(() => setIsLoading(false));
    }

    return (
        <div className='tw-pt-4 tw-pb-10'>
            <div className='tw-px-4'>
                <div className='tw-mb-1 tw-text-sm tw-text-gray-600'>Credit Limit</div>
                <input type='number' className='w3-input tw-text-sm tw-outline-0 tw-mb-4' defaultValue={due_amount}
                    disabled={true}
                    onChange={(evt) => { setDueAmount(parseInt(evt.target.value)); setError(null) }}
                    placeholder='Amount'
                />
                {error ? <FormErrorText msg={error} /> : null}
            </div>
            <div className='tw-flex tw-gap-4 tw-absolute tw-bottom-0 tw-w-full tw-p-4'>
                <button className={`tw-border tw-border-secondary-bg tw-bg-secondary-lighter tw-rounded tw-grow tw-px-4 tw-py-2 tw-text-white ${is_loading ? 'tw-opacity-50' : ''}`}
                    disabled={is_loading}
                    onClick={updateCredit}>
                    {is_loading ? <FontAwesomeIcon className="tw-mr-2 tw-animate-spin" icon={faSpinner} /> : null}
                    Save
                </button>
            </div>
        </div>
    )
}

const RefundUser = ({ delivery_route, user, onSave }) => {
    const [amount, setAmount] = useState('');
    const [is_loading, setIsLoading] = useState(false);

    const handleRefund = () => {
        const payload = {
            "amount": amount,
            "wallet_user_id": user._id,
            "wallet_for": "delivery_route_" + delivery_route._id,
            "wallet_currency": delivery_route.currency
        }
        setIsLoading(true);
        axios.post("/api/admin/deposit/wallet/" + delivery_route._id, payload)
            .then(
                (resp) => {
                    if (resp.data.errors) {
                        Popup.show("Errors", <GenericException ex={resp.data.errors} />)
                        return;
                    }
                    user.credit_wallet = resp.data.deposit_wallet
                    onSave(user);
                }
            ).finally(() => setIsLoading(false));
    }

    return (
        <div className="tw-p-4">
            <label className="tw-text-black">Refund Amount:</label>
            <div className='tw-bg-white tw-rounded tw-text-sm tw-py-2 tw-px-2 tw-border tw-my-2 '>
                <input className='tw-outline-0 tw-w-full' type="number" placeholder="Enter refund amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
            </div>
            <button className={`tw-bg-secondary tw-px-4 tw-py-2 tw-rounded tw-text-white tw-mt-4 tw-text-sm`}
                disabled={!amount}
                onClick={handleRefund}
            >Submit {is_loading && <FontAwesomeIcon icon={faSpinner} spin />}
            </button>
        </div>
    )
}

function DeliveryRouteUser({ delivery_route, user, onUserUpdated }) {
    const [credit_wallet, setCreditWallet] = useState(user?.credit_wallet);
    const rerender = useRerender();
    const navigate = useNavigate();
    const ctx = useRef({}).current;
    const current_user = useCurrentUser();

    const showRefundPopUp = (user) => {
        var popup = Popup.show(
            "Refund User",
            <RefundUser
                delivery_route={delivery_route}
                user={user}
                onSave={
                    (_user) => {
                        setCreditWallet(_user.credit_wallet)
                        popup.close();
                    }
                }
            />
        );
    }

    const addTags = (evt) => {
        ctx.selected_tags = null;
        /* show selecttags popup with user */
        const tags = [...new Set(delivery_route.delivery_points.map((cp) => cp.area).filter((area) => area)),
        ...new Set(delivery_route.data?.additional_tags?.map(tag => tag?.trim()).filter((tag) => tag))
        ]
        Popup.show(
            "Add Areas",
            <SelectTags
                tags={tags}
                selected_tags={user.tags}
                onSelectedTagsUpdated={(tags) => ctx.selected_tags = tags}
                className="w3-padding-8"
                placeholder_text={"Select Areas"}
            />,
            {
                "ok_button": "Update",
                "cancel_button": "Cancel",
                "cb": (action) => {
                    if (action) {
                        axios.post(
                            `/api/admin/user/${delivery_route._id}?action=update`,
                            {
                                "tags": ctx.selected_tags,
                                "user_id": user._id
                            }
                        ).then(
                            (resp) => {
                                if (resp.data.errors) {
                                    Popup.show("Errors", <GenericException ex={resp.data.errors} />)
                                    return;
                                }
                                user.tags = resp.data.session_user?.tags;
                                rerender();
                            }
                        );
                    }
                }
            }
        );
    }

    const giveCredit = (evt) => {
        var popup = Popup.slideUp(
            <GiveCredit
                onUpdate={(credit_wallet) => { setCreditWallet(credit_wallet); popup.close() }}
                credit_wallet={credit_wallet}
                delivery_route_id={delivery_route._id}
                user_id={user._id}
            />,
            { title: 'Give Credit' }
        )
    }

    const collectCreditDue = (evt) => {
        var popup = Popup.slideUp(
            <CollectCreditDue
                onUpdate={(credit_wallet) => { setCreditWallet(credit_wallet); popup.close() }}
                credit_wallet={credit_wallet}
                delivery_route_id={delivery_route._id}
                user_id={user._id}
            />,
            { title: 'Collect Credit Due (Cash)' }
        )
    }

    const removeUser = (evt) => {
        if (!window.confirm("Are you sure ?")) {
            return;
        }
        axios.post(
            `/api/admin/users/${delivery_route._id}?action=remove_user`,
            { "remove_user_id": user._id }
        ).then((resp) => {
            if (resp.data.removed) {
                user.is_deleted = true;
                rerender();
            }
        });
    }


    const editUser = () => {
        var popup = Popup.show(
            "Edit Customer",
            <AddNewCustomer
                delivery_route={delivery_route}
                user={user}
                onSave={
                    (_user) => {
                        Object.assign(user, _user);
                        onUserUpdated && onUserUpdated(_user);
                        rerender();
                        popup.close();
                    }
                }
            />
        );
    }

    const clearUnreadCount = () => {
        user.wa_user && (user.wa_user.cs_unseen_count = 0);
        rerender();
        broadcaster.broadcast_event(
            'users_unseen_count',
            delivery_route.users?.reduce(
                (count, user) => (
                    count + (user.wa_user?.cs_unseen_count || 0)
                ), 0
            )
        );
    }

    const onUpdateTags = (tags) => {
        user.tags = tags
        rerender();
    }

    const chatWithUser = (evt) => {
        ChatSession.open(
            `wcs_${user._id}_${delivery_route.delivery_org.wa_business_number}`,
            {
                "bottomStatusIndicator": (session_data) => DeliveryRouteUserChatButtons.render(session_data, clearUnreadCount, delivery_route._id),
                "polling": true,
                "topIndicator": (session_data) => CustomerTagsChatIndicator.render(session_data, onUpdateTags, delivery_route._id)
            }
        )
    }

    const openCustomerNotes = () => {
        ChatSession.open(
            `customer_notes_${delivery_route._id}_${user._id}`
        )
    }
    const openSurvey = () => {
        var popup = Popup.show("Survey",
            <SurveyPopup
                survey_questions={delivery_route.delivery_org.config.customer_notes_survey.questions}
                onSelect={() => {
                    Popup.toast(<div>Survey Submitted Successfully!!</div>, 2000)
                    popup.close();
                }}
                delivery_route={delivery_route}
                user={user}
            />
        );
    }

    const isFinanceManager = (user, delivery_org_id) => {
        return user?.roles?.finance_manager && user?.roles?.finance_manager.includes(delivery_org_id)
    }

    const chatOptions = () => (
        <div className='w3-list w3-list-bordered w3-pointer tw-text-sm'>
            <a className='tw-px-4 tw-py-2 w3-row'
                href={`/${delivery_route._id}?buying_for_user_id=${user._id}`}
                target="_blank" rel="noreferrer"
            >Send Cart</a>
            <div className='tw-px-4 tw-py-2' onClick={giveCredit}>Give Credit</div>
            {credit_wallet?.due_amount > 0 ? <div className='tw-px-4 tw-py-2' onClick={collectCreditDue}>Collect Credit Due (Cash)</div> : null}
            <a className="tw-px-4 tw-py-2 w3-block"
                href={`/admin/support/${user._id}/${delivery_route.delivery_org.wa_business_number}?delivery_route_id=${delivery_route._id}`}
                target="_blank"
                rel="noopener noreferrer"
            >Chat Support</a>
            <div className='tw-px-4 tw-py-2' onClick={addTags}>Add Tags</div>
            {
                isFinanceManager(current_user, delivery_route.delivery_org._id)
                    ? <div className='tw-px-4 tw-py-2' onClick={() => {
                        showRefundPopUp(user)
                    }}>Refund</div>
                    : null
            }
            <div className='tw-px-4 tw-py-2' onClick={openCustomerNotes}>User Notes</div>
            <div className='tw-px-4 tw-py-2' onClick={openSurvey}>Take a Survey</div>
            {onUserUpdated ? <div className='tw-px-4 tw-py-2 w3-text-orange' onClick={editUser}>Edit User</div> : null}
            <div className='tw-px-4 tw-py-2 w3-text-red' onClick={removeUser}>Remove User</div>
        </div>
    )

    const showOptions = (evt, is_popup) => {
        if (is_popup) {
            Popup.slideUp(chatOptions())
        } else {
            Popup.showContextMenu(
                evt.target,
                chatOptions()
            );
        }
    };

    if (user.is_deleted || user.is_removed) {
        return null;
    }

    const now_millis = new Date().getTime();

    return (
        <div className='tw-flex tw-px-4 tw-py-2'>
            <div className='tw-w-10 tw-h-10 tw-rounded-full flex-box tw-text-secondary tw-bg-secondary-bg tw-overflow-hidden'>
                {
                    !user.image
                        ? <span>{user.name?.slice(0, 1)}</span>
                        : <img alt={user.name} src={user.image} className='tw-object-cover' />
                }
            </div>
            <div className='tw-relative tw-ml-2 tw-grow tw-cursor-pointer' onClick={chatWithUser}>
                <div className='tw-text-sm tw-font-bold'>
                    <span dangerouslySetInnerHTML={{ __html: user.name }} />
                    {
                        user.session_user_type === SESSION_USER_ADMIN
                            ? <div className="w3-tag w3-margin-4">ADMIN</div>
                            : null
                    }
                    {
                        user.status
                            ? <div
                                className="w3-tag w3-margin-4 w3-tiny"
                                style={{ "backgroundColor": getRandomColor(Object.keys(user.status)[0]) }}
                            >
                                {Object.keys(user.status)[0]} {
                                    Object.values(user.status)[0]
                                        ?
                                        <DateView millis={Object.values(user.status)[0]} relative={true} />
                                        : null
                                }
                            </div>
                            : null
                    }
                </div>
                <div className='tw-text-xs tw-text-primary tw-my-1'>{user.phone_number || user.email_id}</div>
                {
                    credit_wallet
                        ? <div className='tw-text-xs tw-text-gray-600 tw-my-2 tw-flex tw-items-center tw-flex-wrap tw-divide-x'>
                            <span className="tw-pr-2">
                                {credit_wallet.wallet_amount <= 0 ? "Credit Used:" : "Wallet:"}&nbsp;
                                <span className={`${credit_wallet.wallet_amount === 0 ? 'tw-text-black' : credit_wallet.wallet_amount < 0 ? 'tw-text-red-600' : 'tw-text-green-600'} tw-text-sm`}>
                                    {credit_wallet.wallet_amount_str}
                                </span>

                            </span>
                            {
                                Math.abs(credit_wallet.minimum_wallet_amount) > 0
                                && <span className='tw-text-xs tw-px-2'>
                                    Credit: <span className="tw-text-xs">{credit_wallet.credit_amount_str}</span>
                                </span>
                            }
                            {
                                Math.abs(credit_wallet?.due_amount) > 0
                                    ? <>
                                        <span className='tw-text-xs tw-px-2'>
                                            Credit Due: <span className="tw-text-red-600 tw-text-sm">{getPrice(credit_wallet.wallet_currency, credit_wallet.due_amount)} {credit_wallet.wallet_currency}</span>
                                        </span>
                                        <span className='tw-text-xs tw-pl-2'>
                                            Due Date: {<DateView millis={credit_wallet.due_date} exclude_time={true} relative={true} />}
                                        </span>
                                    </>
                                    : null
                            }
                        </div>
                        : null
                }
                <div className='tw-text-xs tw-text-gray-600 tw-flex tw-items-center tw-flex-wrap tw-divide-x'>
                    {
                        user.delivery_org_data?.last_visited_at && (now_millis - user.delivery_org_data.last_visited_at) < 30 * MILLIS_IN_A_DAY
                            ? <div className='tw-text-xs tw-text-gray-600 tw-my-2 tw-flex tw-items-center tw-flex-wrap tw-divide-x'>
                                Visited:&nbsp;<DateView millis={user.delivery_org_data.last_visited_at} relative={true} />
                            </div>
                            : null
                    }
                    {
                        user.points_wallet
                            ? <div className="tw-w-fit tw-bg-white tw-p-1 tw-rounded-full tw-border tw-flex tw-items-center tw-text-xs tw-gap-1 tw-mb-2">
                                <PointsIcon style={{ width: 12 }} /><span>{parseInt(user.points_wallet.wallet_amount, 10)}</span>
                            </div>
                            : null
                    }
                </div>
                {
                    user.tags?.length
                        ? <div className='tw-flex tw-flex-wrap tw-mb-1 tw-gap-y-1'>
                            {
                                user.tags.map(
                                    (tag) => <div key={tag} className='tw-bg-gray-400 tw-px-2 tw-py-1 tw-mr-1 tw-rounded tw-text-white tw-text-xs'>{tag}</div>
                                )
                            }
                        </div>
                        : null
                }
                {
                    user.wa_user?.cs_unseen_count
                        ? <div className='w3-display-right w3-flex w3-center'>
                            <div className='w3-circle w3-red w3-flex-vcenter'
                                style={{ "width": "25px", "height": "25px" }}
                            >
                                {user.wa_user.cs_unseen_count}
                            </div>
                        </div>
                        : null
                }
            </div>
            <FontAwesomeIcon icon={faEllipsisV} onClick={showOptions} className='tw-p-2 tw-cursor-pointer' />
        </div>
    )
}
export default DeliveryRouteUser;
