import React, { useEffect, useRef, useState } from 'react'
import { loadGoogleMapsScript } from './utils/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocation, faMapLocationDot, faSpinner } from '@fortawesome/free-solid-svg-icons';

let map; 
let marker;
let MarkerElement;

const SelectAddress = ({onChange, options}) => {
    const [address_text, setAddressText] = useState('');
    const search_box = useRef(null);
    const geocoder_ref = useRef(null);
    const input_el_ref = useRef(null);
    const map_el_ref = useRef(null);
    const [loading_location, setLocationLoader] = useState(false);
    const [show_map, setShowMap] = useState(options?.show_map || false);
    const selected_address = useRef({});
    
    const initializeMap = async () => {
      const { Map } = await window.google.maps.importLibrary("maps");
      const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");
      MarkerElement = AdvancedMarkerElement;

      map = new Map(map_el_ref.current, {
        center: options?.lat_long || { lat: -1.286, lng: 36.817 },
        zoom: 10,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
        mapId: '7651a7a9552a860c',
        fullscreenControlOptions: {
          position: window.google.maps.ControlPosition.BOTTOM_RIGHT
        }
      });

      // Create the search box and link it to the UI element.
      const input = input_el_ref.current;
      search_box.current = new window.google.maps.places.SearchBox(input);

      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

      // Bias the SearchBox results towards current map's viewport.
      map.addListener("bounds_changed", () => {
        search_box.current?.setBounds(map.getBounds());
      });

      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      search_box.current.addListener("places_changed", () => {
        onMapAddressSelect(search_box.current.getPlaces()?.[0])
      })

      // Configure the click listener.
      map.addListener("click", (mapsMouseEvent) => {
        setAddressFromLatLng({latitude: mapsMouseEvent.latLng.lat(), longitude: mapsMouseEvent.latLng.lng()})
      });

    }

    useEffect(() => {
        loadGoogleMapsScript('AIzaSyCSMLgYj7Uxz34Afcvclun1FslKy6ILtwI').then(
          () => {
            initializeMap();
            if (!geocoder_ref.current) {
              geocoder_ref.current = new window.google.maps.Geocoder();
            }
          });
    
          return () => {
            search_box.current = null;
          };
        }, []
    );

    const setAddressFromLatLng = (data, is_alert) => {
      try {
        const {latitude, longitude} = data;
        const latlng = new window.google.maps.LatLng(latitude, longitude);
        geocoder_ref.current.geocode({
          'latLng': latlng, 
        }, function (results, status) {
          if (status === window.google.maps.GeocoderStatus.OK) {
            const result = results.find((addr => addr.geometry.location_type === 'ROOFTOP' && addr.types.includes('street_address'))) || results[0];
            if (result) {
              onMapAddressSelect(result)
            } else {
              throw 'Address not found';
            }
            if (input_el_ref.current) {
              input_el_ref.current.value = result.formatted_address || '';
            }
          } else {
            throw 'Failed to load address: ' + status;
          }
        });
      } catch (err) {
        if (is_alert && typeof err === 'string') alert(err);
      }
      
    }

    const onMapAddressSelect = async (place) => {
        // Clear out the old marker.
        marker?.setMap(null);
        
        if (!place) return;
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }

        // Create a marker for each place.
        marker = new MarkerElement({
          map,
          title: place.name,
          position: place.geometry.location,
          gmpDraggable: true
        })

        // Set marker drag listener
        marker.addListener("dragend", (event) => {
          const position = marker.position;
          setAddressFromLatLng({latitude: position.lat, longitude: position.lng})
        });
        
        // Set bounds
        const bounds = new window.google.maps.LatLngBounds();
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
        map.fitBounds(bounds);

        // update address field
        selected_address.current = {...(selected_address.current || {}), ...place};
        setAddressText(selected_address.current.formatted_address);
        onChange(selected_address.current);
    }

    const getCurrentLocation = () => {
      setTimeout(() => {
        setLocationLoader(true);
        navigator.geolocation.getCurrentPosition(
          (data) => {
            setLocationLoader(false);
            setAddressFromLatLng(data.coords, true)
          }, 
          (err) => {
            setLocationLoader(false);
            if (err.code === 1) {
              alert('Please allow access to your location.')
            } else {
              alert('Unable to fetch your location.');
            }
          }
        );
      });
    }

    const onManualAddressChange = (evt) => {
      setAddressText(evt.target.value);
      selected_address.current = {...(selected_address.current || {}), formatted_address: evt.target.value};
      onChange(selected_address.current);
    }

    return (
      <>
        <div className={`w3-relative w3-flex w3-border w3-round ${options?.hide_address_input ? "w3-hide": ""}`}>
            <textarea
              value={address_text}
              placeholder="Enter Address here"
              className='w3-no-outline w3-flex-grow-s1 w3-padding-8'
              onChange={onManualAddressChange}
            />
            <div className='w3-flex-col w3-center w3-flex-hcenter w3-border-left'>             
              {
                navigator.geolocation
                ? loading_location
                  ? <FontAwesomeIcon icon={faSpinner}
                      className='w3-padding-8 w3-animate-spin w3-text-blue'
                      style={{"width": "1.5rem", "height": "1.5rem"}}
                    />
                  : <FontAwesomeIcon icon={faLocation} onClick={getCurrentLocation}
                      className='w3-text-blue w3-padding-8'
                      style={{"width": "1.5rem", "height": "1.5rem"}}
                    />
                : null
              }
              <FontAwesomeIcon icon={faMapLocationDot} onClick={() => setShowMap(!show_map)}
                className='w3-text-green w3-padding-8 w3-border-top'
                style={{"width": "1.5rem", "height": "1.5rem"}}
              />
            </div>
        </div>
        <div style={{"height": "100%"}} className={`${show_map ? "w3-row": "w3-display-none"} w3-flex-grow-s1`}>
          <input
            ref={input_el_ref}
            className="w3-padding w3-margin-top w3-large"
            style={{"width": "90%", "height": "2.5rem", "marginLeft": "5%"}}
            type="text"
            placeholder={options?.address_placeholder || "Enter your address"}
          />
          <div className="w3-expand" ref={map_el_ref}></div>
        </div>
      </>
)
}

export default SelectAddress
