// MARK: Payments
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { GenericException } from 'base/ui/errors';
import { Popup } from 'base/ui/popups';
import { useCurrentUser } from 'base/app';
import EventTracker from '../../../../eventsTracker';

function PaymentOptions({delivery_route, order, cp_ids, afterUpdate}){
    
    const [is_loading, setIsLoading] = useState("Loading..");
    const [paying_amount, setPayingAmount] = useState(0)
    const [credit_available, setCreditAvailabe] = useState(0);
    const [currency, setCurrency] = useState("");
    const [payable_amount, setPayableAmount] = useState(0);
    const [cash_to_deposit, setCashToDeposit] = useState(0);
    const [live_payment_options, setLivePaymentOptions] = useState([]);
    const [credit_already_availed, setCreditAlreadyAvailed] = useState(0);
    const [payer_user, setPayerUser] = useState(null);
    const ctx = useRef({"updates": {}}).current;
    const user = useCurrentUser()
  
    useEffect(
        () => {
            if (ctx.is_loading) return;
            ctx.is_loading = true;
            axios.post(
                `/api/delivery/paymentoptions/${delivery_route._id}/${order._id}`,
                {"cp_ids": cp_ids}
            ).then(
                (resp) => {
                    if(resp.data.order_updated){
                      /* order was updated have to redo the payments option*/
                      afterUpdate(resp.data.order_updated);
                      return;
                    }
  
                    setIsLoading(null);
                    setPayableAmount(resp.data.payable_amount);       
                    setPayingAmount(resp.data.payable_amount);
                    setCreditAvailabe(resp.data.credit_available);
                    setCashToDeposit(resp.data.cash_to_deposit);
                    setCreditAlreadyAvailed(resp.data.credit_already_availed);
                    setLivePaymentOptions(resp.data.live_payment_options || []);
                    setCurrency(resp.data.payment_currency);
                    setPayerUser(resp.data.payer_user);
                }
            ).finally(() => {ctx.is_loading = false});

        }, [delivery_route._id, cp_ids, order._id]
    );
  
    const availCredit = () => {
        const data = {
            "action": "avail_credit",
            "cp_ids": cp_ids,
            "order_id": order._id,
            "amount": paying_amount,
        };
        if(ctx.updates.admin_force_auto_wallet){
            data.admin_force_auto_wallet = true;
        }
        axios.post(`/api/delivery/paymentoptions/${delivery_route._id}/${order._id}`, data).then(
            (resp) => {
                if(resp.data.errors){
                    Popup.show("Error Occured", <GenericException ex={resp.data.errors} />);
                    return;
                }
                if(resp.data.order) afterUpdate(resp.data.order);
                resp.data.payment_transaction_id
                    && waitForPaymentTransaction(resp.data.payment_transaction_id);
            }
        )
    }
  
    const collectCash = () => {
        setIsLoading("Please Wait!");
        axios.post(
            `/api/delivery/paymentoptions/${delivery_route._id}/${order._id}`,
            {
                "cp_ids": cp_ids,
                "order_id": order._id,
                "action": "cash_collected",
                "amount": paying_amount,
            }
        ).then(
            (resp) => {
                if(resp.data.errors){
                    Popup.show("Error Occured", <GenericException ex={resp.data.errors} />);
                    return;
                }
                EventTracker.track("Order_Payment_Made");
                afterUpdate(resp.data.order);                
            }
        ).finally(
            () => setIsLoading(null)
        )
    }
  
    const waitForPaymentTransaction = (payment_transaction_id) => {
        var ui_block = Popup.blockUi("Waiting for customer Payment");        
  
        var times_checked = 0;
        const check = () => {
            if(times_checked++ > 15){
                ui_block.remove();
                return;
            }
            axios.post(
                `/check_payment_transaction/${payment_transaction_id}?return_type=json`,
            ).then(
                (resp) => {
                    if(resp.data.wait_on_transaction){
                        setTimeout(check, Math.max(5, times_checked) * 1000); // check again in 1 second
                        ui_block.setContent("Still Waiting for customer payment..." + times_checked);
                        return;
                    }
                    /* error or success */
                    ui_block.remove();
                    if(resp.data.errors){
                        Popup.show("Error Occured", <GenericException ex={resp.data.errors} />);
                        return;
                    }
                    if(resp.data.payment_failed){
                        Popup.show("Error Occured", <GenericException ex={{"error": "Payment was cancelled/rejected or encountered an error"}} />);
                        return;
                    }
                    if(resp.data.is_payment_successful){
                      EventTracker.track("Order_Payment_Made");
                      afterUpdate(resp.data.order);
                    }
                }
            ).catch(
                () => ui_block.remove()
            );
        }
        check();
    }
  
    const doStkPush = () => {
        let phone_number = window.prompt("Enter phone number", payer_user && payer_user.phone_number);
        if(!phone_number){
            return;
        }
  
        setIsLoading("Initiating STK Push");
        axios.post(
            `/api/delivery/paymentoptions/${delivery_route._id}/${order._id}`,
            {
                "cp_ids": cp_ids,
                "order_id": order._id,
                "action": "stk_push",
                "amount": parseInt(paying_amount),
                "payer_phone_number": phone_number,
            }
        ).then(
            (resp) => {
                if(resp.data.errors){
                    Popup.show("Error Occured", <GenericException ex={resp.data.errors} />);
                    return;
                }
                // waiting for customer to pay
                waitForPaymentTransaction(resp.data.payment_transaction_id);
            }
        ).finally(
            () => setIsLoading(null)
        );
    }
  
    if(is_loading){
        return (
            <div className="w3-row w3-center" style={{"height": "200px"}}>
                <div className="w3-display-middle w3-large">{is_loading}</div>
            </div>
        );
    }
  
    if(!payable_amount){
        return (
            <div className="w3-row w3-center" style={{"height": "200px"}}>
                <div className="w3-display-middle w3-large">Nothing to Pay</div>
            </div>
        );
    }
    
    return (
        <div className="w3-row w3-content w3-bounded" style={{"width": "300px"}}>
            <div className="w3-center w3-margin-bottom">
                <div className="w3-row w3-padding-topbottom-32">
                    <div className="w3-row w3-margin-bottom-8">
                        To Collect: <b>{payable_amount} {currency}</b>
                    </div>
                </div>
                <div className="w3-relative">
                    <input className="w3-input" type="number"
                        value={paying_amount} 
                        onChange={(evt) => {setPayingAmount(evt.target.value)}} 
                        placeholder="Enter cash to collect"
                        min={0}
                        max={Math.max(payable_amount || 0, credit_already_availed || 0) }
                    />
                    <div className="w3-display-right w3-padding-right">{currency}</div>
                </div>
            </div>
            <div className='w3-list-16 w3-list-bordered'>
                {
                    credit_available
                    ?   <div className="w3-margin-bottom-8">
                            <div className="w3-button w3-block w3-black w3-round-xlarge" onClick={availCredit}>
                                Pay Using Credit
                                <div>
                                {credit_available} {currency}
                                </div>
                            </div>
                            {
                                user.roles.superadmin || user.roles.manager?.[delivery_route?._id]
                                ?   <label className='w3-flex w3-flex-vcenter w3-padding-8 w3-list w3-list-horizontal-16 w3-margin-bottom-16'>
                                        <input type="checkbox" className="w3-check"
                                            onChange={
                                                (evt) => ctx.updates.admin_force_auto_wallet= evt.target.checked
                                            }
                                        />
                                        <div className='w3-small'>Admin Force Credit</div>
                                    </label>
                                :   null
                            }
                            <div className="w3-small">
                                Approval will be sent to
                                {payer_user?.data?.wa_number || payer_user?.phone_number || "???"}.
                                If you want to change whatsapp number,
                                ask customer to call us to change it.
                            </div>
                        </div>
                    :   null
                }
                <div className="w3-margin-bottom-8">
                    <div className="w3-button w3-block w3-green w3-round-xlarge" onClick={collectCash}>
                        Collect Cash
                    </div>
                </div>
                {
                    live_payment_options.includes("mpesa")
                    ?   <div className="w3-margin-bottom-8">
                            <div className="w3-button w3-block w3-indigo w3-round-xlarge" onClick={doStkPush}>
                                MPesa Push
                            </div>
                        </div>
                    :   null
                }
            </div>
            {
                credit_already_availed
                ?   <div className="w3-row w3-margin-bottom-8">
                        Credit Availed: <b className="w3-text-red">{-credit_already_availed} {currency}</b>
                    </div>
                :   null
            }
            {
                cash_to_deposit
                ?   <div className="w3-row w3-margin w3-text-grey w3-tiny">
                        Your Total Cash Collected/ Deposit Back: <b>{cash_to_deposit} {currency}</b>
                    </div>
                :   null
            }
            <div className='w3-row'></div>
        </div>
    );
}

export default PaymentOptions;