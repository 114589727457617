import React from 'react';
import { getDisplayPrice } from '../../../helpers/StoreHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

const DiscountView = ({ data, currency, onRemove, is_from_store}) => {
    return <>
        <div className='hflex tw-mb-2 tw-border tw-p-2 tw-rounded tw-gap-4'>
            <div className={`tw-text-sm tw-capitalize ${data.is_hint ? "tw-text-amber-600" : "tw-text-green-600"}`}>
                <span>{data.code} 🥳</span>
                {
                    data.description
                        ? <div className='tw-mt-1 tw-text-xxs tw-leading-4 tw-text-black'>
                            {data.description}
                        </div>
                        : null
                }
            </div>
            <div className='tw-grid tw-justify-items-end tw-gap-1'>
                {
                    data.amount
                        ? <div className='tw-text-sm tw-text-gray-600 w3-bold w3-no-wrap'>
                            - {getDisplayPrice(currency, data.amount)}
                        </div>
                        : null
                }
                {
                    data.manual_discount && is_from_store
                        ? <button className={`tw-border tw-rounded tw-text-center tw-border-gray-300 tw-font-bold tw-text-red-600 tw-text-xs tw-px-3 tw-py-0.5`}
                            onClick={() => {
                                onRemove(data.code)
                            }}>Remove
                        </button>
                        : null
                }
            </div>
        </div>
    </>
}

export default DiscountView