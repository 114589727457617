import React from 'react';
import { faCaretDown, faMinusSquare, faPlusSquare, faTag, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  {ReactComponent as CheckMark} from '../../../../images/checkmark.svg';
import  {ReactComponent as PointsIcon} from '../../../../images/ic_points.svg';
import { DateView } from 'base/ui/date';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import WhatsappIcon from '../../../../images/whatsapp.svg';
import { getDisplayPrice } from '../../../../helpers/StoreHelper';

const Title = ({cp, className}) => (
    <div className={`app-product-title tw-tracking-wide tw-font-bold line-clamp-2 tw-capitalize ${className}`} 
        dangerouslySetInnerHTML={{__html: cp.title}}
    />
)
const Image = ({grid, image_height, onClick, cp, delivery_route}) => (
    <div className={`${grid ? `tw-mb-4 tw-w-full` : 'tw-w-1/5 tw-max-h-[175px]'}`} style={image_height ? {height: image_height} : null}
        onClick={onClick || null}
    >
        {
            (cp.images && cp.images.length) || delivery_route?.image
                ? <LazyLoadImage src={cp.images?.[0]?.url || delivery_route.image} className={`w3-image tw-max-w-full tw-max-h-full tw-object-cover ${grid ? '' : 'tw-mx-auto'}`} alt={""} style={grid ? {height: '100%', width: '100%'} : {}} />
                : <div className='w3-display-middle w3-bold'>No Image</div>
        }
    </div>
)
const Points = ({cp, size}) => (
    cp.data?.points_per_unit > 0  && <div className='tw-flex tw-absolute tw-top-0 tw-right-0 tw-gap-1 tw-p-1 tw-bg-purple tw-rounded-bl-xl tw-items-center'>
        <PointsIcon style={{ width: size === 'lg' ? 12 : 10}} />
        <p className={`${size === 'lg' ? 'tw-text-xs' : 'tw-text-xxs'} tw-text-white`}>{cp.data?.points_per_unit == 1 ? "1 point" : cp.data?.points_per_unit + " points"}</p>
    </div>
)
const Unit = ({cp, className}) => <div className={className}>{cp.unit}</div>
const PurchasedCount = ({cp, className}) => (
    cp.purchased_users_count
        ? <div className={`tw-text-xxs tw-text-blue-500 ${className}`}>
            <FontAwesomeIcon icon={faUser} />&nbsp;
            {cp.purchased_users_count} purchased
        </div>
        : null
)
const OffersTag = ({onClick, className}) => (
    <div className={`tw-flex tw-items-center tw-text-white tw-rounded tw-text-xxs tw-bg-emerald-600 tw-p-1 tw-gap-1 tw-w-max ${className}`} onClick={onClick}>
        <FontAwesomeIcon icon={faTag} className="tw-w-2" />
        <div>Offers</div>
    </div>
)
const Discount = ({color, data}) => (
    <div className="tw-flex tw-gap-1 tw-mt-1 tw-text-xs">
        <div><CheckMark style={{ width: 14, height: 14}} fill={color} /></div>
        <p className={`tw-text-[${color}]`}>
            {data?.description}
        </p>
    </div>   
)
const DiscountsList = ({cp, cart}) => {
    return <>
        {
            cart?.pricing?.discount_prompts && Object.entries(cart.pricing.discount_prompts).map(([code, discount]) => {
                if (discount.specific_product_id === cp._id ) {
                    return <div className='hflex tw-gap-4' key={code}>
                        <div className="tw-text-sm tw-capitalize">
                            <Discount data={discount} color='#DA5F1C' />
                        </div>
                    </div>
                }
            })
        }
        {
            cart?.pricing?.discounts && Object.entries(cart.pricing.discounts).map(([code, discount]) => {
                if (discount.specific_product_id === cp._id ) {
                    return <div className='hflex tw-gap-4' key={code}>
                        <div className="tw-text-sm tw-capitalize">
                            <Discount data={discount} color='#38A169' />
                        </div>
                    </div>
                }
            })
        }
    </>
}

const PriceQtyReadOnly = ({delivery_route, price, qty}) => (
    <div className="hflex tw-flex-wrap">
        {delivery_route ? <div>{getDisplayPrice(delivery_route?.currency, price)}</div> : null}
        {qty > 0 ? <div><span className='tw-text-xs tw-text-gray-500'>Qty: </span>{qty}</div> : null}
    </div>
)

const BfcRecommendedData = ({cp}) => (
    cp.bfc_recommendatation_data
    ?   <ul className='tw-text-xs tw-p-2 tw-pl-4 tw-mt-1 tw-bg-primary-bg tw-border-0 tw-rounded tw-list-disc tw-text-teal-600'>
            {/* <legend className='tw-font-bold'>Recommended for</legend> */}
            <li>
                <span className='tw-text-gray-800'>Next likely to buy&nbsp;</span>
                <b><DateView millis={cp.bfc_recommendatation_data.next_likely_at} exclude_time={true} /></b>
            </li>
            {
                cp.bfc_recommendatation_data.last_purchased_at
                &&  <li className='tw-mt-1'>
                        <span className='tw-text-gray-800'>Last purchased&nbsp;</span>
                        <b><DateView millis={cp.bfc_recommendatation_data.last_purchased_at} exclude_time={true}
                            relative={true}
                        /></b>
                    </li>
            }
            {
                cp.bfc_recommendatation_data.purchasing_since
                &&  <li className='tw-mt-1'>
                        <span className='tw-text-gray-800'>Purchasing since&nbsp;</span>
                        <b><DateView millis={cp.bfc_recommendatation_data.purchasing_since}
                            exclude_time={true}  relative={true}
                        /></b>
                    </li>                                   
            }
        </ul>
    :   null
)

const Enquire = ({delivery_route, cp, className}) => {
    const enquireProduct = () => {
        const url = encodeURI(`https://wa.me/${delivery_route.delivery_org.wa_business_number}?text=ⓘ Hey, I want to know more about the product *${cp.title}*`);
        window.location.href = url;
    }

    return (
        <button className={`max-xs:tw-w-full tw-flex tw-items-center tw-gap-2 ${className || 'tw-text-xs tw-mt-3 tw-bg-primary-fade tw-rounded-full tw-px-2 tw-py-1'}`} onClick={enquireProduct} type="button">
            <img src={WhatsappIcon} width={20} alt="whatapp icon"/> Contact for Price
        </button>
    )
}

const VariantsInfo = ({cp}) => (
    cp.variant_group_id && cp.variant_info 
    ?   <div className='tw-text-xs tw-bg-secondary-bg tw-text-gray-600 tw-rounded-full tw-px-2 tw-w-fit line-clamp'>
            {
            Object.entries(cp.variant_info)
                .map(([key, val]) => `${key}: ${val}`)
                .join(' | ')
            }
        </div> 
    : null
)

const QtyButton = ({qty, onAdd, onMinus, onQtyClick}) => (
    qty
    ?  <div className='tw-flex tw-gap-0.5 tw-items-center app-qty-change'>
            <FontAwesomeIcon icon={faMinusSquare}
                className="w3-text-light-grey"
                onClick={onMinus}
            />&nbsp;
            <div className="w3-center tw-text-sm"
                onClick={onQtyClick || null}
            >
                {qty} <FontAwesomeIcon icon={faCaretDown} className='tw-text-xs tw-text-gray-400 tw-ml-[1px] tw-mb-[1px]' />
            </div>&nbsp;
            <FontAwesomeIcon
                className="app-plus-button"
                icon={faPlusSquare} onClick={onAdd}
            />
        </div> 
    :   <button className='tw-border-[0.5px] tw-border-black tw-text-sm tw-rounded-lg tw-px-2 tw-py-1'
            onClick={onAdd}
        >Add
        </button>
)

const Product = {};

Product.Title = Title;
Product.Image = Image;
Product.Points = Points;
Product.Unit = Unit;
Product.PurchasedCount = PurchasedCount;
Product.OffersTag = OffersTag;
Product.DiscountsList = DiscountsList;
Product.PriceQtyReadOnly = PriceQtyReadOnly;
Product.BfcRecommendedData = BfcRecommendedData;
Product.Enquire = Enquire;
Product.QtyButton = QtyButton;
Product.VariantsInfo = VariantsInfo;

export default Product
