import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { LandingTabs } from "./constants";
import { track_event } from "../../analytics";
import SukhibaLogo from "../../images/ic_logo_sukhiba.svg";
import LinkedinIcon from "../../images/landing/ic_social_linkedin.svg";
import MBPBadge from "../../images/landing/MBPBadge.jpg";
import bgPage1 from "../../images/landing/page1_bg.svg";
import YoutubeIcon from "../../images/youtube.svg";

const Footer = ({ is_self }) => {
	const handleTabs = (selected_tab) => {
		window.open("/"+selected_tab, is_self ? "_self" : "_blank");
	}

	return (
		<div
			className="tw-px-8 md:tw-px-12 tw-py-12 tw-border tw-border-t"
			style={{
				backgroundImage: "url(" + bgPage1 + ")",
				backgroundSize: "cover",
			}}
		>
			<div className="tw-flex max-md:tw-flex-col tw-gap-12 tw-justify-between lg:tw-justify-around">
				<div className="tw-flex tw-flex-col tw-items-center">
					<img src={SukhibaLogo} className="tw-h-[33px] tw-w-fit" alt='sukhiba-logo' />
					<div className="tw-flex tw-items-center tw-mt-10 md:tw-mt-12 tw-gap-6">
						<div
							className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] tw-cursor-pointer "
							onClick={() => {
								window.open("https://www.linkedin.com/company/69195454/");
							}}
						>
							<img src={LinkedinIcon} className="tw-h-40" alt='linkedin' />
						</div>
						<div
							className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-landing-green/[.2] tw-w-[40px] tw-h-[40px] tw-cursor-pointer "
							onClick={() => {
								window.open("https://www.youtube.com/@sukhiba");
							}}
						>
							<img src={YoutubeIcon} className="tw-h-4" alt='youtube' />
						</div>
					</div>
				</div>
				<div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-4 lg:tw-grid-cols-5 tw-gap-8 tw-font-medium tw-text-gray-500">
					<div>
						<p className="tw-text-[18px] md:tw-text-[20px] tw-font-semibold tw-text-black">Solutions</p>
						<p
							className=" tw-cursor-pointer tw-mt-4"
							onClick={() => {
								handleTabs(LandingTabs.LargeEnterprises);
							}}
						>
							Large Enterprises
						</p>
						<p
							className=" tw-cursor-pointer tw-mt-4"
							onClick={() => {
								handleTabs(LandingTabs.SME);
							}}
						>
							Small to Medium Businesses
						</p>
					</div>
					<div>
						<p className="tw-text-[18px] md:tw-text-[20px] tw-font-semibold tw-text-black">Free Tools</p>
						<p
							className=" tw-cursor-pointer tw-mt-4"
							onClick={() => {
								window.open("/whatsapp-button-generator", "_blank");
							}}
						>
							WhatsApp Widget
						</p>
						<p
							className=" tw-cursor-pointer tw-mt-4"
							onClick={() => {
								window.open("/whatsapp-login");
							}}
						>
							Login With WhatsApp
						</p>
					</div>
					<div>
						<p className="tw-text-[18px] md:tw-text-[20px] tw-font-semibold tw-text-black">Company</p>
						<p
							className="  tw-cursor-pointer tw-mt-4"
							onClick={() => {
								handleTabs(LandingTabs.Partnerships);
							}}
						>
							Partner With Us
						</p>
						<p
							className=" tw-cursor-pointer tw-mt-4"
							onClick={() => {
								handleTabs(LandingTabs.Industries);
							}}
						>
							Industries
						</p>
						<p className="tw-text-[14px] md:tw-text-[16px] tw-mt-4 ">
							<a href="/tnc/" target="_blank">
								Terms & Conditions
							</a>
						</p>
						<p className="tw-text-[14px] md:tw-text-[16px] tw-mt-4 ">
							<a href="/privacy_policy/" target="_blank">
								Privacy Policy
							</a>
						</p>
					</div>

					<div>
						<p className="tw-text-[18px] md:tw-text-[20px] tw-font-semibold tw-text-black">Contact</p>
						<div className="tw-mt-4">
							<p
								className="tw-cursor-pointer"
								onClick={() => {
									track_event("button_schedule_demo");
									handleTabs(LandingTabs["Contact Us"]);
								}}
							>
								Schedule A Demo
							</p>
						</div>
						<div className="tw-mt-4">
							<p className="">
								<FontAwesomeIcon icon={faMapMarkedAlt} /> 10th Floor ABC Place, Waiyaki Way, Nairobi
							</p>
						</div>
					</div>
					<div
						id="meta_badging"
						className="tw-cursor-pointer"
						onClick={() => {
							window.open(
								"https://www.facebook.com/business/partner-directory/search?solution_type=messaging&id=7890157004331858&section=overview",
							);
						}}
					>
						<LazyLoadImage src={MBPBadge} className=" md:tw-h-[115px]" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Footer