// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .scroller-image {
    max-width: 180px;
    min-height: 3rem;
    padding: 0 15px;
    object-fit: cover;
  }
  
  .scroller-image:last-of-type {
    padding-left: 0;
  }
  
  .scroller-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 4rem;
  }
  
  .scroller-wrapper {
    position: absolute;
    display: flex;
  }
  
  .scroller-section-logo {
    display: flex;
    animation: scroller-left-anim var(--speed) linear infinite backwards;
  }
  
  @keyframes scroller-left-anim {
    0% {
      transform: translate(0);
    }
  
    100% {
      transform: translate(-100%);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/css/logoScroller.css"],"names":[],"mappings":"EAAE;IACE,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,oEAAoE;EACtE;;EAEA;IACE;MACE,uBAAuB;IACzB;;IAEA;MACE,2BAA2B;IAC7B;EACF","sourcesContent":["  .scroller-image {\n    max-width: 180px;\n    min-height: 3rem;\n    padding: 0 15px;\n    object-fit: cover;\n  }\n  \n  .scroller-image:last-of-type {\n    padding-left: 0;\n  }\n  \n  .scroller-inner {\n    position: relative;\n    width: 100%;\n    overflow: hidden;\n    height: 4rem;\n  }\n  \n  .scroller-wrapper {\n    position: absolute;\n    display: flex;\n  }\n  \n  .scroller-section-logo {\n    display: flex;\n    animation: scroller-left-anim var(--speed) linear infinite backwards;\n  }\n  \n  @keyframes scroller-left-anim {\n    0% {\n      transform: translate(0);\n    }\n  \n    100% {\n      transform: translate(-100%);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
