import React, { useEffect, useRef, useState } from 'react';

const EditQuantity = ({qty, max_qty_per_user, updateQty}) => {
    const [edit_qty, setEditQty] = useState(qty || 0);
    const input_ref = useRef(null);

    useEffect(() => {
        // Setting timeout to focus as it's interfering with slide animation
        setTimeout(() => input_ref.current?.focus(), 300)
    }, [])

    const onQtyEnter = (e) => {
        if (e.key === 'Enter' && edit_qty >= 0) {
            updateQty(edit_qty); 
        }
    }

    return (
        <div className='tw-pb-4'>
            <div className='tw-px-4 tw-mb-4'>
                <input type='number' ref={input_ref} placeholder='Edit Quantity' className='tw-px-4 tw-py-2 tw-border tw-rounded tw-w-full tw-mt-4' value={edit_qty} onChange={(e) => setEditQty(e.target.value)} onKeyDown={onQtyEnter} />
                {max_qty_per_user > 0 && edit_qty > max_qty_per_user ? <span className='tw-text-red-700 tw-text-xxs'>Please reduce quantity. Maximum is {max_qty_per_user}</span> : null}
            </div>
            <div className={`tw-p-4 ${edit_qty >= 0 ? '' : 'tw-opacity-50'}`}>
                <button className='tw-bg-secondary-lighter tw-text-white tw-rounded tw-text-sm tw-py-2 tw-w-full tw-px-4' disabled={!(edit_qty >= 0)}
                    onClick={() => {
                        if (!max_qty_per_user || (max_qty_per_user > 0 && edit_qty <= max_qty_per_user))
                            updateQty(edit_qty)
                    }
                    }
                >Update Quantity</button>
            </div>
        </div>
    )
}

export default EditQuantity