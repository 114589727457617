import React, { useEffect, useState } from 'react';
import { GenericException } from 'base/ui/errors';
import axios from 'axios';
import { Popup } from 'base/ui/popups';
import { EmptyView, LoadingView, WarningView } from 'base/ui/status';
import SelectAddress from 'base/select_address';
import LoginForm from '../login/LoginForm';
import { broadcaster } from 'base/utils/events';

function SelectAddressList({addresses: _addresses, setAddresses: _setAddresses, user, buying_for_user, delivery_route_id, selected_address, onAddressSelected}){
    const [addresses, setAddresses] = useState(_addresses || []);
    const [is_loading, setLoading] = useState(false);
    const [is_add, setAdd] = useState(false);
    const [new_address, setNewAddress] = useState(null);

    const getAddresses = (params) => {
        setLoading(true);
        axios.post(`/api/user/addresses`, {
            buying_for_user_id: buying_for_user?._id, 
            delivery_route_id,
            ...(params || {})
        }).then((resp) => {
            if(resp.data.errors){
                Popup.show("Error Occurred!", <GenericException ex={resp.data.errors} />)
            }
            if (resp.data?.length) {
                const address_list = [...new Set(resp.data.map(addr => addr.address_text))];
                setAddresses(address_list)
                if (_setAddresses) _setAddresses(address_list);
                if (params?.action === 'add_address') onAddressSelected(address_list[0])
            }
            if (is_add) setAdd(false);
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        if (!addresses?.length && (user?._id || buying_for_user?._id)) {
            getAddresses()
        }
    }, [addresses, user, buying_for_user])

    const setAddress = (_address) => {
        setNewAddress({
            full_address_text: _address.formatted_address,
            address: {
                lat_long: _address.geometry ? [_address.geometry.location.lat(), _address.geometry.location.lng()] : null
            }
        });
    }

    const addAddress = () => {
        getAddresses({action: 'add_address', ...new_address})
    }

    if (!user?._id && !buying_for_user?._id) {
        Popup.show(
            'Login Required',
            <WarningView height={200} title='Please login to view your addresses' />,
            {
                ok_button: 'Login',
                cb: (action) => {
                    action && broadcaster.broadcast_event("error:need_user");
                }
            }
        )
        return null;
    }

    return (
        <div className='w3-row w3-relative tw-p-4'>
            {!is_add
            ?   is_loading ? <LoadingView height={200} />
            :   <>
                    {addresses?.length
                    ?   <div className='tw-min-h-[50vh] tw-pb-10 w3-list-16 tw-divide-y'>
                            <label className={`w3-flex w3-padding-8`}>
                                <div className='w3-col s10 w3-padding-sides-8 '>
                                    Select
                                </div>
                                <div className='w3-margin-left-auto  w3-flex w3-flex-vcenter'>
                                    <input 
                                        className='w3-radio w3-input'
                                        type="radio"
                                        defaultChecked={!selected_address}
                                        name={'address_select'}
                                        onChange={(evt) => evt.target.checked && onAddressSelected('')} 
                                    />
                                </div>
                            </label>
                            {
                                addresses.map(
                                    (addr) => {
                                        return (
                                            <label className={`w3-flex w3-padding-8`}
                                                key={addr}
                                            >
                                                <div className='w3-col s10 w3-padding-sides-8 '>
                                                    {addr}
                                                </div>
                                                <div className='w3-margin-left-auto  w3-flex w3-flex-vcenter'>
                                                    <input 
                                                        className='w3-radio w3-input'
                                                        type="radio"
                                                        defaultChecked={selected_address === addr}
                                                        name={'address_select'}
                                                        onChange={(evt) => evt.target.checked && onAddressSelected(addr)} 
                                                    />
                                                </div>
                                            </label>
                                        )
                                    }
                                )
                            }
                        </div>
                    :   <EmptyView
                            title="No addresses added."
                            height="300px"
                        />
                    }
                    <div className='tw-border-t tw-w-full tw-fixed tw-bg-white tw-bottom-0 tw-left-0 tw-px-4 tw-py-2'>
                        <button className='tw-rounded tw-w-full tw-bg-secondary-lighter tw-text-white tw-px-4 tw-py-2 tw-text-sm' onClick={() => setAdd(true)}>Add Address</button>
                    </div>
                </>
            :   <>
                    <div className='tw-p-4 tw-min-h-[50vh] tw-pb-10'>
                        <p className='tw-text-md tw-mb-4 tw-font-bold'>Add new address</p>
                        <SelectAddress onChange={setAddress} />
                    </div>
                    <div className='tw-border-t tw-w-full hflex tw-fixed tw-bg-white tw-bottom-0 tw-left-0 tw-px-4 tw-py-2'>
                        <button className='tw-rounded tw-border tw-px-4 tw-py-2 tw-text-sm tw-text-gray-500 tw-ml-4' disabled={is_loading} onClick={() => setAdd(false)}>Cancel</button>
                        <button 
                            onClick={addAddress}
                            disabled={!new_address || is_loading} 
                            className={`tw-rounded tw-bg-secondary-lighter tw-border tw-text-sm tw-border-secondary-lighter tw-px-4 tw-py-2 tw-text-white ${!new_address || is_loading ? 'tw-opacity-50' : ''}`}
                        >{is_loading ? 'Adding..' : 'Add Address'}</button>
                    </div>
                </>
        }
        </div>
    )
}

export default SelectAddressList
