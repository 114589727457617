import axios from "axios";
import { IS_DEV } from "base/constants";


const dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

gtag('js', new Date());
gtag('config', 'G-0LKPK5BQJL');

function track_event(event, data){
    if (!IS_DEV) {
        gtag('event', event, data);
    } else {
        console.log('event', event, data);
    }
}

axios.interceptors.response.use(undefined, function (error) {
    const statusCode = error.response ? error.response.status : null;
    track_event("error", {"status_code": statusCode});
    return Promise.reject(error);
})

function log_error(data) {
    if (!IS_DEV) {
        axios.post('/jserror', {stacktrace_string: JSON.stringify(data)});
    } else {
        console.log('js-error', data);
    }
}

window.onerror = function(message, source, lineno, colno, error) {
    var error_details = 'Script: '+ source + ':'+ lineno + ':'+ colno ; 
    const data = {"description": error ? error.stack : error_details, 'error_details': error_details}
    log_error(data);
}

export {track_event};