import React, { useEffect, useState } from "react";
import { TemplatesPopUp } from "./TemplatesPopUp";
import { Popup } from "base/ui/popups";
import CreateBroadcast from "./CreateBroadcast";
import CreateWaTemplate from "./CreateWaTemplate";
import { WAPreview } from "./WAPreview";

const STATUS_STYLE = {
	"CANCELLED": { "backgroundColor": "#FFE4E4", "color": "#950000" },
	"APPROVED": { "backgroundColor": "#15951A14", "color": "#14AA1A" },
}

const NOTIFICATION_TYPES = {
	PRODUCTS: "products",
	SHOP: "shop",
	TEMPLATE: "template",
};
/**
 * Extracts specific data from a template object and returns it in a structured format.
 *
 * @param {Object} template - The template object containing components.
 * @returns {Object} - An object containing extracted data from the template.
 * @property {string} [message.image] - The file URL for image, video, or document formats.
 * @property {string} [message.body] - The text content of the body component.
 * @property {string} [message.header_text] - The text content of the header component.
 * @property {string} [message.footer] - The text content of the footer component.
 * @property {Array} [message.buttons] - The buttons in the buttons component.
 */
function getTemplateBody(template) {
	if (!template)
		return null
	let message = {};
	template?.components.forEach((c) => {
		if (c.type.toLowerCase() === "header") {
			if (c.parameters) {
				c.parameters.forEach((p) => {
					if (!p) return;
					if (p.type === "image") {
						message.image = p.image.link;
					} else if (p.type === "video") {
						message.image = p.video.link;
					} else if (p.type === "document") {
						message.image = p.document.link;
					}
				});
			} else if (c.format === "IMAGE" || c.format === "VIDEO" || c.format === "DOCUMENT") {
				message.image = c.example?.header_handle?.[0];
			} else if (c.format === "TEXT") {
				message.header_text = c.text;
			}
		} else if (c.type.toLowerCase() === "body") {
			message.body = c.text;
		} else if (c.type.toLowerCase() === "footer") {
			message.footer = c.text;
		} else if (c.type.toLowerCase() === "buttons") {
			message.buttons = c.buttons;
		}
	});
	return message;
}

/**
 * Extracts variables from a given text string that follow the format "{{n}}" where n is a number.
 *
 * @function getBodyVariables
 * @param {string} text - The text string to extract variables from.
 * @returns {Array|undefined} - An array of extracted variables if found, or undefined if no variables are found.
 *
 * @example
 * getBodyVariables("Hello, {{1}}! Welcome to {{2}}.") // Returns: ["{{1}}", "{{2}}"]
 * getBodyVariables("No variables here.") // Returns: undefined
 */
function getBodyVariables(text) {
	if (!text) return undefined;
	return text.match(/\{\{([1-9]|[0-9][0-9])\}\}/g);
}

/**
 * Displays a popup to select a template for creating a broadcast.
 *
 * @function showSelectTemplatePopUp
 * @param {Object} org - The organization object containing the business number and ID.
 * @param {Array} selected_users_ui - An UI of selected users for the broadcast.
 * @param {Object} url_params - Additional URL parameters for the broadcast creation mostly search filters.
 *
 * @returns {undefined} - This function does not return a value.
 *
 * @example
 * showSelectTemplatePopUp(org, selected_users_ui, url_params);
 */
const showSelectTemplatePopUp = (org, selected_users_ui, url_params, onCreateTemplate) => {
	const popup = Popup.show(
		"Select Template",
		<TemplatesPopUp
			wa_business_number={org.wa_business_number}
			org_id={org._id}
			onTemplateSelected={(template) => {
				popup.close();
				showCreateBroadcastPopUp(org, template, selected_users_ui, url_params);
			}}
			onCreateTemplate={(sample_template) => {
				popup.close()
				onCreateTemplate && onCreateTemplate(sample_template)
			}}

		/>,
	);
};

/**
 * Displays a popup to create a broadcast using a selected template.
 *
 * @function showCreateBroadcastPopUp
 * @param {Object} org - The organization object containing the business number and ID.
 * @param {Object} template - The selected template object for creating the broadcast.
 * @param {Array} selected_users_ui - An UI of selected users for the broadcast.
 * @param {Object} url_params - Additional URL parameters for the broadcast creation mostly search filters.
 *
 * @returns {undefined} - This function does not return a value.
 *
 * @example
 * showCreateBroadcastPopUp(org, template, selected_users_ui, url_params);
 */
const showCreateBroadcastPopUp = (org, template, selected_users_ui, url_params, broadcast_type, DeliveryRoutesUI) => {
	const popup = Popup.show(
		"Create Broadcast",
		<CreateBroadcast
			org={org}
			template={template}
			selected_users_ui={selected_users_ui}
			url_params={url_params}
			DeliveryRoutesUI={DeliveryRoutesUI}
			onSubmit={() => {
				popup.close();
			}}
			broadcast_type={broadcast_type}
		/>,
	);
};

function SelectWAPreview({ template, setNewTemplate, onClose }) {
	return (
		<div className='tw-grid tw-mt-2 md:tw-max-w-[220px] tw-drop-shadow tw-cursor-pointer' onClick={() => {
			onClose()
			setNewTemplate(template)
		}}>
			<WAPreview files={template.files}
				title={template.title}
				text={template.text}
				footer_text={template.footer_text}
				button_payloads={template.button_payloads}
				show_button_type={true} />
		</div>
	);
}

const SampleTemplates = ({ setNewTemplate, onClose }) => {
	const templates = [
		{
			title: "Seasonal discounts",
			text: "Dont miss out on our amazing festival offer!\nClick now to shop and enjoy a fabulous 20% discount on all our products/services. \nHurry, limited time only!",
			footer: "Reply STOP to Unsubscribe",
			files: [{ url: "https://storage.googleapis.com/sukhiba/mobilefresh/user_uploads/2024-04-08/1712590454562DWt5Z02__samplegallery.png" }],
			button_payloads: [{ text: "Shop Now", type: "QUICK_REPLY", payload: "Shop Now" }]
		},
		{
			title: "Festival Special",
			text: "Eid Mubarak, friends! Until Friday, enjoy Ksh 1,500 off with the code “Eid24”.\n\nDon’t miss out! Swing by one of our branches or shop online:",
			footer: "Sukhiba",
			files: [{ url: "https://storage.googleapis.com/sukhiba/mobilefresh/user_uploads/2024-04-08/1712590454562DWt5Z02__samplegallery.png" }],
			button_payloads: [{ text: "View Shop", type: "URL", payload: "https://lite.sukhiba.com/" }, { text: "Pause", type: "QUICK_REPLY", payload: "Pause" }]
		},
		{
			title: "New Product Alert",
			text: "Introducing Gira Oil - Your Ultimate Health Companion! Discover the perfect blend of health and flavor with our latest creation",
			footer: "#GiraOil #HealthyCooking #NewProduct",
			files: [{ url: "https://storage.googleapis.com/sukhiba/mobilefresh/user_uploads/2024-04-08/1712590454562DWt5Z02__samplegallery.png" }],
			button_payloads: [{ text: "View Shop", type: "URL", payload: "https://lite.sukhiba.com/" }]
		},
		{
			title: "Order Confirmation",
			text: "Hey, your order with Sukhiba is confirmed and will deliver within 24hrs, Thanks for shopping with us.\n\nHappy Shopping!!",
			footer: "Sukhiba",
		},
	]
	return (
		<div className='tw-text-sm tw-p-4 md:tw-grid tw-grid-cols-3 tw-flex-wrap tw-gap-2 tw-items-start'>
			{
				templates.map((template => {
					return <SelectWAPreview key={template.title} template={template} setNewTemplate={setNewTemplate} onClose={onClose} />
				}))
			}
		</div>
	)
}


const showCreateTemplatePopUp = (onTemplateSelected) => {
	const sample_template_popup = Popup.show("Select Template", <SampleTemplates setNewTemplate={(selected_sample_template) => {
		onTemplateSelected(selected_sample_template)
		sample_template_popup.close()
	}} onClose={() => { sample_template_popup.close() }} />)
};


export { STATUS_STYLE, NOTIFICATION_TYPES, getTemplateBody, getBodyVariables, showSelectTemplatePopUp, showCreateTemplatePopUp, showCreateBroadcastPopUp };
