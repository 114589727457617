import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocalStorageState } from 'base/utils/events';
import axios from 'axios';
import { timeRangeToString } from 'base/ui/date';
import { faChevronRight, faTruckFast } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AreaRoutes() {
    const [searchParams] = useSearchParams();
    const [area, setArea] = useLocalStorageState("area", searchParams.get("area"), true);
    const [delivery_area_routes, setDeliveryAreaRoutes] = useState();
    const navigate = useNavigate();

    useEffect(
        () => {
            if(!area) return;
            axios.get(
                `/api/area`,
                {"params": {area}}
            ).then(
                (resp) => {
                    if(!resp.data.errors) {
                        setDeliveryAreaRoutes(resp.data?.delivery_area_routes || []);
                    }
                }
            )
        }, [area]
    );

    const onDeliveryRouteSelect = (_id) => {
        navigate(`/${_id}?area=${area}`)
    }

    return (
        <div className='w3-content'>
            <div className='tw-bg-primary tw-p-4 tw-text-md tw-text-white tw-font-bold tw-flex tw-justify-between tw-capitalize'>
                {area || null}
                {/* <span className='tw-text-sm tw-text-secondary-light'>
                    {area_name ? 'Change Area' : 'Select Area'}
                    &nbsp;&nbsp;<FontAwesomeIcon icon={faChevronDown} />
                </span> */}
            </div>
            {delivery_area_routes
                ?   <div className='tw-divide-y'>
                        <div className='tw-p-4'>
                            <div className='tw-text-md tw-font-bold'>Select Delivery Van</div>
                            <div className='tw-mt-1 tw-text-xs tw-text-gray-600'>
                                Our vans will come in yor area.
                            </div>
                        </div>
                        {delivery_area_routes?.map((d_info) => {
                            let [time_range_prefix, time_range_str] = timeRangeToString(d_info.next_delivery_time_range);
                            return (
                                <div className='tw-p-4 tw-flex' key={d_info._id} onClick={() => onDeliveryRouteSelect(d_info._id)}>
                                    <FontAwesomeIcon icon={faTruckFast} className='tw-text-primary tw-mr-4 tw-mt-1' />
                                    <div className='tw-grow'>
                                        <div className='tw-text-primary tw-text-sm tw-font-bold'>
                                            {d_info.title}
                                        </div>
                                        <div className='tw-text-gray-800 tw-text-xs tw-mt-1'>
                                            Delivery Time: {time_range_prefix} {time_range_str}
                                        </div>
                                        <div className='tw-text-gray-500 tw-text-xs tw-mt-2'>
                                            {d_info.description}
                                        </div>
                                    </div>
                                    <FontAwesomeIcon icon={faChevronRight} className='tw-text-gray-400' />
                                </div>
                            )
                        })}
                    </div>
                :   null
            }
        </div>
    )
}

export default AreaRoutes