import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useBroadcastedState } from 'base/utils/events';
import { Popup } from 'base/ui/popups';
import { getSubProductDescription } from '../../../../helpers/StoreHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProductPriceQty from './ProductPriceQty';
import Product from './Product';

/* items display */
function CartProduct({
    delivery_route,
    buying_for_user,
    catalog_product, price, qty: _qty, order, cart,
    className,
    can_modify,
    image_height,
    updateCart,
    onViewProduct,
    configs, config_index
}){
    const [cp, setCp] = useState(catalog_product);
    const ctx = useRef({}).current;
    const product_ref = useRef();
    const qty = (order?.items?.[cp._id]?.qty || 0) + (cart?.items?.[cp._id]?.diff_qty || 0)
    const sub_product = config_index !== undefined ? configs[config_index] : null;
    const sub_product_description = useMemo(
        () => !can_modify ? getSubProductDescription(sub_product) : null,
        [sub_product, can_modify]
    )
    const notes_config = sub_product || configs?.[0];
    const show_notes = cp.rules?.[0]?.find(rule => rule[1] === 'notes' && rule[2] == 1);

    const [screen] = useBroadcastedState("tw_screen_size");

    const addNotes = () => {
        updateCart({
            config: {...(notes_config || {}), notes: ctx.notes?.trim()}, 
            config_index: config_index || 0,
            catalog_product_id: cp._id
        });
    }

    const openNotes = () => {
        ctx.notes = notes_config?.notes;
        var popup = Popup.slideUp( 
            <div className='tw-p-4'>
                <div><textarea className='tw-w-full tw-border tw-mb-4 tw-px-4 tw-py-2 tw-text-sm' placeholder={`Enter notes for ${cp.title}`} rows={2} defaultValue={ctx.notes || ''} onChange={(evt) => ctx.notes = evt.target.value} /></div>
                <button className='tw-px-4 tw-w-full tw-py-2 tw-bg-secondary-lighter tw-rounded tw-text-white tw-text-sm'
                    onClick={() => {popup.close(); addNotes()}}
                >Add Note</button>
            </div>,
            {title: 'Add Product Notes'}
        )
    }

    const deleteProduct = () => {
        if (sub_product) {
            const config_qty = sub_product.qty;
            updateCart({diff_qty: -config_qty, config: {}, config_index, catalog_product_id: cp._id});
        } else {
            const qty = (order?.items?.[cp._id]?.qty || 0) + (cart?.items?.[cp._id]?.diff_qty || 0)
            updateCart({diff_qty: -qty, catalog_product_id: cp._id});
        }
    }

    const onProductScroll = () => {
        if (!ctx.deleted && product_ref.current.scrollLeft > product_ref.current.offsetWidth*2/3) {
            ctx.deleted = true;
            deleteProduct();
        }
    }

    useEffect(() => {
        if (ctx.deleted && product_ref.current) {
            product_ref.current.scrollLeft = 0;
            if (qty <= 0) {
                product_ref.current.removeEventListener('scroll', onProductScroll)
            } else {
                ctx.deleted = false;
            }
        }
    }, [qty])

    useEffect(() => {
        if (product_ref.current) {
            product_ref.current.addEventListener('scroll', onProductScroll);
            return () => {product_ref.current?.removeEventListener('scroll', onProductScroll)};
        }
    }, [product_ref.current])

    const enable_swipe_delete = can_modify && qty > 0 && !screen.md && product_ref.current;

    return (
        <>
            <div className={enable_swipe_delete ? 'tw-flex tw-overflow-x-auto tw-scroll-smooth w3-hide-scrollbar tw-snap-x tw-snap-mandatory' : ''} ref={product_ref}>
                <div className={`tw-relative tw-px-3 tw-py-4 tw-bg-white tw-h-full ${className || ""} tw-flex tw-gap-4 ${enable_swipe_delete ? 'tw-min-w-full tw-snap-center tw-snap-always' : ''}`}>
                    <Product.Image grid={false} image_height={image_height} 
                        cp={cp} delivery_route={delivery_route} 
                        onClick={() => !can_modify || !onViewProduct ? null : onViewProduct()} 
                    />
                    <Product.Points cp={cp} />
                    <div className={'tw-flex-1'}>
                        <Product.Title cp={cp} className={`tw-mb-1 tw-text-sm ${cp.data?.points_per_unit > 0 && "tw-mt-1.5"}`} />
                        
                        <div className="tw-flex tw-items-center tw-text-xs tw-text-gray-500 tw-mt-1 tw-mb-1">
                            <Product.Unit cp={cp} />
                            {!can_modify ? <Product.PurchasedCount cp={cp} className='tw-px-4' /> : null}
                        </div>
                        {sub_product_description && !can_modify /* showing sub-selections here only if it's non-editable view. Otherwise, it's shown in ProductPriceQty */
                            ?   <div className='hflex tw-bg-gray-bg tw-p-2 tw-gap-4 tw-rounded tw-mb-2'>
                                    <div dangerouslySetInnerHTML={{__html: sub_product_description}} className='tw-text-[13px] tw-text-gray-700 tw-leading-6' /> 
                                </div> 
                            : null
                        }
                        {
                            !can_modify
                            ?   
                                <Product.PriceQtyReadOnly delivery_route={delivery_route} price={price + (sub_product?.total_price || 0)} qty={sub_product ? sub_product.qty : _qty} />
                            :   <ProductPriceQty
                                    catalog_product={catalog_product}
                                    setCp={setCp}
                                    price={price}
                                    cart={cart}
                                    order={order}
                                    delivery_route={delivery_route}
                                    buying_for_user={buying_for_user}
                                    show_config_description={true}
                                    config_index={config_index}
                                />
                        }
                        <div className='tw-mt-2'><Product.VariantsInfo cp={cp} /></div>
                        <div>
                            {
                                cp.sku ? 
                                <div className='tw-flex tw-items-center'>
                                    <p className='tw-text-xs tw-mr-1'>SKU:</p>
                                    <p className='tw-text-sm'>{cp.sku}</p>
                                </div>
                                : null
                            }
                        </div>
                        {can_modify ? <Product.DiscountsList cp={cp} cart={cart}  /> : null}
                        <div className={`tw-flex tw-gap-3 tw-items-center tw-flex-wrap tw-mt-3`}>
                                {   show_notes
                                    ?   <div className={`${notes_config?.notes ? 'tw-bg-primary-fade' : 'tw-border tw-rounded'} tw-px-2 tw-py-[3px] tw-text-xs tw-rounded tw-w-fit line-clamp`} onClick={can_modify ? openNotes : null}>
                                            {notes_config?.notes
                                                ?   <span className='tw-text-gray-500'><span className='tw-text-black'>Note: </span> {notes_config?.notes}</span>
                                                :   <div className='tw-text-gray-600'>
                                                        {/* <FontAwesomeIcon icon={faPencilAlt} className='tw-mr-1 tw-text-[10px]' /> */}
                                                        <span>Add Note</span>
                                                    </div>
                                            }
                                        </div>
                                    :   null 
                                }
                                {can_modify && qty > 0 ? <button onClick={deleteProduct} className='tw-border tw-rounded tw-px-2 tw-py-[3px] tw-text-xs tw-text-red-700'>Delete</button> : null}
                        </div>
                    </div>
                </div>
                {enable_swipe_delete
                    ? <div className='tw-snap-center tw-snap-always tw-bg-red-600 tw-p-4 tw-min-w-full tw-text-white tw-text-sm tw-flex tw-items-center'>Delete</div> 
                    : null
                }
            </div>
        </>
    );
}

export default CartProduct