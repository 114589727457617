export const CURRENCY_CODES = [
	"KES",
	"INR",
	"EUR",
	"USD",
	"RWF",
	"NGN",
	"ZAR",
	"OMR",
	"UGX",
	"PKR",
	"TZS",
	"MUR",
	"ZMW",
	"BWP",
	"MXN",
	"GHS",
	"POINTS"
];
export const PHONE_NUMBER_CODES = [
	"254",
	"1",
	"27",
	"31",
	"33",
	"39",
	"43",
	"44",
	"49",
	"52",
	"66",
	"91",
	"92",
	"230",
	"233",
	"234",
	"250",
	"255",
	"256",
	"260",
	"267",
	"356",
	"968",
	"971",
];
export const ORDER_STATUS_DELIVERED = 1;
export const CUSTOM_DISCOUNT_CODE = "CUSTOM_DISCOUNT";
export const CURRENCY_DECIMALS = { BHD: 3, JOD: 3, KWD: 3, OMR: 3, TND: 3 };
export const DAYS_OF_WEEK = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
export const INBOX_MESSAGE_TYPE_STAFF_GPS = 2001;
export const INBOX_MESSAGE_TYPE_ROUTE_PLAN = 2002;

export const TIME_ZONES = {
	"Africa/Nairobi": "Kenya",
	UTC: "GMT",
	"Africa/Johannesburg": "South Africa",
	"Africa/Lagos": "Nigeria",
	"Asia/Muscat": "Oman",
	"Asia/Kolkata": "India",
	"Asia/Karachi": "Pakistan",
	"Europe/Amsterdam": "Netherlands",
};
export const ORG_SETTINGS = {
	WA_REDIRECTION_POPUP: "wa_redirection_popup",
	LAST_PURCHASED_MSG_TAG: "last_purchased_message_tag",
};

export const ORG_FEATURE_FLAGS = {
	LOYALTY_QR: "loyalty_qr",
	DOWNLOAD_ORDER_INVOICE: "download_order_invoice",
	ROUTE_PLAN: "route_plan",
	NON_COMMERCE: "non_commerce",
	BOT_ANALYTICS: "bot_analytics",
	SHOW_WHATSAPP_CATALOG_PRODUCTS_WHEN_SEARCHED_WITH_AI: "show_whatsapp_catalog_products_when_searched_with_ai"
};

export const ERP = {
	SHOPIFY: { label: "Shopify", id: "shopify" },
	WOOCOMMERCE: { label: "Woo Commerce", id: "woocommerce" },
	ERPNEXT: { label: "ERP Next", id: "erpnext" },
	MISCELLANEOUS: { label: "Miscellaneous ERP", id: "miscellaneous_erp" },
	MAGENTO: { label: "Magento", id: "magento" },
	QUICKBOOKS: { label: "Quickbooks", id: "quickbooks" },
};
export const LOGISTICS = {
	SHIPRAZOR: { label: "Shiprazor", id: "shiprazor" },
};
