
const AudioContext = window.AudioContext || window.webkitAudioContext;
var audio_context = null;
const sounds = {};
const loaded = {};

async function playSound(file){
    if(!AudioContext) return;
    if(!audio_context || audio_context.state === 'suspended'){
        audio_context = new AudioContext();
    }
    if(audio_context.state === 'suspended') return; // try later
    if(loaded[file] === undefined){
        loaded[file] = null; // prevent multiple fetches
        loaded[file] = await fetch(file)
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => audio_context.decodeAudioData(arrayBuffer))
            .then(audioBuffer => loaded[file] = audioBuffer);
    }
    let audio_buffer = loaded[file];
    if(!audio_buffer) return;
    // stop previously playing
    if(sounds[file]){
        sounds[file].stop();
    }
    const source = audio_context.createBufferSource();
    source.buffer = audio_buffer;
    source.connect(audio_context.destination);
    source.start(0);
    sounds[file] = source;
}



export {playSound}