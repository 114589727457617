import { useEffect } from "react"
import { track_event } from "./analytics";

/**
 * Start Performance monitoring 
 * @param {string} id unique identifier for performance entry
 */
function startPerformanceMonitor(id) {
    performance.mark(`${id}_start`);
}

/**
 * Stop and measure performance of a component or interaction
 * @param {string} id unique identifier for performance entry
 * @param {boolean} send_to_analytics default is false. Set to true if the performance measure needs to be sent to google analytics
 */
function stopPerformanceMonitor(id, send_to_analytics) {
    performance.mark(`${id}_end`);
    const start_marker = performance.getEntriesByName(`${id}_start`)?.length ? `${id}_start` : {};
    const measure = performance.measure(`${id}_duration`, start_marker, `${id}_end`);
    if (send_to_analytics && measure?.duration > 0) {
        track_event('ui_performance', {
            id: `${id}_duration`,
            startTime: Math.round(measure.startTime), // duration from navigation started to monitoring start - performance.now()
            duration: Math.round(measure.duration)        
        })
    }
}

/**
 * Performance Monitor at Component Level
 * @argument id Unique name to identify the component or interactiion or activity
 * @argument interactive Default is false. Set to true, if components render is largest paint based on data loaded.
 * @argument send_to_analytics Default is false. Set to true, if duration is to be sent to google analytics
 * @returns renders children 
 */
function PerformanceMonitor({id, interactive, send_to_analytics, children}) {
    useEffect(() => {
        startPerformanceMonitor(id);
    }, [])

    useEffect(() => {
        if (interactive) {
            stopPerformanceMonitor(id, send_to_analytics);
        }
    }, [interactive])
    return children
}

export {startPerformanceMonitor, stopPerformanceMonitor, PerformanceMonitor}
