import axios from "axios";
import { useEffect, useState } from "react";
import { broadcaster, useBroadcastedState } from "./utils/events";

var user = null;
var guest_user = null;
var pending_fetch_user_promise = null;
broadcaster.add_event_listener("_user_", (new_user) => {user = new_user;});

function getCurrentUser(){
    if(user){
        return Promise.resolve(user);
    }
    if(pending_fetch_user_promise){
        return pending_fetch_user_promise;
    }
    var resolver = null; 
    pending_fetch_user_promise = new Promise(
        (_resolver, reject) => { resolver = _resolver;}
    );
    
    axios.get("/user/me").then((resp) => {
        pending_fetch_user_promise = null;
        user = resp.data.user || null;  // explicitly set to null if there is no user, undefined vs null
        guest_user = resp.data.guest_user || null; // explicitly set to null if there is no user, undefined vs null  
        resolver(user || guest_user);
    });

    return pending_fetch_user_promise;
}

function useCurrentUser(){
    const [user, setUser] = useBroadcastedState("_user_");
    useEffect(
        () => {
            if(user) return;
            getCurrentUser().then((user) => setUser(user));
        }, []
    )
    return user;
}

/* 
use this when you know user probably will be loaded in other api calls
so we don't need to make a duplicate parallel call to get user
*/
function useCurrentUserDelayed(){
    const [user, setUser] = useBroadcastedState("_user_");
    useEffect(
        () => {
            let timer = setTimeout(
                () => {
                    if(user) return;
                    getCurrentUser().then((user) => setUser(user));
                }, 750
            );
            return () => clearTimeout(timer);
        }, []
    )
    return user;
}



export {getCurrentUser, useCurrentUser, useCurrentUserDelayed};
