import { faCheckCircle, faDownload, faUpload, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { Popup } from "base/ui/popups";
import { EmptyView, LoadingView } from "base/ui/status";
import { useOnScroll } from "base/ui/utils";
import { loadGoogleMapsScript, useRerender } from "base/utils/common";
import { useBroadcastedState } from "base/utils/events";

import AddNewCustomer from "./AddNewCustomer";
import DeliveryRouteUser from "./DeliveryRouteUser";
import { SearchTagsWithEsValues } from "../../../csm/common";
import UploadSheetView from "../../../csm/components/ui/UploadSheetView";
import { UserBadge } from "../../ui/commonUI";


function getTags(delivery_route) {
  return [
    ...new Set(delivery_route?.delivery_points?.map((cp) => cp.area).filter((area) => area)),
    ...new Set(delivery_route?.data?.additional_tags?.map(tag => tag?.trim()).filter((tag) => tag))
  ];
}

function UploadPopUp(delivery_route, fetchUsers) {
  const popup = Popup.show('Upload Users', 
  <UploadSheetView
    props={{
      sync_url: `/api/sync/org/${delivery_route.delivery_org._id}/customers?delivery_route_id=${delivery_route._id}`
    }}
    onCancel={() => {
      popup.close();
    }}
    onSubmit={(data) => {
      popup.close()
      fetchUsers && fetchUsers()
    }}
  />)
}

function CustomersList({ is_select_list, selected_users = [], setSelectedUsers }) {
  const [delivery_data] = useBroadcastedState('delivery_route_data');
  const { delivery_route } = useOutletContext() || delivery_data || {};
  const [search_result_users, setSearchResultUsers] = useState([]);
  const scrollable_el_ref = useRef();
  const rerender = useRerender();
  const ctx = useRef({}).current;
  const [screen] = useBroadcastedState("tw_screen_size");

  const [search_text, setSearchText] = useState("");
  const [selected_tags, setSelectedTags] = useState([]);
  const [system_tags, setSystemTags] = useState({});
  const [filter, setFilter] = useState(null);

  if (!delivery_route.users) {
    delivery_route.users = [];
  }
  const { users } = delivery_route;


  const setIsLoading = (val) => {
    ctx.is_users_loading = val;
    rerender();
  }

  const fetchUsers = () => {
    setIsLoading(true);
    axios.post(
      `/api/admin/users/${delivery_route._id}`,
      { "cursor": 0 }
    ).then((resp) => {
      if (resp.data.errors) {
        return;
      }
      delivery_route.users = resp.data.users
      delivery_route.next_users_cursor = resp.data.next_users_cursor;
      delivery_route.has_more_users = resp.data.has_more_users;
      rerender();
    }
    ).finally(
      () => {
        setIsLoading(false);
      }
    )
  }

  const fetchMoreUsers = useCallback(() => {
    if (
      !delivery_route.has_more_users
      || ctx.is_users_loading
      || search_result_users.length > 0
    ) {
      return
    }
    setIsLoading(true);
    axios.post(
      `/api/admin/users/${delivery_route._id}`,
      { "cursor": delivery_route.next_users_cursor }
    ).then(
      (resp) => {
        if (resp.data.errors) {
          return;
        }
        users.push(...resp.data.users);
        delivery_route.next_users_cursor = resp.data.next_users_cursor;
        delivery_route.has_more_users = resp.data.has_more_users;
        rerender();
      }
    ).finally(
      () => {
        setIsLoading(false);
      }
    )
  }, [users])

  const searchUsers = () => {
    if (ctx.is_users_loading) {
      return
    }
    if (
      !search_text && !selected_tags.length
      && !Object.keys(system_tags).length && !filter
    ) {
      setSearchResultUsers([]);
      return;
    }
    setIsLoading(true);
    const payload = { "action": "search", ...filter }

    if (search_text) {
      payload["search_text"] = search_text
    }
    if (selected_tags.length > 0) {
      payload["tags"] = selected_tags;
    }
    if (system_tags) {
      payload["system_tags"] = system_tags;
    }
    if (delivery_route.has_more_search_users) {
      payload["cursor"] = delivery_route.next_search_users_cursor
    }


    axios.post(
      `/api/admin/users/${delivery_route._id}`, payload
    ).then(
      (resp) => {
        if (resp.data.errors) {
          return;
        }
        if (delivery_route.has_more_search_users) {
          search_result_users.push(...resp.data.users);
        } else {
          setSearchResultUsers(resp.data.users)
        }
        delivery_route.next_search_users_cursor = resp.data.next_users_cursor;
        delivery_route.has_more_search_users = resp.data.has_more_users;
        delivery_route.search_users_count = resp.data.total
      }
    ).finally(
      () => {
        setIsLoading(false);
      }
    )
  };

  useEffect(() => {
    delivery_route.search_users_count = undefined
    delivery_route.has_more_search_users = false
    if (selected_tags.length > 0 || filter !== null) {
      searchUsers();
    } else {
      setSearchResultUsers([])
      setSearchText("")
    }
  }, [selected_tags, filter]);

  useEffect(() => {
    if (!search_text && selected_tags.length === 0) {
      setSearchResultUsers([]);
      return;
    }

    const timeout = setTimeout(
      () => {
        delivery_route.search_users_count = undefined
        delivery_route.has_more_search_users = false
        searchUsers();
      }, 500
    );
    return () => clearTimeout(timeout);
  }, [search_text]
  );

  useEffect(() => { rerender() }, [ctx.is_users_loading]);

  function loadMoreCustomers() {
    if (search_result_users.length > 0 && delivery_route.has_more_search_users) {
      searchUsers()
      return
    }
    fetchMoreUsers()
  }

  useOnScroll(scrollable_el_ref.current, (percent) => {
    percent === 100 && loadMoreCustomers()
  }, [delivery_route, fetchMoreUsers, searchUsers]);

  const setScrollRef = (el) => {
    if (!scrollable_el_ref.current) {
      scrollable_el_ref.current = el;
      rerender();
    }
  }

  const addCustomer = () => {
    var popup = Popup.show(
      "Add Customer",
      <AddNewCustomer
        delivery_route={delivery_route}
        onSave={
          (user) => {
            users.unshift(user);
            delivery_route.users_count += 1;
            rerender();
            popup.close();
          }
        }
      />
    );
  }

  const onUserUpdated = (user) => {
    const index = users.findIndex(_user => _user._id === user._id);
    if (index >= 0) {
      users[index] = user;
      if (!search_result_users)
        rerender();
    }
    if (search_result_users) {
      const index = search_result_users.findIndex(_user => _user._id === user._id);
      if (index >= 0) {
        search_result_users[index] = user;
        rerender();
      }
    }
  }

  // Loading maps here first to keep it ready for add customer
  useEffect(() => {
    loadGoogleMapsScript('AIzaSyCSMLgYj7Uxz34Afcvclun1FslKy6ILtwI')
  }, [])

  if (!users) {
    return <EmptyView title="Loading.." height="300px" />
  }
  const is_user_shown = new Set();

  const users_list = search_result_users.length ? search_result_users : users.filter(
    (duser) => { if (is_user_shown.has(duser._id)) return false; is_user_shown.add(duser._id); return true; }
  )

  return (
    <div className="tw-flex tw-flex-col max-lg:full-height tw-p-2">
      {!is_select_list
        ? <div className="hflex lg:tw-border-b">
          <div className="tw-grow">
            {screen.lg ?
              <div className="tw-pl-4 tw-py-4">
                <div className="tw-text-md">Customers
                  <span className='tw-rounded tw-text-md tw-px-2 tw-py-[2px]'>
                    {`(${delivery_route.search_users_count || delivery_route.users_count})`}
                  </span>
                </div>
              </div>
              : <div className="tw-text-md ">{`Customers (${delivery_route.search_users_count || delivery_route.users_count})`}</div>
              // : <BackHeader className={'tw-bg-white'} title={`Customers (${delivery_route.search_users_count || delivery_route.users_count})`} />
            }
          </div>
          <button type="button" className="btn-secondary" onClick={() => UploadPopUp(delivery_route, fetchUsers)}>
            <FontAwesomeIcon icon={faUpload} />&nbsp; <span className="max-lg:tw-hidden">Upload</span>
          </button>
          <a href={`/download/users/?delivery_route_id=${delivery_route._id}`} target="_blank" rel="noreferrer"
            className="btn-secondary tw-mx-2">
            <FontAwesomeIcon icon={faDownload} />&nbsp; <span className="max-lg:tw-hidden">Download</span>
          </a>
          <button type="button" className='btn-primary-v2 tw-mr-2'
            onClick={addCustomer}
          >
            <FontAwesomeIcon icon={faUserPlus} />
            <span className='tw-text-xs'>&nbsp; Add</span>
          </button>
        </div>
        : null
      }
      <div className={`tw-relative tw-border-b ${is_select_list ? '' : ' tw-pt-2 tw-pb-4 lg:tw-px-6'}`}>
        <SearchTagsWithEsValues
          tag_types={{
            "ordered": "date-YYYY-MM-DD-HH-mm",
            "cancelled": "date-YYYY-MM-DD-HH-mm",
            "checkout": "date-YYYY-MM-DD-HH-mm",
            ...Object.fromEntries(getTags(delivery_route).map((tag) => [tag, ""]))
          }}
          onTagsChange={(tags) => {
            const system_tags = {};
            /* remove the ordered, cancelled,checkout to system tags */
            for (const [tag, value] of Object.entries(tags)) {
              if (tag === 'ordered' || tag === 'cancelled' || tag === 'checkout') {
                system_tags[tag] = value;
                delete tags[tag];
              }
            }
            setSystemTags(system_tags);
            setSelectedTags(Object.keys(tags));
          }}
          onTextSearch={(search_text) => {
            setSearchText(search_text)
          }}
        />
        {!is_select_list
          ?
          <div className="tw-flex tw-items-center tw-gap-4 tw-mt-2">
            <button type="button" className={`tw-border ${!selected_tags.includes('credit_given') ? 'tw-border-secondary tw-bg-secondary tw-text-white' : 'tw-bg-white tw-border tw-rounded-full tw-px-3 tw-py-1 tw-text-xs'} tw-rounded-full tw-px-3 tw-py-1 tw-text-xs`}
              onClick={() => {
                if (selected_tags.includes('credit_given')) {
                  setSelectedTags(selected_tags.filter(tag => tag !== "credit_given"));
                }
              }}
            >
              All Customers
            </button>
            <button type="button" className={`tw-border ${selected_tags.includes('credit_given') ? 'tw-border-secondary tw-bg-secondary tw-text-white' : 'tw-bg-white tw-border tw-rounded-full tw-px-3 tw-py-1 tw-text-xs'} tw-rounded-full tw-px-3 tw-py-1 tw-text-xs`}
              onClick={() => {
                selected_tags.push("credit_given");
                setSelectedTags([...(selected_tags)]);
              }
              }
            >
              Customers with Credit
            </button>
          </div>
          : null
        }
      </div>
      {
        users && users.length
          ? <div className={`tw-py-2 tw-overflow-auto tw-divide-y tw-grow tw-relative lg:customers-maxh ${is_select_list ? '' : 'max-lg:tw-pb-10'}`}
            ref={setScrollRef}
          >
            {
              search_text && ctx.is_users_loading
                ? <div className="tw-text-black tw-text-center tw-bg-gray-100 tw-sticky tw-mb-2">Searching..</div>
                : null
            }
            {
              users_list.map(
                (user) => {
                  const is_user_selected = is_select_list && selected_users?.includes(user._id);
                  return is_select_list
                    ? <button type="button" className="hflex" key={user._id} onClick={() => { setSelectedUsers(is_user_selected ? selected_users.filter(_id => _id !== user._id) : [...selected_users, user._id]) }}>
                      <UserBadge user={user} />
                      {!is_user_selected
                        ? null
                        : <FontAwesomeIcon icon={faCheckCircle} className='tw-text-green-600' />
                      }
                    </button>
                    : <DeliveryRouteUser
                      user={user} delivery_route={delivery_route}
                      onUserUpdated={onUserUpdated}
                      key={user._id}
                    />
                }
              )
            }
            {
              ctx.is_users_loading
                ? <LoadingView height={75} />
                : null
            }
          </div>
          : <EmptyView title="No Customers Yet" height="200px" />
      }
    </div>
  );
}
export default CustomersList;