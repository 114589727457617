const Clipboard = {
    "copyToLegacyClipboard": (text) => {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        textarea.setAttribute('readonly', '');
        textarea.style.position = 'absolute';
        textarea.style.left = '-9999px';
        document.body.appendChild(textarea);
        textarea.select();
        let success = false;
        try {
            success = document.execCommand('copy');
        } catch (err) {}
        document.body.removeChild(textarea);
        return Promise.resolve(success);
    },
    "copy": (text) => {
        // Check if navigator.clipboard is supported by the browser
        if (navigator.clipboard && window.isSecureContext) {
            // Use the Clipboard API to copy the text
            return navigator.clipboard.writeText(text)
                .then(() => true) // Return true on success
                .catch((err) => {
                   Clipboard.copyToLegacyClipboard(text);
                });
        } else {
            Clipboard.copyToLegacyClipboard(text);
        }
    }
}

export default Clipboard;
