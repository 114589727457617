import {isEmptyObject} from './utils';
import axios from 'axios';


/*some server calls*/
var _to_fetch = {};
var ServerObjCaches =  {};
var pending_promise_get_by_ids = null;

/*
    this map is used to find actual cache 
    and id_key_path from the objects returned 
    from the server
    Ex: caching an object:
        ServerObjCaches[server_resp_field_name][obj[id_key_path]] = obj
    server_resp_field_name => server response key

*/
var _ids_to_type_map = {
    "user_ids": {
        server_resp_field_name: "users", //server response will come with this key
        id_key_path: "_id"
    },
    "session_with_user_ids": {
        server_resp_field_name: "session_with_users", 
        cache_func: function(resp_item){
            ServerObjCaches["session_with_users"][resp_item.with_user_id] = resp_item;
            ServerObjCaches["sessions"][resp_item.session_id] = resp_item;
        }
    },
    "session_ids": {
        server_resp_field_name: "sessions",
        cache_func: function(resp_item){
            /* resp_item -> {"session": , is_user_in_sessiom: ...} */
            ServerObjCaches["sessions"][resp_item.session._id] = resp_item;
        }
    },
    "item_ids": {
        server_resp_field_name: "items",
        id_key_path: "_id"
    },
};


/*just precreate with empty caches*/
for(let type_ids in _ids_to_type_map){
    ServerObjCaches[_ids_to_type_map[type_ids].server_resp_field_name] = {};
}

/*base function that pools and get ids and caches it*/
function getByIds(ids){

    // FILTER IDS TO FETCH
    for(let type_ids in ids){
        let server_resp_field_name = _ids_to_type_map[type_ids].server_resp_field_name;
        
        for(var i=0;i<ids[type_ids].length;i++){
            let to_fetch_id = ids[type_ids][i];
            if(!to_fetch_id){
                continue;
            }
            if(ServerObjCaches[server_resp_field_name][to_fetch_id] === undefined ){
                if(!_to_fetch[type_ids]){
                    _to_fetch[type_ids] = new Set();
                }
                _to_fetch[type_ids].add(to_fetch_id);
            }
        }
    }
    
    // ALREADY SCHEDULED TO FETCH
    if(pending_promise_get_by_ids) return pending_promise_get_by_ids;//already pending
    
    // RESOLVE IF NOTHING TO FETCH
    if(isEmptyObject(_to_fetch)){
        //nothing to fetc
        return Promise.resolve(ServerObjCaches);
    }
 
    // CREATE A PROMISE TO WAIT FOR FETCH
    var resolve = null;
    pending_promise_get_by_ids = new Promise((_resolve) => resolve= _resolve);
    
    var after_fetch = function(data){
        //cache results
        for(let type_ids in _ids_to_type_map){ 
            let server_resp_field_name = _ids_to_type_map[type_ids].server_resp_field_name;
            let id_key_path = _ids_to_type_map[type_ids].id_key_path;
            let cache_func = _ids_to_type_map[type_ids].cache_func;
            if(data[server_resp_field_name]){
                for(let i=0;i<data[server_resp_field_name].length;i++){
                    let obj = data[server_resp_field_name][i]

                    let cache = ServerObjCaches[server_resp_field_name];
                    if(id_key_path){
                        cache[obj[id_key_path]] = obj;
                    }
                    else if(cache_func){
                        cache_func(obj);
                    }

                }						
            }
        }
        resolve(ServerObjCaches);
    };
    
    // SCHEDULE IN 50ms
    let defered_fetch = function(){
        //copy and reset to_fetch
        let temp = {};
        for(let i in _to_fetch){
            let temp2 = Array.from(_to_fetch[i]);
            if(temp2.length>0){
                temp[i] = temp2;						
            }
        }
        /* clear to_fetch  */
        for (var member in _to_fetch) delete _to_fetch[member]
        
        let old_pending_promise_get_by_ids = pending_promise_get_by_ids;
        pending_promise_get_by_ids = null; //set for next batch
                
        axios.post("/get_by_ids", temp).then(
            (function(old_pending_promise_get_by_ids){
                return function(resp){
                    after_fetch(resp.data, old_pending_promise_get_by_ids);
                };
            })(old_pending_promise_get_by_ids)
        );
    };
    setTimeout(defered_fetch, 50);
    // RETURN PROMISE
    return pending_promise_get_by_ids;
}


getByIds.addCaching = function(type_ids, server_resp_field_name, id_key_path, cache_func){
    _ids_to_type_map[type_ids] = {
        server_resp_field_name: server_resp_field_name,
        id_key_path: id_key_path,
        cache_func: cache_func
    };
    ServerObjCaches[server_resp_field_name] = {};
};

export {getByIds, ServerObjCaches};