(function() {
	/* String replaceAll polyfill */
	if (!String.prototype.replaceAll) {
		String.prototype.replaceAll = function(str, newStr){

			// If a regex pattern
			if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
				return this.replace(str, newStr);
			}

			// If a string
			return this.replace(new RegExp(str, 'g'), newStr);

		};
	}

	/* Object.fromEntries polyfill */
	if (!Object.fromEntries) {
		Object.fromEntries = function (entries){
			if (!entries || !entries[Symbol.iterator]) { throw new Error('Object.fromEntries() requires a single iterable argument'); }
			let obj = {};
			for (let [key, value] of entries) {
				obj[key] = value;
			}
			return obj;
		};
	}
})();
