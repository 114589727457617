// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
:root {
  --primary-color: #15956e;
  --secondary-color: #052f2f;
  --highlight-color: #ffb93e;
  --main-text-color: #303030;
  --sub-text-color: #626f86;

  /* Buttons colors */
  --primary-btn-hover-color: #0c7c5a;
  --secondary-btn-hover-color: #ecf4f1;
  --tertiary-hover-border-color: #e3e3e3;

  /* dropdown colors */
  --dropdown-border-color: #c0c0c0;

  /* input colors */
  --input-hover-color: #616161;
  --input-border-active-color: #1A1A1A;
  --input-normal-bg-color: #FDFDFD;
  --input-hover-bg-color: #FAFAFA;
  --input-active-bg-color: #F7F7F7;

  --input-border-error-color: #8E1F0B;
  --input-bg-error-color: #FEE9E8;
  --input-disabled-text-color: #666666;
  --input-disabled-color: #0000000D;

  /* Checkbox colors */
  --checkbox-border-color: #8A8A8A;
  --checkbox-disabled-color: #EBEBEB;

  /* Avatar colors */
  --avatar-bg-color: #15956E0F;

  /* Tags color */
	--tag-primary-bg-color: #D8F5E9;
	--tag-primary-text-color: #0C5132;

	--tag-secondary-bg-color: #FEDAD9;
	--tag-secondary-text-color: #8E1F0B;

	--tag-tertiary-bg-color: #EEEEEE;
	--tag-tertiary-text-color: #000000;

	--tag-counter-bg-color: #DE350B;
}`, "",{"version":3,"sources":["webpack://./src/base/css/variables.css"],"names":[],"mappings":"AAAA,cAAc;AACd;EACE,wBAAwB;EACxB,0BAA0B;EAC1B,0BAA0B;EAC1B,0BAA0B;EAC1B,yBAAyB;;EAEzB,mBAAmB;EACnB,kCAAkC;EAClC,oCAAoC;EACpC,sCAAsC;;EAEtC,oBAAoB;EACpB,gCAAgC;;EAEhC,iBAAiB;EACjB,4BAA4B;EAC5B,oCAAoC;EACpC,gCAAgC;EAChC,+BAA+B;EAC/B,gCAAgC;;EAEhC,mCAAmC;EACnC,+BAA+B;EAC/B,oCAAoC;EACpC,iCAAiC;;EAEjC,oBAAoB;EACpB,gCAAgC;EAChC,kCAAkC;;EAElC,kBAAkB;EAClB,4BAA4B;;EAE5B,eAAe;CAChB,+BAA+B;CAC/B,iCAAiC;;CAEjC,iCAAiC;CACjC,mCAAmC;;CAEnC,gCAAgC;CAChC,kCAAkC;;CAElC,+BAA+B;AAChC","sourcesContent":["/* Variables */\n:root {\n  --primary-color: #15956e;\n  --secondary-color: #052f2f;\n  --highlight-color: #ffb93e;\n  --main-text-color: #303030;\n  --sub-text-color: #626f86;\n\n  /* Buttons colors */\n  --primary-btn-hover-color: #0c7c5a;\n  --secondary-btn-hover-color: #ecf4f1;\n  --tertiary-hover-border-color: #e3e3e3;\n\n  /* dropdown colors */\n  --dropdown-border-color: #c0c0c0;\n\n  /* input colors */\n  --input-hover-color: #616161;\n  --input-border-active-color: #1A1A1A;\n  --input-normal-bg-color: #FDFDFD;\n  --input-hover-bg-color: #FAFAFA;\n  --input-active-bg-color: #F7F7F7;\n\n  --input-border-error-color: #8E1F0B;\n  --input-bg-error-color: #FEE9E8;\n  --input-disabled-text-color: #666666;\n  --input-disabled-color: #0000000D;\n\n  /* Checkbox colors */\n  --checkbox-border-color: #8A8A8A;\n  --checkbox-disabled-color: #EBEBEB;\n\n  /* Avatar colors */\n  --avatar-bg-color: #15956E0F;\n\n  /* Tags color */\n\t--tag-primary-bg-color: #D8F5E9;\n\t--tag-primary-text-color: #0C5132;\n\n\t--tag-secondary-bg-color: #FEDAD9;\n\t--tag-secondary-text-color: #8E1F0B;\n\n\t--tag-tertiary-bg-color: #EEEEEE;\n\t--tag-tertiary-text-color: #000000;\n\n\t--tag-counter-bg-color: #DE350B;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
