import React, { useEffect, useState } from 'react'
import { getByIds } from 'base/get_by_ids';
import { DateView } from 'base/ui/date';

const FormErrorText = ({ msg }) => {
    return (<div className='tw-text-red-600 tw-text-xs tw-mt-1'>{msg}</div>)
}

const NumberBadge = ({val}) => (
    <div className='tw-rounded-full tw-bg-red-600 tw-py-1 tw-px-1 tw-text-xs tw-text-white'
        style={{"bottom": "70%", "left": "80%", "position": "absolute"}}
    >
        {val}
    </div>
)

export { FormErrorText, NumberBadge }