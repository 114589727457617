import React from 'react';
import { GenericException } from 'base/ui/errors';

function OrderSuccess({order, errors}){
    return (
        <div className='w3-row w3-center w3-padding'>
            <div className='w3-row w3-margin-bottom'>#{order._id}</div>
            {errors && <GenericException ex={errors} />}
            <div className='w3-center' style={{"height": "200px"}}>
                <video style={{"height": "100%", "maxWidth": "300px"}} autoPlay
                    muted={true} loop={false}
                >
                    <source src="https://storage.googleapis.com/sukhiba/mobilefresh/icons/order_success.mp4"
                        type={"video/mp4"} 
                    />
                    Order Was placed successfully
                </video>
            </div>
            <div>Relax now! we will notify when we arrive</div>
            <div>You can still edit your order until ordering freeze</div>
        </div>
    )
}

export default OrderSuccess;