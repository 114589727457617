import React, { useRef, useState } from "react";
import { useOnScroll } from "base/ui/utils";
import { LoadingView } from "base/ui/status";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export function SelectList({items: _items, key_path=0, value_path=1, selected_values, onScrollEnd, onSelect, show_all}) {
    const [items, setItems] = useState(_items);
    const [loading, setLoading] = useState(false);
    const scroll_ref = useRef();
    const ctx = useRef({}).current;

    useOnScroll(
        scroll_ref.current, (percent) => {
            if(scroll_ref.current && percent > 95 && onScrollEnd && !ctx.loading) {
                ctx.loading = true;
                setLoading(true);
                onScrollEnd().then((items) => {
                    setItems(items)
                }).finally(() => {
                    ctx.loading = false;
                    setLoading(false)
                })
            }
        }, [items, onScrollEnd]
    );
  
    return (
        <ul className="tw-max-h-[300px] tw-overflow-y-auto tw-p-1.5" ref={scroll_ref}>
            {
                show_all 
                ?   <li className="tw-p-1.5 tw-flex tw-gap-2 tw-text-sm tw-h-8 tw-bg-[#EDFFF0] tw-text-[#15956E] tw-font-bold tw-rounded-md" key={'all'} onClick={() => onSelect(null)}>
                        <div className="tw-w-3">{!selected_values?.length ? <FontAwesomeIcon icon={faCheck} className="hover:tw-bg-[#F6F6F6] tw-text-[#15956E] tw-text-sm" /> : null}</div>
                        All
                    </li>
                : null
            }
            {
                items?.map(item => (
                    <li className={`tw-p-1.5 tw-mb-1 hflex tw-text-sm tw-h-8 tw-cursor-pointer hover:tw-rounded-md ${selected_values?.includes(item[value_path]) ? "tw-text-[#15956E] tw-font-bold tw-bg-[#EDFFF0] hover:tw-text-[#15956E]": "tw-text-[#303030]  tw-font-medium hover:tw-bg-[#F6F6F6]" }`}
                        key={item[value_path]}
                        onClick={() => onSelect(item)}>
                        <span className="tw-grow">{item[key_path || value_path]}</span>
                        <div className="tw-w-3">
                            {
                                selected_values?.includes(item[value_path])
                                ? <FontAwesomeIcon icon={faCheck} /> 
                                : null
                            }
                        </div>
                    </li>
                ))
            }
            {loading ? <LoadingView height="50px" /> : null}
        </ul>
    )
}
