const formCategoryObj = (categories_list, sub_cats, parent) => {
    const primary_cat_name = sub_cats[0]?.trim();
    if (primary_cat_name) {
      let category = categories_list.find(cat => cat.name === primary_cat_name);
      if (!category) {
        category = {name: primary_cat_name, sub_cats: [], parent};
        categories_list.push(category)
      }
      if (sub_cats.length > 1) {
        formCategoryObj(category.sub_cats, sub_cats.slice(1), category);
      }
    }
}
  
export const formCategoryTree = (all_categories) => {
    const categories_list = [];
    all_categories.forEach((cat) => {
      formCategoryObj(categories_list, cat?.toLowerCase().split(new RegExp(['&gt;','>'].join('|'), 'g')))
    })
    return categories_list;
}

export const getCategoriesForSearch = (selected_category) => {
  const categories = getParentCategories(selected_category);
  return categories.map(c => c.name);
}

export const getParentCategories = (selected_category, categories = []) => {
  if (!selected_category) return categories;
  categories.unshift(selected_category);
  return getParentCategories(selected_category.parent, categories);
}

export const getSelectedCategory = (category_names = [], categories = []) => {
  if (!category_names?.length) return null;
  const cat = categories.find(c => c.name === category_names[0]);
  if (!cat) return null;
  if (category_names.length > 1 && cat.sub_cats?.length) {
    return getSelectedCategory(category_names.slice(1), cat.sub_cats);
  }
  return cat;
}

