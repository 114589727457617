import { track_event } from './analytics';

export function sendWebVitalsToAnalytics(data) {
  const {name, value, attribution, rating} = data;
  const eventParams = {
    duration: value,
    id: name,
    status_code: rating
  }

  switch (name) {
    case 'CLS':
      eventParams.debug_string = attribution.largestShiftTarget;
      break;
    case 'LCP':
      eventParams.debug_string = attribution.element;
      break;
    case 'FID':
      eventParams.debug_string = attribution.eventTarget;
      break;
  }

  track_event('web_vitals', eventParams);
}

const reportWebVitals = (onPerfEntry) => {
  import('web-vitals/attribution').then(({ onCLS, onFID, onFCP, onLCP, onTTFB }) => {
    onCLS(onPerfEntry);
    onFID(onPerfEntry);
    onFCP(onPerfEntry);
    onLCP(onPerfEntry);
    onTTFB(onPerfEntry);
  });
};

export default reportWebVitals;
