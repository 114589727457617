import React, { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { LoadingOverlay, NotFoundView } from 'base/ui/status';
import axios from 'axios';
import { GenericException } from 'base/ui/errors';
import { useCurrentUser } from 'base/app';
import { Popup } from 'base/ui/popups';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faWarning } from '@fortawesome/free-solid-svg-icons';
import CatalogProduct from '../components/features/store/product/CatalogProduct';
import  WhatsappIcon from "../images/whatsapp.svg"
import  SuccessJson from "../images/success.json"
import  CelebrationJson from "../images/celebration.json"
import OtpLoginForm from '../components/features/login/OtpLoginForm';
import Lottie from "lottie-react";
import SelectBuyForUser from '../components/features/store/SelectBuyForUser';
import AddNewCustomer from '../components/features/customers/AddNewCustomer';

const QRScan = () => {
    const [search_params] = useSearchParams();
    const ctx = useRef({}).current;
    const [qr_data, setQrData] = useState();
    const user = useCurrentUser();
    const [is_loading, setLoading] = useState(false);
    const qr_key = search_params.get('key');
    const [availed, setAvailed] = useState(false);

    const getQRDetails = (params) => {
        if (ctx.is_loading) return;
        ctx.is_loading = true;
        setLoading(true);
        ctx.params = {...(ctx.params || {}), ...(params || {})};
        axios.post(`/api/qrscan?qr_key=${qr_key}`, ctx.params)
        .then((resp) => {
            setQrData(resp.data);
        })
        .finally(() => {ctx.is_loading = false; setLoading(false)});
    }

    const availPoints = () => {
        setAvailed(true);
    }

    const addCustomer = () => {
        var popup = Popup.show(
          "Avail points for New Customer",
          <AddNewCustomer
            delivery_route={qr_data.delivery_route}
            onSave={
              (user) => {
                ctx.scan_for_user = user;
                getQRDetails({reconfirm: true, scan_for_user_id: user._id});
                popup.close();
              }
            }
          />
        );
    }

    const availForCustomer = () => {
        var popup = Popup.slideUp(
            <SelectBuyForUser
                delivery_route_id={qr_data.delivery_route._id}
                onBuyForUserSelect={
                    (_user) => {
                        if (_user) {
                            getQRDetails({reconfirm: true, scan_for_user_id: _user._id});
                            ctx.scan_for_user = _user;
                        }
                        popup.close();
                    }
                }
                onAddCustomer={() => {
                    addCustomer();
                    popup.close();
                }}
            />,
            {"title": "Select Customer"}
        );
    }

    useEffect(() => {
        if (qr_key) {
            getQRDetails();
        }
    }, [qr_key])

    useEffect(() => {
        if (availed) {
            if (!user) {
                ctx.login_popup = Popup.slideUp(
                    <div className='tw-p-4 tw--mt-2'><OtpLoginForm className="tw-p-4" /></div>,
                    {onCancel: () => setAvailed(false)}
                )
            } else {
                ctx.login_popup && ctx.login_popup.close();
                getQRDetails({reconfirm: true})
            }
        }
    }, [user, availed])

    if (!qr_key) {
        return <NotFoundView title={'QR Code Not found'} />
    }

    return (
        <>
            {is_loading ? <LoadingOverlay /> : null}
            {
                qr_data
                ?   <div className='md:tw-mx-[20%] lg:tw-mx-[25%]'>
                        <div className='tw-bg-primary-bg tw-p-4'>
                            <p className='tw-font-bold tw-text-lg tw-mb-1'>{qr_data.dorg?.title}</p>
                            <div className='tw-font-bold tw-text-sm tw-text-gray-600 tw-flex'>
                                <img src={WhatsappIcon} width={22} className='tw-mr-[2px]' />
                                +{qr_data.dorg?.wa_business_number}
                            </div>
                        </div>
                        {qr_data.errors && !qr_data.errors.need_phone_number
                            ? <div className='tw-p-4'><GenericException ex={qr_data.errors} /></div> 
                            : (qr_data.reconfirm_required || !user) && !qr_data.already_activated 
                            ?   <div className='tw-pb-10 flex-box tw-flex-col'>
                                    <div className="tw-h-[300px] tw-w-full tw-bg-[url('../images/offer_bg.png')] flex-box tw-text-white tw-flex-col tw-gap-4">
                                        <p className='tw-text-md'>You have received!</p>
                                        <div className='tw-p-6 tw-bg-blue-500/50 tw-rounded-full flex-box'>
                                            <div className='tw-p-6 tw-bg-blue-800/50 tw-rounded-full flex-box'>
                                                <div className='tw-p-6 tw-bg-blue-800 tw-rounded-full flex-box tw-flex-col'>
                                                    <p className='tw-text-[32px]'>{qr_data.points}</p>
                                                    <p className='tw-text-xs tw-text-gray-50'>points</p>
                                                </div>
                                            </div>
                                        </div>
                                        <Lottie animationData={CelebrationJson} loop={false} className='tw-absolute tw-w-full tw-h-[300px]' />
                                    </div>
                                    <div className='tw-p-4 tw-w-full'>
                                        <p className='tw-text-gray-600 tw-text-center tw-px-4 tw-pb-4'>Click the button below to avail these points</p>
                                        <button className='tw-drop-shadow tw-px-4 tw-py-3 tw-rounded tw-bg-secondary-lighter tw-text-white tw-text-sm tw-w-full'
                                            onClick={availPoints} disabled={is_loading}
                                        >
                                            Avail Points
                                        </button>
                                        {user?.roles && (user.roles.superadmin || user.roles.manager || user.roles.delivery_org_manager || user.roles.delivery)
                                        ?   <button className='tw-mt-4 tw-font-medium tw-px-4 tw-py-3 tw-rounded tw-border tw-border-secondary-lighter tw-text-secondary-lighter tw-text-sm tw-w-full'
                                                onClick={availForCustomer} disabled={is_loading}
                                            >
                                                Avail for Customer
                                            </button>
                                        :   null
                                        }
                                    </div>
                                    <div className='tw-border-t tw-border-b'><CatalogProduct delivery_route={qr_data.delivery_route} catalog_product={qr_data.cp} qty={qr_data.qty} price={qr_data.cp.price} can_modify={false} /></div>
                                </div>
                            :   <div className='tw-pb-10 flex-box tw-flex-col'>
                                    <div className={`${qr_data?.already_activated ? 'tw-bg-red-50' : 'tw-bg-green-50'} tw-border-t tw-px-4 tw-py-8 tw-w-full flex-box tw-flex-col`}>
                                    {
                                        qr_data?.already_activated
                                            ?   <>
                                                    <p className='tw-text-[64px]'>&#128532;</p>
                                                    <p className='tw-my-2 tw-text-red-600'>Sorry! The QR has already been used.</p>
                                                </>
                                            :   <>
                                                    <Lottie animationData={SuccessJson} loop={false} className='tw-w-36' />
                                                    <p className='tw-mt-4 tw-mb-2 tw-text-gray-600'>You have successfully availed</p>
                                                    <p className='tw-text-secondary-lighter tw-text-lg'>{qr_data.points} points{ctx.scan_for_user ? ' for ' + ctx.scan_for_user.name : '.'}</p>
                                                </>
                                    }
                                    </div>
                                    <div className='tw-border-t tw-border-b'><CatalogProduct delivery_route={qr_data.delivery_route} catalog_product={qr_data.cp} qty={qr_data.qty} price={qr_data.cp.price} can_modify={false} /></div>
                                </div>
                        } 
                    </div>
                :   null
            }
        </>
        
            
    )
}

export default QRScan
