import React from 'react';
import { ReactComponent as WhatsappIcon } from "../../../images/whatsapp.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { getLabel } from '../../../helpers/StoreHelper';

function WAOrderPopUp({ delivery_route, onContinue, onWhatsApp }) {

    return (
        <div className={`tw-p-4`}>
            <div className='tw-bg-gray-200 tw-rounded tw-grid tw-grid-cols-1 tw-gap-2 tw-place-items-center tw-p-2'
                onClick={() => { onContinue() }}>
                <img className=' tw-w-[30%]'
                    src='https://storage.googleapis.com/sukhiba/mobilefresh/user_uploads/2024-02-26/1708935890xr4kAoW5gQ__Group.png' />
                <p className='tw-text-black tw-text-sm tw-font-bold'>Continue to Shop on Website</p>
                <p className='tw-text-gray-500 tw-text-xxs tw-text-center'>{`Select ${getLabel(delivery_route.other_config, "delivery_location", "delivery location").toLowerCase()}  and slot, Add products to cart, Add address and Checkout on whatsapp`}</p>
                <button className='tw-px-4 tw-py-2 tw-bg-primary tw-text-white tw-text-md tw-rounded '
                >Continue <FontAwesomeIcon icon={faArrowRight} />
                </button>
            </div>

            <div className='tw-bg-gray-200 tw-rounded tw-flex tw-gap-2 tw-mt-2 tw-place-items-center tw-p-2'
                onClick={() => { onWhatsApp() }}>
                <div className=' tw-w-[50%]'>
                    <p className='tw-text-black tw-text-sm tw-font-bold'>Buy on Chat with Customer Care</p>
                    <p className='tw-text-gray-500 tw-text-xxs tw-mt-1'>Send your address, products and checkout on chat</p>
                    <p className='tw-text-xs tw-mt-1'>+{delivery_route?.delivery_org?.wa_business_number}</p>
                    <button className='tw-flex tw-items-center tw-gap-1 tw-px-4 tw-mt-2 tw-py-2 tw-bg-[#38966C] tw-text-white tw-text-sm tw-rounded'>
                        <WhatsappIcon style={{ width: 18, height: 18, }} />Chat Now</button>
                </div>
                <img className=' tw-w-[50%]'
                    src='https://storage.googleapis.com/sukhiba/mobilefresh/user_uploads/2024-02-26/1708935903vb1utJXCpA__OBJECTS.png' />
            </div>

        </div>
    )
}

export default WAOrderPopUp