import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

const SideSheet = ({ children, onClose, show }) => {
    return (
      <div
        className="tw-absolute tw-z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        {show ? (
          <div
            className="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-75 tw-transition-opacity"
            onClick={onClose}
          />
        ) : null}
        <div
          className={`tw-fixed tw-right-0 tw-top-0 tw-z-10 tw-overflow-y-auto full-height tw-bg-white tw-ease-in tw-duration-300 ${
            show ? "tw-w-3/5" : "tw-w-0"
          }`}
        >
          <div className='tw-text-right tw-px-4 tw-pt-2 tw-pb-1'><FontAwesomeIcon icon={faX} className='tw-text-gray-500' onClick={onClose} /></div>  
          {children}
        </div>
      </div>
    );
};

export default SideSheet