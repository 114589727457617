import React from "react";

// make a website page with terms and conditions

export const TermsAndConditions = () => {
  return (
    <div className="tw-scroll-smooth tw-p-12 font-figtree">
      <h1 className="tw-font-bold" style={{ fontSize: 32 }}>
        Terms & Conditions
      </h1>
      <br />
      <ol className="tw-list-decimal tw-list-inside">
        <li>
          Acceptance of Terms: By accessing or using our CRM-based SaaS platform,
          you agree to be bound by these terms and conditions.
        </li>
        <br />
        <li>
          Account Registration: To use our platform, you must create an account.
          You are responsible for maintaining the confidentiality of your
          account information and ensuring its accuracy. You must be legally
          authorized to enter into this agreement on behalf of your
          organization.
        </li>
        <br />
        <li>
          User Responsibilities: You are responsible for all activities conducted
          through your account. You agree to use the platform in compliance with
          applicable laws and regulations. You shall not engage in any
          unauthorized access, distribution, or transmission of any content or
          materials through the platform.
        </li>
        <br />
        <li>
          Intellectual Property: The CRM-based SaaS platform and all related
          intellectual property rights belong to us or our licensors. You are
          granted a limited, non-exclusive, non-transferable license to use the
          platform for its intended purpose.
        </li>
        <br />
        <li>
          Data Security and Privacy: We take the security and privacy of your
          data seriously. We implement industry-standard measures to protect
          your data; however, we cannot guarantee absolute security. By using
          our platform, you acknowledge and agree to our privacy policy.
        </li>
        <br />
        <li>
          Service Availability: We strive to provide uninterrupted access to our
          platform but cannot guarantee it. We may need to perform maintenance
          or updates, which may result in temporary disruptions. We are not
          liable for any loss or damage caused by platform unavailability.
        </li>
        <br />

        <li>
          Subscription and Billing: If you subscribe to our platform, you agree
          to pay the applicable fees as outlined in our pricing terms.
          Subscriptions may be billed on a monthly or annual basis. You are
          responsible for timely payment of fees and providing accurate billing
          information.
        </li>
        <br />

        <li>
          Termination: We reserve the right to suspend or terminate your access
          to the platform at any time for violations of these terms or for any
          other reason. Upon termination, your data may be permanently deleted.
        </li>
        <br />

        <li>
          Limitation of Liability: To the extent permitted by law, we shall not
          be liable for any indirect, incidental, consequential, or punitive
          damages arising from your use of the platform. Our maximum liability
          shall be limited to the amount paid by you for the platform during the
          preceding six months.
        </li>
        <br />

        <li>
          Governing Law and Dispute Resolution: These terms are governed by the
          laws of United States of America. Any disputes arising from or related
          to these terms shall be resolved through binding arbitration in
          accordance with the rules of SUKHIBA INC.
        </li>
      </ol>
    </div>
  );
};
