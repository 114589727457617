import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import StaffUser from '../deliveryRoute/staff/StaffUser';
import axios from 'axios';
import { UserProfile } from '../../../user';
import { UserShortBadge } from 'base/ui/misc';
import { LoadingOverlay, LoadingView } from 'base/ui/status';

function SelectDeliveryUser({ delivery_route, onDeliveryUserSelected }) {
    const [delivery_staff, setDeliveryStaff] = useState(null);
    const [is_loading, setIsLoading] = useState();

    useEffect(() => {
        setIsLoading(true)
        axios.get(`/api/admin/staff/${delivery_route._id}`).then(
            (resp) => {
                setDeliveryStaff(resp.data.delivery_staff);
            }
        ).finally(() => setIsLoading(false))
    }, [delivery_route._id]);
  

    return (
        <div className='tw-relative tw-px-4 tw-pt-4 tw-pb-32'>
            {
                is_loading
                    ? <LoadingView title={"Fetching delivery partners.. "} />
                    : <div className='tw-flex'>
                        {
                            delivery_staff?.length
                                ? <>
                                    {
                                        delivery_staff.map(user => <div key={user._id} onClick={() => onDeliveryUserSelected(user)}>
                                            <UserShortBadge user={user} />
                                        </div>)
                                    }
                                </>
                                : <div className='tw-text-center tw-text-gray-400 tw-text-sm'>No Delivery Staff</div>
                        }
                    </div>
            }
        </div>
    )
}

export default SelectDeliveryUser;