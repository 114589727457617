import React from "react";
import "../src/css/logoScroller.css"

const LogoScroller = ({ images, speed = 5000 }) => {
    return (
      <div className="scroller-inner">
        <div className="scroller-wrapper">
          <section className="scroller-section-logo"  style={{ "--speed": `${speed}ms` }}>
            {images.map(({ id, image }) => (
              <div  key={id}>
                <img className="scroller-image" src={image} alt={id} />
              </div>
            ))}
          </section>
          <section  className="scroller-section-logo"  style={{ "--speed": `${speed}ms` }}>
            {images.map(({ id, image }) => (
              <div  key={id}>
                <img className="scroller-image" src={image} alt={id} />
              </div>
            ))}
          </section>
          <section className="scroller-section-logo" style={{ "--speed": `${speed}ms` }}>
            {images.map(({ id, image }) => (
              <div  key={id}>
                <img className="scroller-image" src={image} alt={id} />
              </div>
            ))}
          </section>
        </div>
      </div>
    );
  };
  
  export { LogoScroller };
  