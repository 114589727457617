const LandingTabs = {
	HOME: "home",
	LargeEnterprises: "large-enterprises",
	SME: "sme",
	Partnerships: "partnerships",
	"Contact Us": "contactus",
	Pricing: "pricing",
	"Customer Stories": "customer-stories",
	CustomerStory: "customer_story",
	Industries: "industries",
	ThankYou: "thankyou",
	WAWidget: "whatsapp-button-generator",
};

export { LandingTabs };