import React from 'react'

const ToggleSwitch = ({className, ...args}) => {
    return (
        <label className={`tw-relative tw-inline-flex tw-items-center tw-cursor-pointer ${className}`}>
            <input type="checkbox" {...args} className="tw-sr-only tw-peer" />
            <div className="tw-w-11 tw-h-6 tw-bg-gray-200 peer-focus:tw-outline-none tw-rounded-full tw-peer peer-checked:after:tw-translate-x-full peer-checked:after:tw-border-white after:tw-content-[''] after:tw-absolute after:tw-top-[2px] after:tw-left-[2px] after:tw-bg-white after:tw-border-gray-300 after:tw-border after:tw-rounded-full after:tw-h-5 after:tw-w-5 after:tw-transition-all peer-checked:tw-bg-blue-600"></div>
        </label>
    )
}

export default ToggleSwitch