import React, { useState } from 'react';
import ProductPriceQty from './ProductPriceQty';
import Product from './Product';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

/* items display */
function CatalogProduct({
    delivery_route,
    buying_for_user,
    catalog_product, price, qty: _qty, order, cart,
    className,
    grid, // displayed in vertical if true, else horizontal
    can_modify: _can_modify, // is view only
    image_height,
    onViewProduct,
}){
    const can_modify = _can_modify !== undefined ? _can_modify : !delivery_route.is_ordering_frozen;
    const [cp, setCp] = useState(catalog_product);

    return (
        <div className={`tw-relative tw-px-3 tw-py-4 tw-bg-white tw-h-full ${className || ""} ${grid ? '' : 'tw-flex tw-gap-4'}`}>
            <Product.Image grid={grid} image_height={image_height} 
                cp={cp} delivery_route={delivery_route} 
                onClick={() => !can_modify || !onViewProduct ? null : onViewProduct(cp)} 
            />
            {can_modify ? <Product.Points cp={cp} /> : null}
            <div className={grid ? '' : 'tw-flex-1'}>
                <Product.Title cp={cp} className={grid ? 'tw-text-xs' : 'tw-mb-1 tw-text-sm'} />
                <div className="tw-flex tw-items-center tw-text-xs tw-text-gray-500 tw-mt-1 tw-mb-1">
                    <Product.Unit cp={cp} />
                    {!can_modify ? <Product.PurchasedCount cp={cp} className='tw-px-4' /> : null}
                    {cp.has_discounts && can_modify && onViewProduct
                        ? <Product.OffersTag className='tw-ml-2' onClick={() => onViewProduct(cp)} />
                        : null
                    }              
                </div>
                {
                    !can_modify
                    ?   <Product.PriceQtyReadOnly delivery_route={delivery_route} price={price} qty={_qty} />
                    :   cp.data?.is_bfc_only && !buying_for_user?._id
                        ?   <Product.Enquire cp={cp} delivery_route={delivery_route} />
                        :   <ProductPriceQty
                                source="from parent"
                                catalog_product={cp}
                                setCp={setCp}
                                price={price}
                                delivery_route={delivery_route}
                                buying_for_user={buying_for_user}
                                cart={cart}
                                order={order}
                            />
                }
                {
                    cp.variant_group_id && cp.variant_info
                    ?   <div className='hflex tw-gap-1 tw-mt-3'>
                            <Product.VariantsInfo cp={cp} />
                            {can_modify && onViewProduct
                            ?   <button className='tw-text-xs tw-shrink-0 tw-font-bold' onClick={() => onViewProduct(cp)}
                                >
                                    Options <FontAwesomeIcon icon={faChevronDown} className='tw-text-[10px] tw-ml-0.5' />
                                </button> 
                            :   null
                            }
                        </div>
                    :   null
                }
                {can_modify && !(cp.data?.is_bfc_only && !buying_for_user?._id) ? <Product.DiscountsList cp={cp} cart={cart}  /> : null}
                {can_modify ? <Product.BfcRecommendedData cp={cp} /> : null}
            </div>
        </div>
    )
}

export default CatalogProduct