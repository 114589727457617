import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SearchView } from "../../components/ui/SearchView";
import axios from "axios";
import { GenericException } from "base/ui/errors";
import { Popup } from "base/ui/popups";
import {
  ORG_FEATURE_FLAGS,
} from "../../constants";
import { useCurrentUser } from "base/app";

const FEATURE_FLAGS_LIST = [
  {
    id: ORG_FEATURE_FLAGS.LOYALTY_QR,
    name: "Loyalty QR",
    type: "Platform Feature",
    default_config: { is_active: true, is_visible_on_inactive: false }
  },
  {
    id: ORG_FEATURE_FLAGS.DOWNLOAD_ORDER_INVOICE,
    name: "Download Order Invoice",
    type: "Platform Feature",
    default_config: { is_active: true, is_visible_on_inactive: false }
  },
  {
    id: ORG_FEATURE_FLAGS.ROUTE_PLAN,
    name: "Route Plan",
    type: "Platform Feature",
    default_config: { is_active: false, is_visible_on_inactive: false }
  },
  {
    id: ORG_FEATURE_FLAGS.NON_COMMERCE,
    name: "Non Commerce Organization",
    type: "Platform Setting",
    default_config: { is_active: false, is_visible_on_inactive: false }
  },
  {
    id: ORG_FEATURE_FLAGS.BOT_ANALYTICS,
    name: "Show Only Bot Analytics",
    type: "Platform Setting",
    default_config: { is_active: false, is_visible_on_inactive: false }
  },
  {
    id: ORG_FEATURE_FLAGS.SHOW_WHATSAPP_CATALOG_PRODUCTS_WHEN_SEARCHED_WITH_AI,
    name: "Show WhatsApp Catalog Products When Searched with AI",
    type: "Platform Setting",
    default_config: { is_active: false, is_visible_on_inactive: false }
  }
];

const OrgFeatureFlags = () => {
  const params = useParams();
  const user = useCurrentUser();
  const { delivery_org_id } = params || {};
  const [feature_flags, setFeatureFlags] = useState();
  const [is_loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [updated_feature_flags, setUpdatedFeatureFlags] = useState({});
  const [filtered_flags, setFilteredFlags] = useState(FEATURE_FLAGS_LIST);
  const [filters, setFilters] = useState({
    is_visible_on_inactive: "",
    is_active: "",
    search_text: "",
  });
  const can_modify = user?.roles?.superadmin;
  const is_save_disabled = Object.keys(updated_feature_flags).length === 0;

  const fetchDeliveryOrg = () => {
    if (is_loading) return;
    setLoading(true);
    axios
      .get(`/api/staff/delivery_org/${delivery_org_id}`)
      .then((resp) => {
        if (resp.data.errors) {
          Popup.show("Errors", <GenericException ex={resp.data.errors} />);
          return;
        }
        setFeatureFlags(resp.data.delivery_org?.feature_flags);
      })
      .catch((err) => {
        Popup.show("Errors", <GenericException ex={err} />);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (delivery_org_id) {
      fetchDeliveryOrg();
    }
  }, [delivery_org_id]);

  const updateField = (id, key) => {
    return (evt) => {
      const feature_flag = updated_feature_flags[id] ||
        feature_flags[id] || { is_visible_on_inactive: false, is_active: true };
      const _feature_flag = { ...feature_flag };
      _feature_flag[key] =
        evt.target.value === "true"
          ? true
          : evt.target.value === "false"
            ? false
            : evt.target.value;
      setUpdatedFeatureFlags({ ...updated_feature_flags, [id]: _feature_flag });
    };
  };

  const save = () => {
    if (is_loading) return;
    setLoading(true);
    axios
      .post(
        `/api/staff/delivery_org/${delivery_org_id}?action=update_feature_flags`,
        {
          feature_flags: updated_feature_flags,
        }
      )
      .then((resp) => {
        if (resp.data.errors) {
          Popup.show("Errors", <GenericException ex={resp.data.errors} />);
          return;
        }
        setFeatureFlags(resp.data.delivery_org?.feature_flags);
        setUpdatedFeatureFlags({});
      })
      .catch((err) => {
        Popup.show("Errors", <GenericException ex={err} />);
      })
      .finally(() => setLoading(false));
  };

  const reset = () => {
    setUpdatedFeatureFlags({});
  };

  useEffect(() => {
    const search_text = filters.search_text?.toLowerCase();
    setFilteredFlags(
      FEATURE_FLAGS_LIST.filter((flag) => {
        const config =
          updated_feature_flags[flag.id] || feature_flags?.[flag.id] || {};
        const is_active = config.is_active || false;
        const is_visible_on_inactive = config.is_visible_on_inactive || false;
        return (
          (!search_text || flag.name.toLowerCase().includes(search_text)) &&
          (!filters.is_active || is_active?.toString() === filters.is_active) &&
          (!filters.is_visible_on_inactive ||
            is_visible_on_inactive?.toString() ===
              filters.is_visible_on_inactive)
        );
      })
    );
  }, [filters]);

  return (
    <div className="tw-bg-gray-bg">
      <div className="tw-w-full lg:tw-w-[90%] lg:tw-ml-[5%] tw-bg-white lg:home-minh">
        <div className="tw-p-4">
          <div className="lg:tw-flex tw-items-center tw-justify-between tw-gap-6">
            <div className="tw-flex">
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="tw-pr-4 tw-mt-1 tw-cursor-pointer"
                onClick={() =>
                  navigate(
                    `${window.location.pathname.replace("/" + delivery_org_id, "")}`,
                    { replace: true }
                  )
                }
              />
              <div>
                <div className="tw-flex tw-items-center tw-gap-4">
                  <span className="tw-font-bold tw-capitalize">
                    {delivery_org_id} Permissions
                  </span>
                  <span className="tw-bg-green-200 tw-text-green-900 tw-font-bold tw-text-xs tw-rounded-sm tw-px-2 tw-py-1">
                    Enterprise Plan
                  </span>
                </div>
                <p className="tw-text-xs tw-text-gray-500 tw-mt-1">
                  {can_modify ? "Enable, Disable, Hide or unhide" : "View"}{" "}
                  permissions from the following table
                </p>
              </div>
            </div>
            <div className="md:tw-flex tw-gap-4 max-lg:tw-mt-4 tw-items-center lg:tw-justify-between">
              <SearchView
                placeholder="Search Permissions"
                className={`tw-grow`}
                onSearch={(search_text) =>
                  setFilters({ ...filters, search_text })
                }
              />
              <div className="max-md:tw-mt-4 tw-flex tw-items-center tw-gap-4">
                <select
                  className={`tw-text-xs tw-text-gray-600 tw-border tw-rounded tw-px-4 tw-py-2 tw-outline-0 ${filters.is_visible_on_inactive ? "tw-border-secondary-lighter" : ""}`}
                  onChange={(evt) =>
                    setFilters({
                      ...filters,
                      is_visible_on_inactive: evt.target.value,
                    })
                  }
                >
                  <option value="">Visibility</option>
                  <option value={"false"}>Hidden</option>
                  <option value={"true"}>Visible &#128274;</option>
                </select>
                <select
                  className={`tw-text-xs tw-text-gray-600 tw-border tw-rounded tw-px-4 tw-py-2 tw-outline-0 ${filters.is_active ? "tw-border-secondary-lighter" : ""}`}
                  onChange={(evt) =>
                    setFilters({ ...filters, is_active: evt.target.value })
                  }
                >
                  <option value="">Status</option>
                  <option value={"true"}>Enabled</option>
                  <option value={"false"}>Disabled</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <table className="tw-table-auto tw-w-full tw-mb-8">
          <thead className="tw-bg-gray-bg tw-text-gray-600 tw-text-left">
            <tr className="tw-border-y">
              <th className="tw-px-4 tw-py-2 tw-text-xs">Permission</th>
              <th className="tw-px-4 tw-py-2 tw-text-xs">Type</th>
              <th className="tw-px-4 tw-py-2 tw-text-xs">Value</th>
              <th className="tw-px-4 tw-py-2 tw-text-xs">Visibility</th>
              <th className="tw-px-4 tw-py-2 tw-text-xs">Status</th>
            </tr>
          </thead>
          <tbody className="tw-text-xs">
            {filtered_flags.map(({ id, name, type }) => {
              const config = updated_feature_flags[id] ||
                feature_flags?.[id] ||
                FEATURE_FLAGS_LIST.find(f => f.id === id)?.default_config || {
                  is_visible_on_inactive: false,
                  is_active: true,
                };
              let is_active = config.is_active ? "true" : "false";
              let is_visible_on_inactive = config.is_visible_on_inactive
                ? "true"
                : "false";

              return (
                <tr key={id + JSON.stringify(config)} className="tw-border-y">
                  <td className="tw-px-4 tw-py-2">{name}</td>
                  <td className="tw-px-4 tw-py-2">{type}</td>
                  <td className="tw-px-4 tw-py-2">
                    {feature_flags?.[id]?.value || "-"}
                  </td>
                  <td
                    className={`tw-px-4 tw-py-2 ${is_visible_on_inactive === "true" ? "tw-text-secondary-lighter" : "tw-text-black"} tw-outline-0`}
                  >
                    {is_active === "true" ? (
                      "-"
                    ) : can_modify ? (
                      <select
                        value={is_visible_on_inactive}
                        onChange={updateField(id, "is_visible_on_inactive")}
                      >
                        <option value={"true"}>Visible &#128274;</option>
                        <option value={"false"}>Hidden</option>
                      </select>
                    ) : is_visible_on_inactive === "true" ? (
                      "Visible"
                    ) : (
                      "Hidden"
                    )}
                  </td>
                  <td
                    className={`tw-px-4 tw-py-2 ${is_active === "true" ? "tw-text-secondary-lighter" : "tw-text-black"} tw-outline-0`}
                  >
                    {can_modify ? (
                      <select
                        value={is_active}
                        onChange={updateField(id, "is_active")}
                        className={`${is_active === "true" ? "tw-text-green-600" : "tw-text-red-600"} tw-outline-0`}
                      >
                        <option value={"true"}>Enabled</option>
                        <option value={"false"}>Disabled</option>
                      </select>
                    ) : is_active === "true" ? (
                      "Enabled"
                    ) : (
                      "Disabled"
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {can_modify ? (
          <div className="tw-px-4 tw-py-2 max-sm:tw-absolute tw-bottom-0 tw-w-full tw-flex tw-gap-4">
            <button
              className={`tw-text-sm tw-rounded tw-text-white tw-border tw-border-secondary-lighter tw-bg-secondary-lighter tw-px-4 tw-py-2 max-sm:tw-flex-1 ${is_save_disabled ? "tw-opacity-70" : ""}`}
              disabled={is_save_disabled}
              onClick={save}
            >
              Save
            </button>
            <button
              className={`tw-text-sm tw-rounded tw-text-gray-600 tw-border tw-px-4 tw-py-2 max-sm:tw-flex-1 ${is_save_disabled ? "tw-opacity-70" : ""}`}
              disabled={is_save_disabled}
              onClick={reset}
            >
              Reset
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { OrgFeatureFlags, FEATURE_FLAGS_LIST };
