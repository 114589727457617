import {getLocalStorage, setLocalStorage} from "../utils"
import { useEffect, useState } from 'react';

/* event listener code */
class Broadcaster {
    constructor() {
        this.message_listeners = {};
    }

    broadcast_event() {
        let args = Array.from(arguments);
        let count = 0;
        let event_id = args.shift();
        let listeners = this._get_event_listeners(event_id);
        for (let listener of [...listeners].reverse()) {
            // reverse so latest added will handle first
            if (listener(...args, count)) {
                count++;
            }
        }
        return count;
    }

    _get_event_listeners(msg_type) {
        let listeners = this.message_listeners[msg_type];
        if (!listeners) {
            this.message_listeners[msg_type] = listeners = new Set();
        }
        return listeners;
    }

    add_event_listener(msg_types, func) {
        if (!Array.isArray(msg_types)) {
            msg_types = [msg_types];
        }
        for (let i in msg_types) {
            this._get_event_listeners(msg_types[i]).add(func);
        }
    }

    remove_event_listener(msg_types, func) {
        if (!Array.isArray(msg_types)) {
            msg_types = [msg_types];
        }
        for (let i in msg_types) {
            this._get_event_listeners(msg_types[i]).delete(func);
        }
    }
};

/* default isntance */
const broadcaster = new Broadcaster();

/* will return initial value as specified and listen for changes */
const BROADCAST_HOOK_INIT_STATES = {};
function useBroadcastedState(event_id, value){
    value = value !== undefined ? value : BROADCAST_HOOK_INIT_STATES[event_id];
    const [v, _setV] = useState(value);
    const setV = (_v) => {
        _setV(_v);
        setBroadcastHookState(event_id, _v)
    };
    useEffect(
        () => {
            broadcaster.add_event_listener(event_id, _setV);
            return () => broadcaster.remove_event_listener(event_id, _setV);
        }, []
    );
    return [v, setV];
}

function setBroadcastHookState(event_id, value){
    if(BROADCAST_HOOK_INIT_STATES[event_id] === value) return;
    BROADCAST_HOOK_INIT_STATES[event_id] = value;
    broadcaster.broadcast_event(event_id, value)
}

function useLocalStorageState(key, _value){
    if(!BROADCAST_HOOK_INIT_STATES[key]){
        /* one time aas getLocalStorage() returns null */
        const _local_storage_value = getLocalStorage(key);
        BROADCAST_HOOK_INIT_STATES[key] = _value || _local_storage_value;
        /* save in local storage if not present */
        if(!_local_storage_value && _value) setLocalStorage(key, _value);
    }

    const [v, _setV] = useBroadcastedState(key, _value || BROADCAST_HOOK_INIT_STATES[key]);
    /* save in local storage when value changes */
    const setV = (v) => {v !== _value && setLocalStorage(key, v || ""); _setV(v)};
    return [v, setV];
}

/* some default events that fire automatically */
let visibilityChange = "visibilitychange"
if (document.mozHidden !== undefined) {      
    visibilityChange = "mozvisibilitychange";
} else if (document.msHidden !== undefined) {      
    visibilityChange = "msvisibilitychange";
} else if (document.webkitHidden !== undefined) {      
    visibilityChange = "webkitvisibilitychange";
} else if (document.oHidden !== undefined) {      
    visibilityChange = "ovisibilitychange";
}

document.addEventListener(visibilityChange, function(event) {
    broadcaster.broadcast_event(
        "is_page_visible",
        document.visibilityState == "visible"
    );
}, false);

export {
    Broadcaster, broadcaster, useBroadcastedState, useLocalStorageState, setBroadcastHookState,
    BROADCAST_HOOK_INIT_STATES
};
