import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Popup } from 'base/ui/popups';
import { GenericException } from 'base/ui/errors';
import { broadcaster } from 'base/utils/events';
import { track_event } from '../../../analytics';
import { playCartChangeSound } from '../../../helpers/StoreHelper';

function useProductQuantity({catalog_product, cart, order, delivery_route, buying_for_user, config_index, setCp, source}){
    const [cp, setCatalogProduct] = useState(catalog_product);
    // prioritze order specific delivery data
    const delivery_time = order?.delivery_time || cart?.delivery_time || delivery_route.selected_delivery_time;
    const delivery_point_name = order?.delivery_point_name || cart?.delivery_point_name || delivery_route.selected_delivery_point_name;;
    
    const [qty, setQty] = useState(0);
    const [prev_sync_qty, setPrevSyncQty] = useState(0);
    const order_qty = order?.items?.[cp._id]?.qty || 0;

    const configs = {...(order?.items?.[cp._id]?.configs || {}), ...(cart?.items?.[cp._id]?.configs || {})}
    const sub_products = Object.entries(configs || {}).filter(([i, config]) => !!config?.sub_selections);
    const sub_product = configs[config_index] || null;
    const [config_qty, setConfigQty] = useState(0);
    const [prev_sync_config_qty, setPrevSyncConfigQty] = useState(0);
    const config_order_qty = order?.items?.[cp._id]?.configs?.[config_index]?.qty || 0;

    const {CustomPricingCache} = window;

    useEffect(()=>{
        setCatalogProduct(catalog_product)
    }, [catalog_product])

    const updateCart = (diff_qty, params) => {
        setPrevSyncQty(qty); // checkpoint
        setPrevSyncConfigQty(config_qty); // checkpoint
        
        return new Promise((resolve, reject) => {
            axios.post(
                `/api/cart/diff/${delivery_route._id}`,
                {
                    "diff_qty": diff_qty || null,
                    "catalog_product_id": cp._id,
                    "delivery_time": delivery_time,
                    "delivery_point_name": delivery_point_name,
                    "buying_for_user_id": buying_for_user?._id,
                    "cart_id": cart._id,
                    'existing_order_id': order?._id,
                    "unit_price": (CustomPricingCache[cp._id] && CustomPricingCache[cp._id] / 1000) || null,
                    ...(params || {})
                }
            ).then(
                (resp) => {
                    if(resp.data.errors){
                        Popup.show("errors while changing cart", <GenericException ex={resp.data.errors} />)
                    }
                    let _cart = resp.data.cart || {...cart};
                    if(resp.data.updated_items){
                        Object.assign(_cart.items, resp.data.updated_items);
                    }
                    if(resp.data.pricing){
                        _cart.pricing = resp.data.pricing;
                    }
                    // update the cart
                    broadcaster.broadcast_event("cart_updated", _cart);
                    /* udpate the cp from the returned cart item if any*/
                    let _cp = _cart.items[cp._id].catalog_product;
                    if (_cp) {
                        setCatalogProduct({...cp, ..._cp})
                        setCp && setCp({...cp, ..._cp})
                    }
                    resolve()
                }
            ).catch(() => {
                setQty(prev_sync_qty); //reset
                setPrevSyncQty(prev_sync_qty);
                setConfigQty(prev_sync_config_qty);
                setPrevSyncConfigQty(prev_sync_config_qty);
            })
        })
    }

    useEffect(
        () => {
            const timer = setTimeout(
                () => {
                    if(qty - prev_sync_qty === 0){
                        return;
                    }
                    let diff_qty = qty - prev_sync_qty
                    /* track event */
                    diff_qty > 0
                    ?   track_event("add_to_cart", {"currency": delivery_route.currency, value: cp.price / 1000 * diff_qty})
                    :   track_event("remove_from_cart", {"currency": delivery_route.currency, value: cp.price / 1000 * -diff_qty})
                    if (config_qty - prev_sync_config_qty !== 0) {
                        updateCart(diff_qty, {
                            config: config_qty === 0 ? {} : {...sub_product, qty: config_qty},
                            config_index
                        })
                    } else if (sub_products?.length === 1) {
                        updateCart(diff_qty, {
                            config: prev_sync_qty + diff_qty === 0 ? {} : {...sub_products[0][1], qty: prev_sync_qty + diff_qty},
                            config_index: sub_products[0][0]
                        })
                    } else {
                        updateCart(diff_qty)
                    }
                },
                500
            );
            return () => clearTimeout(timer);
        }, [cp, order, qty, prev_sync_qty, delivery_route._id, cart?._id]
    );

    /* update this item is cart updated */
    useEffect(
        () => {
            // if cart update in progress return
            if(prev_sync_qty !== qty){
                return;  
            }

            var _qty = 0;
            if(order?.items){
                _qty += order?.items[cp._id]?.qty || 0;   
            }
            if(cart?.items){
                _qty += (cart?.items[cp._id]?.diff_qty || 0);
            }
            /* don't update if some update is already going on */
            setQty(_qty);
            setPrevSyncQty(_qty);
        },
        [cart, cp._id, order?.items]
    )

    /* update this item if sub-product updated */
    useEffect(
        () => {
            // if cart update in progress return
            if(!sub_product || prev_sync_config_qty !== config_qty){
                return;  
            }
            setConfigQty(sub_product.qty);
            setPrevSyncConfigQty(sub_product.qty);
        },
        [sub_product?.qty]
    )

    const getDiffQty = (val) => {
        val = parseInt(val) || 0;
        val = Math.max(0, Math.min(val, cp.max_qty_per_user || 500, cp.qty + prev_sync_qty));
        return val - qty;
    }

    const updateQty = (val) => {
        const diff_qty = getDiffQty(val);
        // play a sound
        playCartChangeSound(diff_qty);
        setQty(val);
    }

    const updateConfigQty = (config_qty_val) => {
        const diff_qty = getDiffQty(qty + config_qty_val - config_qty) ;
        if (diff_qty !== 0) {
            playCartChangeSound(diff_qty);
            setQty(qty + diff_qty);
            setConfigQty(config_qty + diff_qty);
        }
    }

    const addNewSubProduct = (config) => {
        const diff_qty = getDiffQty(qty + 1);
        if (diff_qty === 1) {
            playCartChangeSound(diff_qty);
            config.qty = 1;
            updateCart(diff_qty, {config, config_index: Object.keys(configs || {}).length + 1 })
        }
    }
    const updateSubProduct = (config) => {
        updateCart(0, {config, config_index})
    }

    return {
        cp, 
        qty: sub_product ? config_qty : qty, 
        order_qty: sub_product ? config_order_qty : order_qty, 
        sub_products, 
        sub_product, 
        updateQty: sub_product ? updateConfigQty : updateQty, 
        updateCart,
        addNewSubProduct,
        updateSubProduct
    }
}

export default useProductQuantity