import React, { useRef, useState } from 'react'
import { SuggestedField } from 'base/ui/suggested_field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import CatalogProduct from '../store/product/CatalogProduct';


function SuggestedProduct ({cp, delivery_route}) {
    return (
      <CatalogProduct delivery_route={delivery_route} catalog_product={cp} price={cp.price} can_modify={false} />
    )
}
  
function SelectedProduct ({cp, delivery_route, onQtyChange, onRemove}) {
    const [qty, setQty] = useState(1);
  
    const updateQty = (val) => {
      val = parseInt(val) || 0;
      val = Math.max(1, Math.min(val, cp.max_qty_per_user || 500, cp.qty));
      // play a sound
      setQty(val);
      val !== qty && onQtyChange(val, cp);
    }
  
    return (
      <div className='tw-w-full hflex tw-gap-2 tw-bg-white' key={cp._id} onClick={(e) => e.preventDefault()}>
        <div className="w3-padding-topbottom-8">
          <input type="checkbox" 
              checked 
              className="w3-check" 
              onChange={onRemove}
          />
        </div>
        <SuggestedProduct cp={cp} delivery_route={delivery_route} />
        <div>
          <span className='tw-text-xs'>Qty:</span>
          <div className='tw-flex tw-items-center app-qty-change'>
            <FontAwesomeIcon icon={faMinusSquare} className="w3-text-light-grey" onClick={() => updateQty(qty - 1)} />
            &nbsp;&nbsp;<span>{qty || 1}</span>&nbsp;&nbsp;
            <FontAwesomeIcon icon={faPlusSquare} className="app-plus-button" onClick={() => updateQty(qty + 1)} />
          </div> 
        </div>
      </div>
    )
}
  
  function SelectProducts({delivery_route, onSelect, max_selections, className, input_className, show_float_label, is_disabled}) {
    const ctx = useRef({}).current;
  
    const onProductsSelect = (products) => {
      const new_products_qty = {};
      products?.forEach(cp => new_products_qty[cp._id] = ctx.products_qty?.[cp._id] || 1);
      ctx.products_qty = new_products_qty;
      ctx.products = products;
      onSelect({products, products_qty: new_products_qty});
    }
  
    const onProductQtyChange = (qty, cp) => {
      ctx.products_qty[cp._id] = qty;
      onSelect({products: ctx.products, products_qty: ctx.products_qty});
    }

    return (
        <div className='tw-relative'>
            <SuggestedField
                className={className}
                input_className={`${show_float_label ? 'floating-label-input' : ''} ${input_className}`}
                props={{
                    "endpoint": `/api/products/search/${delivery_route._id}`,
                    "results_key_path": "catalog_products",
                    "max_selections": max_selections || 10,
                    "placeholder": "Search for Products",
                    "result_renderer": (cp) => <SuggestedProduct cp={cp} delivery_route={delivery_route} />,
                    "selected_renderer": (cp, onRemove) => <SelectedProduct cp={cp} delivery_route={delivery_route} onQtyChange={onProductQtyChange} onRemove={onRemove} />,
                    "is_disabled": is_disabled
                }}
                selected={[]}
                onSelect={onProductsSelect}
            />
            {show_float_label ? <label className={'floating-label'}>{max_selections === 1 ? 'Select Product' : 'Select products'}</label> : null}
        </div>
    )
}

export default SelectProducts
