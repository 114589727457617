import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { GenericException } from 'base/ui/errors';
import { useOnScroll } from "base/ui/utils";
import { EmptyView, LoadingOverlay, LoadingView } from 'base/ui/status';
import useCheckout from './useCheckout';
import { OrderDeliveryViewShort } from '../deliveryRoute/orders/OrderDeliveryView';

const UserOrders = ({user, buying_for_user, delivery_route, container}) => {
    const ctx = useRef({has_more: true}).current;
    const [orders, setOrders] = useState(null);
    const [is_loading, setIsLoading] = useState(false);
    const el_ref = useRef(null);

    useCheckout({cart: null, delivery_route, buying_for_user});

    const getPastOrders = () => {
        if(!ctx.has_more || is_loading) return;
        setIsLoading(true);
        axios.post(
            `/api/user/orders/${delivery_route?._id}`,
            {
                "cursor": ctx.next_cursor,
                "buying_for_user_id": buying_for_user?._id
            }
        ).then(
            (resp) => {
                if(resp.data.errors){
                    GenericException.showPopup(resp.data.errors);
                    return;
                }
                setOrders([...(orders || []), ...resp.data.orders]);
                ctx.next_cursor = resp.data.next_cursor;
                ctx.has_more = resp.data.has_more
            }
        ).finally(
            () => setIsLoading(false)
        );
    }
    useEffect(() => {getPastOrders()}, []);
    useOnScroll(el_ref.current, getPastOrders, []);

    const refetchOrders = () => {
        ctx.has_more = true;
        ctx.next_cursor = 0;
        getPastOrders();
    }

    return (
        <div className='w3-list w3-overflow-auto' ref={el_ref}>
            {is_loading ? orders?.length ? <LoadingOverlay  /> : <LoadingView title="Loading.." height={100} /> : null}
            {!orders?.length
                ?   !is_loading ? <EmptyView title={'No orders found.'} /> : null
                :   <div className='tw-bg-gray-bg tw-p-4'>
                    {
                        orders?.map(
                            (order) => <OrderDeliveryViewShort
                            key={order._id}
                            order={order}
                            delivery_route={delivery_route}
                            className="tw-bg-white tw-rounded tw-mb-4 tw-border"
                            container={container}
                            refetchOrders={refetchOrders}
                            buying_for_user={buying_for_user}
                          />
                        )
                    }
                    </div>
            }
        </div>
    )
}

export default UserOrders