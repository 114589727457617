import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';

function SearchView({ className, placeholder, onSearch, debounceTime }) {
    const [search_text, setSearchText] = useState("");

    useEffect(() => {
        var timer = setTimeout(
            () => {
                onSearch(search_text)
            },
            debounceTime || 300
        )
        return () => clearTimeout(timer);
    }, [search_text])

    return <div className={`tw-border tw-rounded-md tw-text-sm tw-px-3 tw-py-2 tw-flex tw-items-center ${className}`}>
        <FontAwesomeIcon icon={faSearch} className='tw-text-gray-400' />
        <input className='tw-outline-0 tw-ml-3 tw-text-sm tw-w-full'
            value={search_text}
            placeholder={placeholder || "Search"}
            onChange={(evt) => {
                setSearchText(evt.target.value);
            }}
        />
        {
            search_text
                ? <FontAwesomeIcon icon={faX} className='tw-ml-auto tw-p-3 tw--m-3 tw-text-gray-600 tw-text-xs' onClick={() => setSearchText("")} />
                : null
        }
    </div>
}

export { SearchView }