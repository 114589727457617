import React from "react";

// make a website page with terms and conditions

export const PrivacyPolicy = () => {
  return (
    <div className="tw-scroll-smooth tw-p-12 font-figtree">
      <h1 className="tw-font-bold" style={{ fontSize: 32 }}>
        Privacy Policy
      </h1>
      <br />
      <p>
        This Privacy Policy outlines how we collect, use, and protect personal
        information obtained through our website and services, in accordance
        with the European General Data Protection Regulation (GDPR). By using
        our website and services, you consent to the practices described in this
        policy.
      </p>
      <br />
      <ul className="tw-list-decimal tw-list-inside">
        <li>
        Information We Collect We may collect and process the following
        categories of personal information:
      <br />
      <ul className="w3-ul">
        <li>
          • Contact Information: Name, email address, phone number, and other
          contact details
        </li>
        <li>
          • Account Information: Usernames, passwords, and other credentials
          used to access our services.
        </li>
        <li>
          • Usage Information: Information about how you interact with our
          website and services, including IP address, browser type, referring
          pages, and pages visited.
        </li>
      </ul>
      </li>

      <br />
      <li>
        Purpose of Processing Personal Information We collect and process
        personal information for the following purposes:
      <br />
      <ul className="w3-ul">
        <li>• Providing and improving our services.</li>
        <li>
          • Communicating with you, including responding to inquiries and
          providing support.
        </li>
        <li>
          • Personalizing and tailoring your experience with our services.
        </li>
        <li>
          • Conducting analytics to understand usage patterns and enhance our
          website and services.
        </li>
        <li>
          • Complying with legal obligations and protecting our rights and
          interests.
        </li>
      </ul>
      </li>
      <br />
      <li>
        Lawful Basis for Processing We rely on the following lawful bases for
        processing personal information:
      <br />
      <ul className="w3-ul">
        <li>
          • Contractual Necessity: Processing is necessary to fulfill a contract
          with you or take pre-contractual steps at your request.
        </li>
        <li>
          • Legitimate Interests: Processing is necessary for our legitimate
          interests, provided that your rights and freedoms do not override
          those interests.
        </li>
        <li>
          • Consent: Processing is based on your explicit consent, which you may
          withdraw at any time.
        </li>
      </ul>
      </li>
      <br />
      <li>
        Data Retention We retain personal information for as long as
        necessary to fulfill the purposes outlined in this Privacy Policy,
        unless a longer retention period is required or permitted by law. When
        personal information is no longer needed, we will securely dispose of or
        anonymize it.
        </li>
      <br />
      <li>
        Data Sharing and Transfers We may share personal information with
        third parties in the following circumstances:
      <br />
      <ul className="w3-ul">
        <li>
          • Service Providers: We engage trusted service providers who assist us
          in delivering our services and processing personal information on our
          behalf.
        </li>
        <li>
          • Legal Compliance: We may share personal information to comply with
          legal obligations, enforce our rights, or respond to lawful requests
          from public authorities.
        </li>
      </ul>
      </li>
      <br />
      <li>
        Your Rights You have certain rights regarding your personal
        information, subject to applicable laws. These rights may include the
        right to access, rectify, restrict, or delete personal information we
        hold about you. To exercise your rights, please contact us using the
        information provided below.
      </li>
      <br />
      <li>
        Data Security We implement appropriate technical and organizational
        measures to safeguard personal information against loss, unauthorized
        access, and misuse. However, please note that no data transmission over
        the internet or electronic storage method is 100% secure.
      </li>
      <br />
      <li>
        Changes to this Privacy Policy We may update this Privacy Policy from
        time to time to reflect changes in our practices or applicable laws. We
        encourage you to review this policy periodically.
      </li>
      <br />
      <li>
        Contact Us If you have any questions or concerns about this Privacy
        Policy or our data practices, please contact us at connect@sukhiba.com.
      </li>
      </ul>
    </div>
  );
};
