import { useCallback, useEffect, useRef, useState } from 'react'
import { broadcaster, useBroadcastedState, useLocalStorageState } from 'base/utils/events'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { cache } from './user';
import { track_event } from './analytics';

const GET_STAFF_LOCATION_INTERVAL = 60000;
const GPS_TIMEOUT = 10000;
var IS_LOADING = false;

function getPosition(options) {
    return new Promise((resolve, reject) => 
        navigator.geolocation.getCurrentPosition(resolve, reject, options)
    );
}

async function getCurrentLocation(options) {
    const {is_retry, show_alert} = options || {};
    let data;
    try {
        data = await getPosition({enableHighAccuracy: !is_retry, timeout: GPS_TIMEOUT, maximumAge: 5000})
        const {latitude, longitude} = data.coords;
        return [latitude, longitude];
    } catch (err) {
        if (err.code === 1 && show_alert) {
            alert('Please enable location permission to check-in. You can enable it from your settings.')
            return;
        } 
        if (is_retry) {
            track_event("gps_error", {"status_code": err.code});
            return;
        }
        return await getCurrentLocation({is_retry: true});
    }
}

export async function sendStaffLocation(checkin_route_id, info = {}, loc_options = {}) {
    if (IS_LOADING) return;
    IS_LOADING = true;
    const lat_long = await getCurrentLocation(loc_options);
    if (lat_long) {
        try {
            const resp = await axios.post(`/api/admin/staff/gps/${checkin_route_id}`, {lat_long, info});
            return resp.data?.logged;
        } finally {
            IS_LOADING = false;
        }
    }
    return null;
}

const useStaffLocation = () => {
    const {status: staff_checkin_status, checkin_route_id, timestamp} = useStaffCheckIn();
    const ctx = useRef({}).current;

    const handleCustomerNotes = useCallback(
        (message_sent) => {
            if (message_sent?.inbox_id?.startsWith('customer_notes_')) {
                const session_id = message_sent.inbox_id.split('_');
                const customer_id = session_id[session_id.length - 1];
                const delivery_route_id = session_id[session_id.length - 2];
                // update user with added notes
                axios.post(`/api/admin/user/${delivery_route_id}`,
                    {
                        user_id: customer_id, 
                        created_at: message_sent.created_at, 
                        inbox_id: message_sent.inbox_id,
                        action: 'log_customer_note'
                    }
                )
                // update staff location log info
                if (delivery_route_id === checkin_route_id) {
                    sendStaffLocation(checkin_route_id, {buying_for_user_id: customer_id, is_customer_notes: true})
                }
            }
        }, [checkin_route_id]
    )
    
    useEffect(() => {
        /* keep polling for location every 20 seconds */
        var gps_poller = null;
        if (staff_checkin_status === CHECKIN_STATUS.CHECKED_IN) {
            gps_poller = setInterval(
                () => {
                    if (Date.now() - Number(timestamp) >= (12*60*60*1000)) {
                        clearInterval(gps_poller);
                    } else {
                        sendStaffLocation(checkin_route_id)
                    }
                }, GET_STAFF_LOCATION_INTERVAL
            )
        } else {
            clearInterval(gps_poller)
        }
        return () => {
            clearInterval(gps_poller);
        }
    },  [staff_checkin_status])

    useEffect(
        () => {
          broadcaster.add_event_listener("chat:message_sent", handleCustomerNotes);
          return () => broadcaster.remove_event_listener("chat:message_sent", handleCustomerNotes)
        }, [handleCustomerNotes]
    );
}

const useStaffCheckIn = () => {
    const [staff_checkin, setStaffCheckIn] = useLocalStorageState('staff_checkin');
    const [last_checkin_status, timestamp, last_checked_route_id] = staff_checkin?.split('_') || [];
    const params = useParams();
    const status = 
        (last_checkin_status === CHECKIN_STATUS.CHECKED_IN && Date.now() - Number(timestamp) < (12*60*60*1000))
            ? CHECKIN_STATUS.CHECKED_IN
            : CHECKIN_STATUS.NEED_CHECK_IN;
    const [loading, setLoading] = useState(false);
    const [delivery_route_id] = useBroadcastedState('delivery_route_id');
    const checkin_route_id = status === CHECKIN_STATUS.CHECKED_IN ? last_checked_route_id : (delivery_route_id || params.delivery_route_id || window.delivery_route_id || cache.get("selected_delivery_route_id"));

    const staffCheckIn = async () => {
        /* Toggle Check-in status */
        const new_status = status === CHECKIN_STATUS.CHECKED_IN ? CHECKIN_STATUS.NEED_CHECK_IN : CHECKIN_STATUS.CHECKED_IN;
        /* Get Delivery route ID */
        if (!checkin_route_id) {
            alert('You have not accessed any shop yet.')
            return;
        }
        setLoading(true);
        try {
            const resp = await sendStaffLocation(checkin_route_id, {status: new_status}, {show_alert: true})
            if (resp) {
                setStaffCheckIn(new_status + '_' + Date.now() + '_' + checkin_route_id)
            }
        } finally {
            setLoading(false)
        }
    }

    return {
        status, 
        timestamp: Number(timestamp), 
        checkin_route_id, 
        staffCheckIn,
        is_loading: loading
    }
}

const CHECKIN_STATUS = {
    'CHECKED_IN': '1',
    'NEED_CHECK_IN': '0'
}

export {useStaffLocation, useStaffCheckIn, CHECKIN_STATUS}
