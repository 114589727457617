import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate } from 'react-router-dom'

const BackHeader = ({title, className, onBack}) => {
  const navigate = useNavigate();
  const {pathname, key} = useLocation();

  const goBack = () => {
    if (key !== 'default') {
      navigate(-1);
    } else {
      if (pathname.startsWith('/admin')) {
        navigate('/admin', {replace: true})
      } else {
        navigate('/', {replace: true});
      }
    }
  }
  
  return (
    <div className={`tw-w-full tw-flex tw-items-center tw-text-md tw-h-12 ${className || 'tw-bg-primary tw-text-white'}`}>
      <FontAwesomeIcon icon={faChevronLeft} className='tw-py-2 tw-px-4' onClick={onBack ? onBack : goBack} />
      <span>{title}</span>
    </div>
  )
}

export default BackHeader