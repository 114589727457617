import { faCheckCircle, faExclamationTriangle, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useRef, useState } from "react";

import { GenericException } from "base/ui/errors";
import { Popup } from "base/ui/popups";
import { LoadingOverlay } from "base/ui/status";

import ICON_EXCEL from "../../../images/gsheets.svg";

const UploadSheetView = ({ props, onCancel, onSubmit }) => {
  const input_field = useRef();
  const [files, setFiles] = useState([]);

  const [upload_progress, setUploadProgress] = useState(false);
  const [uploader_border_color, setUploaderBorderColor] = useState("black");


  function handleFileChange(evt) {
    const file_data_obj = evt.target?.files[0] || evt.files[0];
    input_field.current.value = ''; // clear the input field
    setFiles([file_data_obj])
  }

  const uploadSheet = () => {
    if (!files?.length) {
      return
    }
    const form_data = new FormData();
    form_data.set("file", files[0]);
    axios.post(props.sync_url,
      form_data, {
      onUploadProgress: (progress_event) => {
        var percent_completed = Math.round((progress_event.loaded * 100) / progress_event.total);
        setUploadProgress(percent_completed);
      }
    }).then((resp) => {
      if (resp.data.errors) {
        return GenericException.showPopup(resp.data.errors) || false;
      }
      Popup.show("", <div>
        <div className='tw-grid tw-place-items-center tw-p-4'>
          {
            resp.data.sucessfully_synced > 0
              ? <div className="tw-grid tw-place-items-center tw-text-landing-black">
                <FontAwesomeIcon icon={faCheckCircle} style={{ width: 56, height: 56 }} color="#16A713" />
                <h4>{`${resp.data.sucessfully_synced} Row(s) Successfully updated`} </h4>
              </div>
              : <FontAwesomeIcon icon={faExclamationTriangle} style={{ width: 56, height: 56 }} color="#E9573F" />
          }
        </div>
        {
          resp.data.failed_to_sync > 0
            ? <h6 className="tw-text-red-400 tw-text-xs tw-p-2"> {`Failed: ${resp.data.failed_to_sync}`}
              {
                resp.data.file_upload_errors?.map(error => (
                  <p>{error}</p>
                ))
              }
            </h6>
            : null
        }
      </div>
      )
      onSubmit && onSubmit(resp.data)
      return null
    }).finally(() => {
      setUploadProgress(0)
    })
  }

  return <div className="tw-p-4">
    {
      upload_progress > 0
        ? <LoadingOverlay />
        : null
    }
    <div className="tw-p-4 tw-flex tw-gap-4 tw-items-top tw-justify-between tw-border-b">
      <div className="tw-flex tw-gap-4 tw-items-top ">
        <img src={ICON_EXCEL} alt="ic_excel" className="tw-h-8 tw-mt-1" />
        <div >
          <span className="tw-text-md">Download Sample</span>
          <p className="tw-text-xs tw-text-gray-500">Excel sheet will be updated with new columns that are added</p>
        </div>
      </div>
      <button
        type="button"
        className="btn-primary-v2 tw-h-max" onClick={() => {
          window.open(
            props?.sample_file ||
            "https://storage.googleapis.com/sukhiba/mobilefresh/fe-assets/static/media/Customers%20Upload.xltx"
          )
        }}>Download</button>
    </div>
    <div className="tw-p-4">
      <span className="tw-text-md">Upload File</span>
      <p className="tw-text-xs tw-text-gray-500">Add all the data to the excel/csv sheet and upload</p>
    </div>
    {
      files.length < 1
        ? <div className="tw-flex tw-mb-4 tw-mx-4" >
          <input type="file" className="w3-display-none" ref={input_field} onChange={(evt) => handleFileChange(evt)} />
          <div className="tw-relative tw-mb-2" style={{ "border": `1px dotted ${uploader_border_color}`, "minHeight": "100px", "width": "100%" }}
            onClick={() => input_field.current.click()}
            onDragEnter={() => setUploaderBorderColor("green")}
            onDragOver={(evt) => { evt.preventDefault(); setUploaderBorderColor("green"); }}
            onDragLeave={() => setUploaderBorderColor("black")}
            onDragEnd={() => setUploaderBorderColor("black")}
            onDrop={(evt) => {
              evt.preventDefault();
              setUploaderBorderColor("black");
              evt.dataTransfer && evt.dataTransfer.files && handleFileChange(evt.dataTransfer);
            }}
          >
            <div className="w3-display-middle w3-center">
              <div className="w3-bold">Upload File</div>
              <div className="w3-row w3-center w3-text-grey w3-small">
                Click/Drag drop
              </div>
            </div>
          </div>
          {
            upload_progress
              ? <div className="w3-row w3-margin-bottom-8 w3-border w3-border-light-grey w3-round w3-overflow-hidden">
                <div style={{
                  "backgroundColor": "green",
                  //  just show small width percentage to display color
                  "width": `${upload_progress}"%"`,
                  "height": "10px",
                }}
                />
              </div>
              : null

          }
        </div>
        : <div className="tw-px-4 tw-py-2 tw-flex  tw-items-center tw-border tw-justify-between tw-mb-4 tw-mx-4">
          <div className="tw-flex tw-items-center tw-gap-2">
            <img src={ICON_EXCEL} alt="ic_excel" className="tw-h-8 tw-mt-1" />
            <span className="tw-text-sm">
              {files[0].name}
            </span>
          </div>
          <FontAwesomeIcon className="tw-cursor-pointer" icon={faX} size="2xs" onClick={() => {
            setFiles([]);
          }} />
        </div>
    }

    <div className="tw-flex tw-justify-end tw-gap-2 tw-px-4">
      <button type="button" className="btn-secondary" onClick={() => onCancel()}>Cancel</button>
      <button type="button" className="btn-primary-v2" onClick={uploadSheet}>Upload</button>
    </div>
  </div>
}

export default UploadSheetView
