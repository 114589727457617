import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useBroadcastedState } from 'base/utils/events'
import BackHeader from '../layout/BackHeader'
import { createPortal } from 'react-dom'

const ResponsivePopup = ({show, children, title, titleRenderer, onClose, is_full_screen, container, style_props}) => {
    const {z_index, md_max_width} = style_props || {};
    const [screen] = useBroadcastedState('tw_screen_size')

    useEffect(() => {
        if (show) {
            window.addEventListener('popstate', onClose, {once: true});
            return () => {
                window.removeEventListener('popstate', onClose);
            }
        } else {
            window.removeEventListener('popstate', onClose);
        }
    }, [show])

    return show && createPortal(
        <div aria-labelledby="modal-title" role="dialog" aria-modal="true" style={{zIndex: z_index || 30}} className='tw-absolute'>
            <div className="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-75 tw-transition-opacity tw-z-10"></div>
            <div className="tw-fixed tw-inset-0 tw-z-20 tw-overflow-y-auto" style={{
					"animation": "0.3s slide-in-from-bottom"
				}}>
                <div className={`tw-flex tw-min-h-full tw-justify-center tw-w-full md:tw-items-center ${is_full_screen ? '' : 'tw-items-end'}`}>
                    <div className={`tw-bg-white tw-transform tw-transition-all max-md:tw-w-full max-md:tw-rounded-t-lg md:tw-rounded-lg tw-shadow-xl md:tw-min-w-[50%]`}
                        style={screen.md ? {maxWidth : md_max_width || '80%'} : {}}
                    >
                        {
                            titleRenderer 
                            ? titleRenderer()
                            :   is_full_screen && screen['max-md']
                                    ?   <BackHeader title={title} className='tw-bg-white tw-text-black' onBack={onClose} />
                                    :   <div className={`tw-w-full tw-h-12 hflex tw-pl-4 tw-text-gray-600 ${title ? 'tw-border-b' : ''}`}>
                                            {title && <span className='tw-text-sm tw-font-bold '>{title}</span>}
                                            <FontAwesomeIcon icon={faClose} width={22} height={22} className='tw-p-4 tw-cursor-pointer tw-ml-auto' onClick={onClose} />
                                        </div>
                            }
                        <div className={`${is_full_screen && screen['max-md'] ? 'popup-maxh tw-overflow-y-auto' : 'tw-max-h-[78vh] tw-min-h-[20vh] tw-overflow-y-auto'}`}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        container || document.body
    )
}

export default ResponsivePopup