import React from 'react'

const label_animation_classes = 'peer-placeholder-shown:tw-scale-100 peer-placeholder-shown:tw--translate-y-1/2 peer-placeholder-shown:tw-top-1/2 peer-focus:tw-top-2 peer-focus:tw-scale-75 peer-focus:tw--translate-y-4';

const FloatingLabelInput = ({placeholder, ...args}) => {
  return (
    <div className="tw-relative">
        <input className={'floating-label-input tw-peer tw-block'} placeholder=' ' {...args} />
        <label className={`floating-label ${label_animation_classes}`}>{placeholder}</label>
    </div>
  )
}

const FloatingLabelSelect = ({placeholder, children, ...args}) => {
  return (
    <div className="tw-relative">
        <select className={'floating-label-input tw-peer tw-block'} {...args}>
          {children}
        </select>
        <label className={'floating-label'}>{placeholder}</label>
    </div>
  )
}

export {FloatingLabelInput, FloatingLabelSelect}