import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { LoadingView } from 'base/ui/status';
import { Gallery, Share } from 'base/ui/misc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import ProductPriceQty from './ProductPriceQty';
import  {ReactComponent as CheckMark} from '../../../../images/checkmark.svg';
import CatalogProduct from './CatalogProduct';
import Product from './Product';
import NoSlotsShortView from '../NoSlotsShortView';


/* Full Display of the product with variants etc*/
function ProductDetails({catalog_product:_cp, onContinueShopping, ...args}) {
    const [cp, setCatalogProduct] = useState(_cp)
    const [all_variants, setAllVariants] = useState();
    const can_modify = !args.delivery_route.is_ordering_frozen;
    const [is_loading, setIsLoading] = useState(false);
    
    useEffect(()=>{
        setIsLoading(true);
        setCatalogProduct(_cp);
        axios
        .get(`/api/product/${args.delivery_route.catalog_id}/${_cp._id}`)
        .then((resp) => {
            if (resp.data.cp) {
                if (resp.data.cp_variants?.length) {
                    setAllVariants([resp.data.cp, ...resp.data.cp_variants])
                }
                resp.data.cp.recommended_products = resp.data.recommended_products;
                setCatalogProduct(resp.data.cp)
            }
        })
        .finally(() => {setIsLoading(false)});
    }, [_cp?._id])

    return (
        <div className='tw-pb-32 md:tw-flex md:tw-gap-6 md:tw-px-4 md:tw-min-w-[50ch] md:tw-max-w-[70ch]'>
            {
                !cp?.title || is_loading 
                    ?   <LoadingView height={100} />
                    :   <>
                            <div className='tw-relative tw-px-[20%] sm:tw-px-[25%] md:tw-px-0 md:tw-w-1/3 tw-mb-4 tw-h-fit'>
                                <Gallery files={cp.images?.[0]?.url ? cp.images : [args.delivery_route.image] } />
                                {
                                cp.data?.points_per_unit > 0 
                                &&  
                                    <Product.Points cp={cp} size="lg" />
                                }
                                <Share className='tw-flex tw-absolute tw-bottom-2 tw-right-4 tw-w-6 tw-h-6 flex-box tw-text-black tw-bg-white tw-rounded-full' 
                                    link={`${window.location.origin}/${args.delivery_route._id}?cp=${cp._id}`}
                                    // file_url={cp.images?.[0]?.url}
                                >
                                    <FontAwesomeIcon icon={faShareAlt} />
                                </Share>
                            </div>
                            <div className='md:tw-flex-1'> 
                                <Product.Title cp={cp} className={`tw-px-4 tw-mb-4`} />
                                {(cp.data?.is_bfc_only && !args.buying_for_user?._id) || !can_modify
                                ?   null
                                    :                                             
                                    <div className='tw-px-4 tw-pb-4 tw-text-lg tw-font-bold tw-border-b'>
                                        <ProductPriceQty
                                            catalog_product={cp}
                                            setCp={setCatalogProduct}  
                                            cart={args.cart}
                                            order={args.order}
                                            buying_for_user={args.buying_for_user}
                                            delivery_route={args.delivery_route}
                                        />
                                    </div>
                                }
                                {
                                    cp.variant_group_id && cp.variant_info
                                    ?   <div className='tw-p-4 tw-border-b'>
                                            {all_variants 
                                                ?   <CPVariants cp={cp} all_variants={all_variants} setCatalogProduct={setCatalogProduct} /> 
                                                :   is_loading 
                                                        ?   <div className='tw-animate-pulse'> {/* part loading animation */}
                                                                <div className="tw-h-2 tw-bg-slate-200 tw-rounded tw-mb-4" />
                                                                <div className='tw-grid tw-grid-cols-2 tw-gap-4'>
                                                                    <div className="tw-h-2 tw-bg-slate-200 tw-rounded" />
                                                                    <div className="tw-h-2 tw-bg-slate-200 tw-rounded" />
                                                                </div>
                                                            </div>
                                                        : null
                                            }
                                        </div>
                                    :   null
                                }
                                {
                                    cp.sku ? 
                                    <div className='tw-p-4 tw-border-b tw-flex tw-items-center'>
                                        <p className='tw-text-sm tw-font-bold tw-mr-1'>SKU:</p>
                                        <p className='tw-text-sm'>{cp.sku}</p>
                                    </div>
                                    : null
                                }
                                {
                                    cp.has_discounts
                                    ?   <div className='tw-p-4 tw-border-b'>
                                            <p className='tw-text-sm tw-font-bold'>Offers</p>
                                            <CatalogProductDiscountsFull cp={cp} delivery_route={args.delivery_route} cart={args.cart}  />
                                        </div>
                                    :   null
                                }
                                {cp.rich_text_description || cp.description
                                    ? <div className='tw-px-4 tw-py-2 tw-text-sm tw-text-gray-600 tw-whitespace-pre-wrap'
                                    dangerouslySetInnerHTML={{ __html: cp.rich_text_description || cp.description }}>
                                    </div>
                                    : null
                                }
                                {cp.recommended_products
                                ?   <div className='tw-mt-8 tw-mb-4 tw-py-4 tw-bg-gray-bg'>
                                        <div className='tw-mb-4 tw-text-sm tw-px-4'>You may also like:</div>
                                        <div className='tw-px-4 tw-gap-3 tw-flex tw-w-fit tw-max-w-[100%] tw-overflow-x-auto '>
                                            {
                                                cp.recommended_products?.map(
                                                    (cp) => {
                                                        return <CatalogProduct
                                                            className={'tw-outline tw-outline-1 tw-outline-gray-200 tw-shrink-0 tw-border-y tw-rounded-sm'}
                                                            catalog_product={cp}
                                                            key={cp._id}
                                                            image_height={100}
                                                            grid={true}
                                                            onViewProduct={null}
                                                            {...args}
                                                        />
                                                    }
                                                )
                                            }
                                        </div>
                                    </div>
                                :   null
                                }
                                <div className='tw-absolute tw-bg-white tw-bottom-0 tw-px-4 tw-py-2 md:tw-py-4 tw-text-sm max-md:tw-w-full tw-grid tw-grid-cols-2 tw-gap-4'>
                                    <button className='tw-border tw-rounded tw-px-4 tw-py-2 tw-w-full tw-text-gray-600' onClick={onContinueShopping}>
                                        <FontAwesomeIcon icon={faChevronLeft} />&nbsp;&nbsp;
                                        Back to Store
                                    </button>
                                    {
                                        cp.data?.is_bfc_only && !args.buying_for_user?._id && can_modify
                                        ?   <Product.Enquire cp={cp} delivery_route={args.delivery_route} 
                                                className='tw-px-4 tw-py-2 tw-border tw-border-primary-fade tw-rounded tw-bg-primary-fade tw-text-sm tw-whitespace-nowrap'
                                            />
                                        :   !can_modify
                                            ?   args.delivery_route.has_future_delivery_slots
                                            ?   <div className="tw-text-red-600 tw-text-xs">
                                                    Delivery closed for this slot, pick a new delivery slot
                                                </div>
                                            :   <NoSlotsShortView />
                                            :   null // TODO: Put Add button here
                                    }
                                </div>
                            </div>
                            
                        </>
            }
            
        </div>
    )
}

function CPVariants({ cp, all_variants, setCatalogProduct }) {
    const [all_variants_grouped, setAllVariantsGrouped] = useState();
    
    useEffect(() => {
        if (all_variants) {
            var grouping_variant_options = [];
    
            var available_variants = Object.keys(cp.variant_info);
            available_variants.map((variant_type, j) => {
            // variant_type = size, color etc
            var variant_options = [];
            all_variants?.map((v, i) => {
                var variant_value = v.variant_info?.[variant_type];
                if (!variant_options.includes(variant_value)) {
                variant_options.push(variant_value);
                }
            });
            var grouping = {type: variant_type, options: variant_options};
            grouping_variant_options.push(grouping);
            });
            setAllVariantsGrouped(grouping_variant_options);
        }
    }, [all_variants]);
  
    function isVariantAvailable(variant_type, variant_value) {
      const all_variant_types = all_variants_grouped.map(item => item.type);
      const other_variant_types = all_variant_types.slice(0, all_variant_types.indexOf(variant_type));

      if (other_variant_types.length == 0) {
        return all_variants?.find((cp_v) => cp_v.variant_info?.[variant_type] === variant_value);
      } else {
        return all_variants?.find((cp_v) => (
            cp_v.variant_info?.[variant_type] === variant_value &&
            other_variant_types.every((v_type) =>
                cp_v.variant_info?.[v_type] === cp.variant_info?.[v_type]
            )
        ));
      }
    }
  
    function setSelectedCP(variant_type, variant_value, available_variant) {
        if (available_variant) {
            setCatalogProduct(available_variant);
        } else {
            const new_variant = all_variants.find(cp_v => cp_v.variant_info?.[variant_type] === variant_value);
            if (new_variant) {
                setCatalogProduct(new_variant)
            }
        }
    }
  
    return (
      <div>
        <div>
          {all_variants_grouped &&
            all_variants_grouped.map((variant, i) => {
              return (
                <div key={i} className='tw-mb-2'>
                  <div className="w3-bold tw-mb-2 tw-capitalize tw-text-sm">
                    {variant.type}
                  </div>
                  {variant.options.map((each_variant_type, j) => {
                    const is_variant_available = isVariantAvailable(
                        variant.type,
                        each_variant_type
                    );
                    return (
                      <button
                        key={j}
                        className={`tw-shrink-0 tw-border tw-rounded tw-text-xs tw-px-2 tw-py-1 tw-capitalize tw-mr-3 tw-mb-2  ${
                          cp?.variant_info?.[variant.type] === each_variant_type
                            ? "tw-border-secondary-lighter tw-text-secondary-lighter tw-font-bold"
                            : ""
                        }
                        ${is_variant_available ? "" : "tw-opacity-50 w3-strike"} `}
                        onClick={(evt) => {
                            if (is_variant_available) {
                                setSelectedCP(variant.type, each_variant_type, is_variant_available);
                            }
                        }}
                      > 
                        {each_variant_type}
                      </button>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
    );
}

function CatalogProductDiscountsFull({ cp, delivery_route, cart }) {
    const [discounts_categorised, setDiscountsCategorised] = useState(null);
    useEffect(
        () => {
            axios.post(`/api/delivery_route/${delivery_route._id}/discounts`, { cp_id: cp?._id })
            .then((resp) => {
                var applied_discounts = [];
                var not_applied_discounts = [];
                var more_discount_prompts = [];
                //applied discounts
                var all_discounts = resp.data.discount_rules;
                let applied_codes = Object.keys(cart.pricing?.discounts || {}).map((code_cp_id) => code_cp_id.split("|")[0]);
                Object.entries(all_discounts).map(([code, discount]) => {
                    if(applied_codes.includes(code)){
                        applied_discounts.push([code, discount]);
                    } else {
                        not_applied_discounts.push([code, discount]);
                    }
                });
                Object.entries(cart.pricing?.discount_prompts || {}).map(([code, discount]) => {
                    if(all_discounts[code]){
                        more_discount_prompts.push([code, discount]);
                    }
                });     
                setDiscountsCategorised({
                    "applied_discounts": applied_discounts,
                    "not_applied_discounts": not_applied_discounts,
                    "more_discount_prompts": more_discount_prompts,
                });       
            }).finally(() => {});
        }, [cart?.pricing]
    );

    if(!discounts_categorised) return null;
    return (
        <div className='tw-mt-1'>
            {
                discounts_categorised.applied_discounts.map(([code, discount]) =>
                    <div key={code} className="tw-flex tw-gap-1 ">
                        <div>
                            <CheckMark style={{ width: 14, height: 14, marginTop: 3}} fill={"#38A169"} />
                        </div>
                        <p className={`tw-text-sm tw-text-[#38A169]`}>
                            {discount.description}
                        </p>
                    </div>
                )
            }
            {
                discounts_categorised.not_applied_discounts.map(([code, discount]) =>
                    <div key={code} className="tw-flex tw-gap-1 ">
                        <div>
                            <CheckMark style={{ width: 14, height: 14, marginTop: 3}} fill={"#DA5F1C"} />
                        </div>
                        <p className={`tw-text-sm tw-text-[#DA5F1C]`}>
                            {discount.description}
                        </p>
                    </div>
                )
            }
            {
                discounts_categorised.more_discount_prompts.map(([code, discount]) =>
                    <div key={code} className="tw-flex tw-gap-1 ">
                        <div>
                            <CheckMark style={{ width: 14, height: 14, marginTop: 3}} fill={"#DA5F1C"} />
                        </div>
                        <p className={`tw-text-sm tw-text-[#DA5F1C]`}>
                            {discount.description}
                        </p>
                    </div>
                )
            }
        </div>
    )
  }

export default ProductDetails
