import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  faCalendarAlt,
  faChevronDown,
  faCog,
  faGripLinesVertical,
  faList,
  faMessage,
  faTimes,
  faUserTie,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import axios from "axios";
import { getByIds, ServerObjCaches } from "base/get_by_ids";
import {
  DateView,
  duration2string,
  string2duration,
  TimeRangeView,
} from "base/ui/date";
import { EditableTable } from "base/ui/editable_table";
import { GenericException } from "base/ui/errors";
import { EditableTextArea, Gallery, InfoText, RoundUserImage, SimpleTableInput, TagsInput, ThumbnailFileView, UserBadge } from "base/ui/misc";
import { Popup } from "base/ui/popups";
import { EmptyView, LoadingOverlay, LoadingView } from "base/ui/status";
import { SuggestedField } from "base/ui/suggested_field";
import { useOnScroll } from "base/ui/utils";
import { idToTitle, last, popKey } from "base/utils";
import {
  getDisplayPrice,
  getPriceWithCurrency,
  getRandomColor,
  getStartOfDay,
  listOfListsToTable,
  objToEl,
  sanitizeToId,
  useRerender,
  useRerenderWithValue,
} from "base/utils/common";
import {
  broadcaster,
  useBroadcastedState,
  useLocalStorageState,
} from "base/utils/events";
import { SelectList } from "./components/ui/SelectList";
import { NumberBadge } from "./components/ui/commonUI";
import "./css/calendar.css";
import "./setupTailwind";
import { ChatSession } from "base/ui/chat";
import { OrgUserChatSupportButtons } from "./user";
import {
  AddTagsWithValues,
  SearchTagsWithEsValues,
  TagsWithTypesEditor,
} from "./common";
import { useCurrentUser } from "base/app";
import { LOCAL_TZ_OFFSET_MILLIS } from "base/constants";
import { FileUploader } from "base/file_uploader";
import { getScrollParent } from "base/utils/scrolling";

export const DEFAULT_TICKET_STATUSES = [
  [0, "Open"],
  [1, "Payment Pending"],
  [51, "In Progress"],
  [52, "Booking Confirmed"],
  [100, "Completed"],
  [200, "Cancelled"],
];

function getTicketStatusString(status, statuses_list) {
  statuses_list = statuses_list || DEFAULT_TICKET_STATUSES;
  for (let i = 0; i < statuses_list.length; i++) {
    if (statuses_list[i][0] >= status) {
      return statuses_list[i][1];
    }
  }
  return last(statuses_list)[1];
}

/* PREPROCESS SERVER DATA */
broadcaster.add_event_listener("payment_automations", (payment_automations) => {
  Object.entries(payment_automations).forEach(([pa_id, pa]) => {
    pa.amount_str = getPriceWithCurrency(pa.currency, pa.amount);
    pa.payment_automation_id = pa_id;
  });
});

let STATUS_STYLE = [
  [0, { backgroundColor: "#F1F1F1", color: "#000" }],
  [1, { backgroundColor: "#FFE9D5", color: "#CE6000" }],
  [51, { backgroundColor: "#D4E6FF", color: "#004CBD" }],
  [52, { backgroundColor: "#15956E26", color: "#15956E" }],
  [100, { backgroundColor: "#15951A14", color: "#14AA1A" }],
  [200, { backgroundColor: "#FFE4E4", color: "#950000" }],
];

function getTicketStatusStyle(status) {
  for (let i = 0; i < STATUS_STYLE.length; i++) {
    if (STATUS_STYLE[i][0] >= status) {
      return STATUS_STYLE[i][1];
    }
  }
  return last(STATUS_STYLE)[1];
}

function TicketsList({ tickets: _tickets, list_id, onReorder }) {
  const [tickets, setTickets] = useState(null);
  const [org] = useBroadcastedState("org");
  const [hidden_columns, setHiddenColumns] = useState({});
  const ctx = useRef({}).current;
  /* load all user */
  useEffect(() => {
    fetchUsersByIdsOnTickets(_tickets).then(() => setTickets(_tickets));
    /* any ticket with total_amount > 0 on ticket */
    setHiddenColumns({ amount: !_tickets.find((ticket) => ticket.amount > 0) });
  }, [_tickets]);

  const assignToMe = (ticket) => {
    if (ctx.is_loading) return;
    ctx.is_loading = true;
    axios
      .post(
        `/api/admin/org/${org._id}/ticket/${ticket._id}?action=assign_to_me`
      )
      .then((resp) => {
        if (resp.data.errors) {
          return GenericException.showPopup(resp.data.errors) || false;
        }
        fetchUsersByIdsOnTickets(resp.data.ticket).then(() => {
          Object.assign(ticket, resp.data.ticket);
          ticket.rerender?.();
        });
      });
  };

  if (!tickets) return null;
  return (
    <EditableTable
      cols={[
        {
          header: "Customer",
          render: (ticket) => <UserBadge user={ticket.user} />,
          size: { s: 60, l: 13 },
        },
        {
          header: "Title",
          render: (ticket) => (
            <div className="tw-text-xs tw-font-medium line-clamp-2">{ticket.title}</div>
          ),
          size: { s: 100, l: 20 },
          position: { m: 0 },
        },
        {
          header: "Status",
          render: (ticket) => {
            return (
              <div
                style={getTicketStatusStyle(ticket.status)}
                className="tag-tertiary"
              >
                {getTicketStatusString(
                  ticket.status,
                  ticket.type_info?.statuses || DEFAULT_TICKET_STATUSES
                )}
              </div>
            );
          },
          size: { s: 40 },
          position: { m: 1 },
        },
        {
          title: "Type",
          render: (ticket) => (
            <div className="tw-capitalize tw-text-xs">
              <div>
                {idToTitle(ticket._type)}
                {ticket.bookable_id ? " | " + ticket.bookable_id : ""}
              </div>
              {ticket.bookable_id ? (
                <div className="tw-mt-1 tw-text-secondary-text">
                  <TimeRangeView
                    className="tw-text-xs"
                    time_range={[ticket.time_a, ticket.time_b]}
                  />
                </div>
              ) : null}
            </div>
          ),
          size: { l: 15 },
        },
        !hidden_columns["amount"]
          ? {
              title: "Amount",
              render: (ticket) => {
                if (!ticket.amount_str && !ticket.paid_amount_str) return null;
                return (
                  <div className="tw-whitespace-nowrap tw-items-center tw-text-xs tw-truncate">
                    {ticket.payment_automation_data?.title}
                    {ticket.to_pay_str ? (
                      <div className="tw-flex tw-flex-row tw-space-x-2">
                        <div>Due :</div>
                        <div className="tw-font-semibold tw-text-secondary-text">
                          {ticket.to_pay_str}
                        </div>
                      </div>
                    ) : (
                      <div className="tw-text-green-400">Paid</div>
                    )}
                    {
                      /* don't show full amount for payment automations */
                      ticket.amount_str && !ticket.payment_automation_data ? (
                        <div className="tw-flex tw-flex-row tw-space-x-2">
                          <div>Total:</div>
                          <div className="tw-font-semibold tw-text-secondary-text">
                            {ticket.amount_str}
                          </div>
                        </div>
                      ) : null
                    }
                  </div>
                );
              },
            }
          : null,
        {
          title: "Assigned To",
          render: (ticket) => {
            return ticket.assigned_user_ids?.length > 0 ? (
              <div className="tw-flex tw-flex-row tw-flex-wrap tw-text-white tw-text-xs">
                {ticket.assigned_users?.map((assigned_user) => (
                  <div
                    className="tw-rounded-md tw-bg-black tw-p-1 tw-mr-1 tw-mt-1"
                    key={assigned_user._id}
                  >
                    {assigned_user.name}
                  </div>
                ))}
              </div>
            ) : (
              <p
                className="tw-bold tw-text-xs tw-text-primary-v2 tw-cursor-pointer"
                onClick={(evt) => {
                  evt.stopPropagation();
                  assignToMe(ticket);
                }}
              >
                Assign to me
              </p>
            );
          },
          size: { s: 60, l: 15 },
        },
        {
          title: "Last Updated",
          render: (ticket) => (
            <div className="tw-text-xs">
              <div>
                Updated <DateView millis={ticket.updated_at} relative={true} />
              </div>
              <div>
                Raised <DateView millis={ticket.created_at} relative={true} />
              </div>
            </div>
          ),
        },
        {
          title: "",
          render: (ticket) => {
            return (
              <div
                className="tw-relative tw-w-fit tw-p-2 tw-cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  openTicketChat(org, ticket);
                }}
              >
                {(ticket.wa_user?.cs_unseen_count || 0) > 0 ? (
                  <NumberBadge val={ticket.wa_user.cs_unseen_count} />
                ) : null}
                <FontAwesomeIcon
                  icon={faMessage}
                  className="tw-text-gray-700 tw-absolute tw-left-0"
                />
              </div>
            );
          },
          size: { l: 3 },
        },
      ]}
      rows={tickets}
      callbacks={{
        onRowClick: (ticket) => {
          /* just open a full view */
          Popup.sideSheet(
            <TicketFullView
              ticket={ticket}
              onUpdate={(_ticket) => ticket.rerender(_ticket)}
            />
          );
        },
        onReorder:
          onReorder ||
          (async (to_position, from_position) => {
            if (to_position === from_position) return;
            let ticket_src = tickets[from_position];

            const resp = await axios.post(
              `/api/admin/org/${ticket_src.org_id}/ticket/${ticket_src._id}?action=reorder`,
              {
                above_ticket_id: tickets[to_position]?._id,
                below_ticket_id: tickets[to_position - 1]?._id,
              }
            );
            if (resp.data.errors)
              return GenericException.showPopup(resp.data.errors) || false;
            Object.assign(ticket_src, resp.data.ticket);
            tickets.splice(from_position, 1, null);
            tickets.splice(to_position, 0, ticket_src);
            setTickets(tickets.filter((ticket) => ticket));
          }),
      }}
      options={{
        list_id: list_id,
        class_names: {
          t: {
            row: "tw-shadow-md tw-border tw-rounded-md tw-p-2 tw-mb-2 tw-bg-white",
          },
          s: {
            row: "tw-shadow-md tw-border tw-rounded-md tw-p-2 tw-mb-2 tw-bg-white",
          },
          m: {
            row: "tw-shadow-md tw-border tw-rounded-md tw-p-2 tw-mb-2 tw-bg-white",
          },
          l: {
            container: "tw-overflow-hidden tw-rounded-lg tw-border",
            row: "tw-items-center tw-border-b tw-border-gray-200 tw-bg-white",
          },
        },
      }}
    />
  );
}

function TicketLanes({ tickets, ticket_type_info }) {
  const [ticket_lanes, setTicketLanes] = useState(null);
  /* LANE VIEW SPECIAL HANDING */
  useEffect(() => {
    const statuses = ticket_type_info?.statuses || DEFAULT_TICKET_STATUSES;
    const lanes_statuses =
      ticket_type_info?.lanes || statuses.map(([status]) => status);
    const ticket_lanes = [];
    for (let i = 0; i < lanes_statuses.length; i++) {
      let status = lanes_statuses[i];
      ticket_lanes.push({
        title: getTicketStatusString(status, statuses),
        status: status,
        index: i,
        tickets: tickets.filter((ticket) => ticket.status === status[0]),
      });
    }
    for (let ticket of tickets) {
      const lane = ticket_lanes.find((lane) => lane.status > ticket.status);
      if (!lane || lane.index <= 0) continue;
      ticket_lanes[lane.index - 1].tickets.push(ticket);
    }
    setTicketLanes(ticket_lanes);
  }, [tickets, ticket_type_info]);

  if (!ticket_lanes) return null;

  /* reordering logic */
  const onReorder = async (
    to_position,
    from_position,
    status_to,
    status_from
  ) => {
    if (to_position === from_position && status_to === status_from) return;
    status_to = parseInt(status_to);
    status_from = parseInt(status_from);
    const destination_tickets_i = ticket_lanes.findIndex(
      (lane) => lane.status === status_to
    );
    const source_tickets_i = ticket_lanes.findIndex(
      (lane) => lane.status === status_from
    );

    const dest_tickets = ticket_lanes[destination_tickets_i].tickets;
    const src_tickets = ticket_lanes[source_tickets_i].tickets;
    let ticket_src = src_tickets[from_position];

    const resp = await axios.post(
      `/api/admin/org/${ticket_src.org_id}/ticket/${ticket_src._id}?action=reorder`,
      {
        above_ticket_id: dest_tickets[to_position]?._id,
        below_ticket_id: dest_tickets[to_position - 1]?._id,
        status: status_to,
      }
    );
    resp.data.ticket && Object.assign(ticket_src, resp.data.ticket);
    if (resp.data.errors)
      return GenericException.showPopup(resp.data.errors) || false;
    src_tickets.splice(from_position, 1, null);
    dest_tickets.splice(to_position, 0, ticket_src);
    ticket_lanes[source_tickets_i].tickets = src_tickets.filter(
      (ticket) => ticket
    );
    setTicketLanes([...ticket_lanes]);
  };

  return (
    <div className="tw-flex tw-flex-row tw-space-x-2">
      {ticket_lanes.map((ticket_lane) => (
        <div key={ticket_lane.status} style={{ width: "400px" }}>
          <div className="tw-font-bold">{ticket_lane.title}</div>
          <TicketsList
            tickets={ticket_lane.tickets}
            list_id={ticket_lane.status + ""}
            onReorder={onReorder}
          />
        </div>
      ))}
    </div>
  );
}

const TicketsCalendar = ({ filters, tickets }) => {
  const [screen] = useBroadcastedState("tw_screen_size");
  const [date_range, setDateRange] = useState([]);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [org_id] = useLocalStorageState("org_id");
  const ctx = useRef({}).current;
  const rerender = useRerender();

  useEffect(() => {
    /* set the initial time_a ticket are given */
    let from_time_a = tickets
      ?.filter((time_a) => time_a)
      .map((ticket) => ticket.time_a)
      .sort()[0];
    ctx.initial_date = from_time_a ? new Date(from_time_a) : new Date();
    rerender();
  }, [tickets]);

  const loadEvents = (tickets) => {
    setEvents(
      tickets
        .filter((ticket) => ticket.time_a)
        .map((ticket) => {
          let color = getRandomColor(ticket.status + "", 100);
          return {
            id: ticket._id,
            extendedProps: ticket,
            title: ticket.title,
            start: ticket.time_a,
            end: ticket.time_b,
            textColor: "white",
            borderColor: color,
          };
        })
    );
  };

  const fetchEvents = (start_date, end_date) => {
    if (ctx.is_loading) return;
    ctx.is_loading = true;
    rerender();
    const payload = {
      ...filtersToTicketSearchParams(filters),
      time_a: start_date.getTime(),
      time_a_end: end_date.getTime(),
    };
    axios
      .post(`/api/admin/org/${org_id}/tickets`, payload)
      .then((resp) => {
        loadEvents(resp.data.tickets);
      })
      .finally(() => {
        ctx.is_loading = false;
        rerender();
      });
  };

  if (!ctx.initial_date)
    return <LoadingView title={"Loding.."} height={"400px"} />;
  return (
    <FullCalendar
      plugins={[listPlugin, timeGridPlugin, dayGridPlugin]}
      initialDate={ctx.initial_date}
      initialView="listWeek"
      weekends={true}
      height={"auto"}
      aspectRatio={screen.md ? 2.1 : 1}
      datesSet={(dates) => {
        fetchEvents(dates.start, dates.end);
      }}
      headerToolbar={{
        left: "prev,next today",
        center: "title",
        right: "listWeek,timeGridWeek,dayGridMonth", // user can switch between the two
      }}
      // lazyFetching={true}
      events={events}
      eventClick={(e) => {
        let ticket = e.event._def.extendedProps;
        Popup.sideSheet(
          <TicketFullView
            ticket={ticket}
            onUpdate={(_ticket) => {
              tickets[tickets.findIndex((t) => t._id === _ticket._id)] =
                _ticket;
              loadEvents(tickets); // resets
            }}
          />
        );
      }}
      // eventContent={renderEventContent}
    />
  );
};

const filtersToTicketSearchParams = (filters) => {
  let assigned_user_ids = Object.values(filters.assigned_users).map(
    (user) => user._id
  );
  let ticket_types = Object.keys(filters.ticket_types);
  let payment_automation_ids = Object.keys(filters.payment_automations);
  let statuses = Object.keys(filters.statuses).map((status) =>
    parseInt(status)
  );
  let bookable_id = Object.keys(filters.bookables);
  return {
    statuses: statuses.length ? statuses : undefined,
    assigned_user_ids: assigned_user_ids.length ? assigned_user_ids : undefined,
    ticket_types: ticket_types.length ? ticket_types : undefined,
    search_text:
      filters.search_text?.length > 3 ? filters.search_text : undefined,
    tags: filters.tags?.length ? filters.tags : undefined,
    payment_automation_ids: payment_automation_ids.length
      ? payment_automation_ids
      : undefined,
    amount_due: filters.amount_due,
    bookable_id: bookable_id.length ? bookable_id : undefined,
    unassigned_tickets: filters.unassigned_tickets,
    sort_by: filters.sort_by || undefined,
  };
};

const openTicketChat = (org, ticket) => {
  ChatSession.open(`wcs_${ticket.user_id}_${org.wa_business_number}`, {
    bottomStatusIndicator: (session_data) => {
      return (
        <OrgUserChatSupportButtons
          org_id={org._id}
          user_id={ticket.user_id}
          session_data={session_data}
          onChatResolved={(cs_unseen_count) => {
            if (!cs_unseen_count) {
              ticket.wa_user && (ticket.wa_user.cs_unseen_count = 0);
              ticket.rerender?.();
            }
          }}
        />
      );
    },
    ...(TicketsPage.CHAT_OPTIONS || {}),
  });
};

/* 
	Page containing filterts and view selections and tickets list
*/
const TicketsPage = ({ org_id: _org_id }) => {
  const [org_id] = useLocalStorageState("org_id", _org_id);
  const [tickets, setTickets] = useState([]);
  const [tickets_count, setTicketsCount] = useState(null);
  const ctx = useRef({ tickets: [] }).current;
  const rerender = useRerender();
  const user = useCurrentUser();
  /* from server */
  const [bookables, setBookables] = useBroadcastedState("bookables", {});
  const [ticket_types, setTicketTypes] = useBroadcastedState(
    "ticket_types",
    {}
  );
  const [payment_automations, setPaymentAutomations] = useBroadcastedState(
    "payment_automations",
    {}
  );
  const [org, setOrg] = useBroadcastedState("org");
  /* seach filters */
  const filters = (ctx.filters = ctx.filters || {
    statuses: {},
    assigned_users: { [user._id]: user },
    ticket_types: {},
    payment_automations: {},
    bookables: {},
    unassigned_tickets: true,
  });
  const [active_view, setActiveView] = useLocalStorageState("ticket_view_type"); // 0 = list, 1 = calendar
  const tickets_el_ref = useRef(null);

  const [filters_updated, setFiltersUpdated] = useRerenderWithValue();
  /* side sheets */
  /* INIT */
  const fetchTickets = () => {
    if (ctx.is_loading || ctx.has_more === false) return;
    ctx.is_loading = true;
    rerender();

    axios
      .post(`/api/admin/org/${org_id}/tickets`, {
        ...filtersToTicketSearchParams(filters),
        cursor: ctx.next_cursor || "0",
        ...(ctx.init_data ? { fields: ["tickets"] } : {}), // after initial load, no new fields
      })
      .then((resp) => {
        /* update from server */
        let server_data = (ctx.init_data = resp.data);
        ctx.has_more = resp.data.has_more;
        ctx.next_cursor = resp.data.next_cursor;
        if (server_data.org) setOrg(server_data.org);
        if (server_data.bookables) setBookables(server_data.bookables);
        if (server_data.ticket_types)
          setTicketTypes(server_data.ticket_types || {});
        if (server_data.payment_automations)
          setPaymentAutomations(server_data.payment_automations);
        /* update into server caches */
        resp.data.users.forEach((user) => {
          ServerObjCaches["users"][user._id] = user;
        });

        setTicketsCount(resp.data.tickets_count);
        /* set ticket.type_info */
        server_data.ticket_types && resp.data.tickets.forEach((ticket) => {
          if(!ticket.type_info){
            ticket.type_info = server_data.ticket_types?.[ticket._type];
          }
        });
        ctx.tickets.push(...resp.data.tickets);
        fetchUsersByIdsOnTickets(resp.data.tickets).then(() =>
          setTickets([...ctx.tickets])
        );
      })
      .catch((err) => Popup.show("Errors", <GenericException ex={err} />))
      .finally(() => {
        ctx.is_loading = false;
        rerender();
      });
  };

  /* reset and fetch if filters change */
  useEffect(
    () => {
      ctx.has_more = null;
      ctx.next_cursor = null;
      ctx.tickets = [];
      fetchTickets();
    },
    [filters_updated] // refetch
  );

  /* SCROLLING */
  useOnScroll(
    tickets_el_ref.current,
    (percent) => {
      !active_view && percent > 95 && fetchTickets();
    },
    [tickets_el_ref.current, tickets]
  );

  /* HANDLERS */

  const showTicketTypesEditorPopup = () => {
    Popup.open(
      "Ticket Settings",
      <div className="tw-p-2">
        <TicketTypesEditor org_id={org_id} ticket_types={ticket_types} />
      </div>
    );
  };

  const showPaymentAutomationsPopup = () => {
    Popup.open(
      null, // no title
      <div className="tw-p-2 tw-mt-4">
        <EditPaymentAutomations org_id={org_id} />
      </div>
    );
  };

  const showBookablesEditorPopup = () => {
    Popup.open(
      "Bookables",
      <div className="tw-p-2">
        <EditBookables org_id={org_id} />
      </div>
    );
  };

  const onTicketSettingsClick = (evt) => {
    Popup.showContextMenu(
      evt.currentTarget,
      <div className="tw-p-1.5 tw-space-y-2">
        <div onClick={() => showTicketTypesEditorPopup()} className="tw-p-1.5 tw-mb-1 hflex tw-text-sm tw-h-8 tw-cursor-pointer hover:tw-rounded-md tw-text-[#303030]tw-font-medium hover:tw-bg-[#F6F6F6]">Ticket Types</div>
        <div onClick={() => showPaymentAutomationsPopup()} className="tw-p-1.5 tw-mb-1 hflex tw-text-sm tw-h-8 tw-cursor-pointer hover:tw-rounded-md tw-text-[#303030]tw-font-medium hover:tw-bg-[#F6F6F6]">
          Payment Automations
        </div>
        <div onClick={() => showBookablesEditorPopup()} className="tw-p-1.5 tw-mb-1 hflex tw-text-sm tw-h-8 tw-cursor-pointer hover:tw-rounded-md tw-text-[#303030]tw-font-medium hover:tw-bg-[#F6F6F6]">Bookables</div>
      </div>
    );
  };

  const doCreateNewTicket = () => {
    var popup = Popup.sideSheet(
      <div className="tw-p-2">
        <div className="tw-text-lg tw-font-semibold tw-p-2">
          Create New Ticket
        </div>
        <EditTicketData
          ticket={{ org_id: org._id }}
          onUpdate={(ticket) => {
            ctx.tickets.unshift(ticket); // add this ticket to the top
            fetchUsersByIdsOnTickets([ticket]).then(() =>
              setTickets([...ctx.tickets])
            );
            popup.close();
          }}
        />
      </div>
    );
  };

  /* loading */
  if (!ctx.init_data) return <LoadingOverlay />;
  /* select the first selected as the type */
  const selected_ticket_type_id = Object.keys(filters.ticket_types)[0];
  const selected_ticket_type = ticket_types[selected_ticket_type_id];
  const ticket_statuses = selected_ticket_type?.statuses || DEFAULT_TICKET_STATUSES;
  const tag_types = selected_ticket_type?.tag_types || {};

  return (
    <div ref={tickets_el_ref} className="tw-overflow-auto tw-p-4">
      <div className="hflex tw-flex-wrap tw-gap-x-4 tw-gap-y-2">
        <div>
          <div className="tw-text-lg tw-font-semibold">{`Tickets (${tickets_count?.value})`}</div>
          <p className="tw-text-xs tw-text-secondary-text tw-mt-2">
            Track and manage your enquires, bookings and assign them to your
            team members
          </p>
        </div>
        <div className="tw-flex tw-gap-3">
          <div className="">
            <button
              className={`tw-border tw-rounded-l-lg tw-p-2 ${
                !active_view
                  ? "tw-text-primary-v2 tw-border-primary-v2"
                  : "tw-text-secondary-text hover:tw-text-primary-v2"
              }`}
              onClick={() => setActiveView(null)}
            >
              <FontAwesomeIcon icon={faList} />
            </button>
            <button
              className={`tw-border tw-p-2 ${
                active_view === "calendar"
                  ? "tw-text-primary-v2 tw-border-primary-v2"
                  : "tw-text-secondary-text tw-border-x-0 hover:tw-text-primary-v2"
              }`}
              onClick={() => setActiveView("calendar")}
            >
              <FontAwesomeIcon icon={faCalendarAlt} />
            </button>
            {/* lanes */}
            <button
              className={`tw-border tw-rounded-r-lg tw-p-2 ${
                active_view === "lanes"
                  ? "tw-text-primary-v2 tw-border-primary-v2"
                  : "tw-text-secondary-text hover:tw-text-primary-v2"
              }`}
              onClick={() => setActiveView("lanes")}
            >
              <FontAwesomeIcon icon={faGripLinesVertical} />
            </button>
          </div>

          <button onClick={onTicketSettingsClick} className="btn-tertiary">
            <FontAwesomeIcon icon={faCog} className="tw-mr-1" />
            Settings
          </button>
          <button
            className="btn-primary-v2"
            onClick={() => doCreateNewTicket()}
          >
            + Create Manual Ticket
          </button>
        </div>
      </div>
      <div className="tw-flex tw-gap-4 tw-mt-6 tw-flex-wrap">
        <div className="tw-w-96">
          <SearchTagsWithEsValues
            tag_types={tag_types}
            onTextSearch={(text) => {
              filters.search_text = text;
              setFiltersUpdated();
            }}
            onTagsChange={(tags) => {
              filters.tags = tags;
              setFiltersUpdated();
            }}
          />
        </div>
        {
          Object.keys(ticket_types || {}).length 
          ? (
            <button
              className="dropdown tw-text-xs hflex tw-gap-4"
              onClick={(e) =>
                Popup.showContextMenu(
                  e.currentTarget,
                  <SelectList
                    items={
                      Object.keys(ticket_types).map((type) => [
                        type,
                        idToTitle(type),
                      ])
                    }
                    selected_values={Object.values(filters.ticket_types)}
                    onSelect={(_type_kv) => {
                      filters.ticket_types[_type_kv[0]] = _type_kv[1];
                      setFiltersUpdated();
                    }}
                  />
                )
              }
            >
              Type{" "}
              <FontAwesomeIcon icon={faChevronDown} className="tw-text-xs" />
            </button>
          ) : null
        }
        {/* <div className="tw-flex tw-gap-4 tw-flex-wrap tw-grow"> */}
          <button
            className="dropdown tw-text-xs hflex tw-gap-4"
            onClick={(e) =>
              Popup.showContextMenu(
                e.currentTarget,
                <SelectList
                  items={ticket_statuses}
                  selected_values={Object.values(filters.statuses)}
                  onSelect={(status_kv) => {
                    filters.statuses[status_kv[0]] = status_kv[1];
                    setFiltersUpdated();
                  }}
                />
              )
            }
          >
            Status{" "}
            <FontAwesomeIcon icon={faChevronDown} className="tw-text-xs" />
          </button>
          <button
            className="dropdown tw-text-xs hflex tw-gap-4"
            onClick={(e) =>
              openAssignUser(
                e,
                org_id,
                (users) => {
                  // on_select
                  filters.assigned_users = Object.fromEntries(
                    users.map((u) => [u._id, u])
                  );
                  setFiltersUpdated();
                },
                Object.values(filters.assigned_users) // selected
              )
            }
          >
            Assigned To
            <FontAwesomeIcon icon={faChevronDown} className="tw-text-xs" />
          </button>
          {Object.keys(payment_automations || {}).length ? (
            <button
              className="dropdown tw-text-xs hflex tw-gap-4"
              onClick={(e) =>
                Popup.showContextMenu(
                  e.currentTarget,
                  <SelectList
                    items={Object.entries(payment_automations).map(
                      ([pa_id, pa_data]) => [pa_id, pa_data.title]
                    )}
                    onSelect={(pa_kv) => {
                      filters.payment_automations[pa_kv[0]] = pa_kv[1];
                      setFiltersUpdated();
                    }}
                  />
                )
              }
            >
              Payment Automation{" "}
              <FontAwesomeIcon icon={faChevronDown} className="tw-text-xs" />
            </button>
          ) : null}
          {Object.keys(bookables || {}).length ? (
            <button
              className="dropdown tw-text-xs tw-hflex tw-gap-4"
              onClick={(e) =>
                Popup.showContextMenu(
                  e.currentTarget,
                  <SelectList
                    items={Object.entries(bookables).map(
                      ([bookable_id, bookable_data]) => [
                        bookable_id,
                        bookable_data.title,
                      ]
                    )}
                    onSelect={(bookable_kv) => {
                      filters.bookables[bookable_kv[0]] = bookable_kv[1];
                      setFiltersUpdated();
                    }}
                  />
                )
              }
            >
              Bookables{" "}
              <FontAwesomeIcon icon={faChevronDown} className="tw-text-xs" />
            </button>
          ) : null}
          <select
            className="dropdown"
            onChange={(e) => {
              filters.sort_by = e.target.value;
              setFiltersUpdated();
            }}
            value={filters.sort_by || ""}
          >
            <option value="">
              Sort By
            </option>
            <option value="created_at">
              Created At
            </option>
            <option value="updated_at">
              Updated At
            </option>
          </select>
          <div className="tw-flex tw-items-center tw-space-x-2">
            <input
              type="checkbox"
              id="unassigned"
              className="input-primary"
              checked={filters.unassigned_tickets}
              onChange={(e) => {
                filters.unassigned_tickets = e.target.checked;
                setFiltersUpdated();
              }}
            />
            <label htmlFor="unassigned" className="radio-label">Unassigned</label>
          </div>
          <div className="tw-flex tw-items-center tw-space-x-2">
            {" "}
            {/* unpaid */}
            <input
              type="checkbox"
              id="unpaid"
              className="input-primary"
              onChange={(e) => {
                filters.amount_due = e.target.checked ? 0 : undefined;
                setFiltersUpdated();
              }}
            />
            <label htmlFor="unpaid" className="checkbox-label">Unpaid</label>
          </div>
        {/* </div> */}
      </div>
      {/* display filters */}
      <div className="tw-my-4 tw-flex tw-flex-wrap tw-gap-4 tw-items-center">
        {Object.entries(filters.statuses).map(([_status, title]) => {
          return (
            <button
              className="tag-tertiary hflex tw-gap-2"
              onClick={() => {
                delete filters.statuses[_status];
                setFiltersUpdated();
              }}
              key={_status}
            >
              {title} <FontAwesomeIcon icon={faX} className="tw-text-xxs" />
            </button>
          );
        })}
        {Object.entries(filters.ticket_types).map(([_type, title]) => {
          return (
            <button
              className="tag-tertiary hflex tw-gap-2"
              onClick={() => {
                delete filters.ticket_types[_type];
                setFiltersUpdated();
              }}
              key={_type}
            >
              {title} <FontAwesomeIcon icon={faX} className="tw-text-xxs" />
            </button>
          );
        })}
        {Object.entries(filters.bookables).map(
          ([bookable_id, bookable_title]) => (
            <button
              key={bookable_id}
              className="tag-tertiary hflex tw-gap-2"
              onClick={() => {
                delete filters.bookables[bookable_id];
                setFiltersUpdated();
              }}
            >
              {bookable_title} <FontAwesomeIcon icon={faX} className="tw-text-xxs" />
            </button>
          )
        )}
        {Object.values(filters.assigned_users).map((user) => (
          <button
            key={user._id}
            className="tag-tertiary hflex tw-gap-2"
            onClick={() => {
              delete filters.assigned_users[user._id];
              setFiltersUpdated();
            }}
          >
            {user.name} <FontAwesomeIcon icon={faX} className="tw-text-xxs" />
          </button>
        ))}
        {Object.entries(filters.payment_automations).map(
          ([pa_id, pa_title]) => (
            <button
              key={pa_id}
              className="tag-tertiary hflex tw-gap-2"
              onClick={() => {
                delete filters.payment_automations[pa_id];
                setFiltersUpdated();
              }}
            >
              {pa_title} <FontAwesomeIcon icon={faX} className="tw-text-xxs" />
            </button>
          )
        )}
        {filters.sort_by ? (
          <button
            className="tag-tertiary hflex tw-gap-2"
            onClick={() => {
              delete filters.sort_by;
              setFiltersUpdated();
            }}
          >
            Sort: {idToTitle(filters.sort_by)} <FontAwesomeIcon icon={faX} className="tw-text-xxs" />
          </button>
        ) : null}
        {/* {tickets_count?.value ? (
          <div className="tw-inline-block tw-ml-auto tw-text-sm tw-rounded-lg tw-px-2 tw-py-1">
            <span className="tw-font-bold ">
              {tickets_count.relation.startsWith("gt") ? ">" : null}{" "}
              {tickets_count.value}
            </span>{" "}
            tickets
          </div>
        ) : null} */}
      </div>
      {active_view === "calendar" ? (
        <TicketsCalendar filters={filters} tickets={tickets} /> // refetches again anyway
      ) : active_view === "lanes" ? (
        <TicketLanes
          tickets={tickets}
          ticket_type_info={selected_ticket_type}
        />
      ) : (
        <TicketsList tickets={tickets} />
      )}
    </div>
  );
};

/* settings */
const EditPaymentAutomations = ({ org_id }) => {
  const [payment_automations, setPaymentAutomations] = useBroadcastedState(
    "payment_automations",
    {}
  );
  const [payment_automations_list, setPaymentAutomationsList] = useState(null);
  useEffect(() => {
    axios
      .get(`/api/admin/org/${org_id}/tickets?fields[]=payment_automations`)
      .then((resp) => {
        setPaymentAutomations(resp.data.payment_automations);
      });
  }, []);

  useEffect(() => {
    setPaymentAutomationsList(
      Object.entries(payment_automations || {}).map(([pa_id, pa_data]) => ({
        ...pa_data,
        payment_automation_id: pa_id,
      }))
    );
  }, [payment_automations]);

	// payment_automation_id: str
	// amount_str: int = None
	// duration: str = "1m"  # 1d, 1w, 1m, 1y
	// active_from: int = None
	// first_payment_at: int = None
	// prepayment: bool = None
	if(payment_automations_list === null) return <LoadingView height={"100px"} />
	return (
		<div>
			<div className="tw-flex tw-flex-row tw-space-x-2 tw-p-2">
				<div className="tw-text-lg tw-font-semibold">Payment Automations</div>
				<button
					className="btn-primary-v2 tw-text-xs tw-shrink-0"
					onClick={() => setPaymentAutomationsList([{"__is_new": true, "__is_editing": true}, ...payment_automations_list])} // add new
				>
					+ Add Payment Automation
				</button>
			</div>
			<EditableTable
				cols={[
					{
						"title": "Payment automation id",
						"key": "payment_automation_id",
						"editor": (pa, updates) => {
							if(pa.payment_automation_id) return null; // default
						}
					},
					{
						"title": "Title",
						"key": "title",
					},
					{
						"title": "Amount",
						"key": "amount_str",
					},
					{
						"title": "Duration",
						"key": "duration",
						"editor": (pa, updates) => {
							return <select defaultValue={updates.duration || pa.duration} 
								onChange={(e) => {updates.duration = e.target.value}}
								className='dropdown tw-w-full'
							>
								<option value="1week">1 Week</option>
								<option value="2weeks">2 Week</option>
								<option value="1month">1 Month</option>
								<option value="3months">3 Months</option>
								<option value="6months">6 Months</option>
								<option value="1year">1 Year</option>								
							</select>
						}
					},
					{
						"title": "Prepayment",
						"key": "prepayment",
						"selection": [["yes", "Yes"], ["", "No"]],
					}
				]}
				rows={payment_automations_list}
				callbacks={{
					"onUpdate": async (row, updates) => {
						updates.payment_automation_id = updates.payment_automation_id || row.payment_automation_id;
						updates.prepayment = updates.prepayment === "yes";
						const resp = await axios.post(
							`/api/admin/org/${org_id}/tickets?action=update_payment_automation`,
							updates
						);
						payment_automations[resp.data.payment_automation_id] = resp.data.payment_automation_data;
						setPaymentAutomations({...payment_automations}); // update all payment automations
						return [resp.data.payment_automation, resp.data.errors];
					},
					"onDelete": async (row) => {
						const resp = await axios.post(
							`/api/admin/org/${org_id}/tickets?action=delete_payment_automation`,
							{"payment_automation_id": row.payment_automation_id}
						);
						if(resp.data.errors) return [false, resp.data.errors];
						delete payment_automations[row.payment_automation_id];
						setPaymentAutomations({...payment_automations});  // delete from payment automations
						return [true, resp.data.errors];
					}
				}}
			/>
		</div>
	)
}

/*
  ticket_types => {"type": {"statuses": [], "lanes": [], "tag_types": {}}}
*/
const TicketTypesEditor = ({org_id}) => {
	const [ticket_types, setTicketTypes] = useBroadcastedState("ticket_types", {});
	const [ticket_type_entries, setTicketTypeEntries] = useState([]);

	/* reload from db */
	useEffect(
		() => {
			axios.get(`/api/admin/org/${org_id}/tickets?fields[]=ticket_types`).then(
				(resp) => {
					setTicketTypes(resp.data.ticket_types);
				}
			);
		}, []
	);

	useEffect(
		() => {
			setTicketTypeEntries(
				Object.entries(ticket_types).map(
					([ticket_type, type_info]) => {
						return {"ticket_type": ticket_type, ...type_info}
					}
				)
			)
		},
		[ticket_types]
	); // optimization

	return (
		<div>
			<EditableTable 
				cols={[
					{
						"title": "Type", 
						"render": (type_info) => {
							return <span>{idToTitle(type_info.ticket_type)}</span>
						},
						"editor": (type_info, updates) => {
							if(type_info.ticket_type) return; // cannot change ticket type
							return <input
									className='input-primary' 
									type="text" 
									defaultValue={updates.ticket_type || ""} 
									onChange={(e) => {updates.ticket_type = e.target.value}}
								/>
						}
					},
					{
						"title": "Statuses",
						"render": (type_info) => {
							return (
								<table className="w3-table-all">
									<tbody>
										{
											type_info.statuses.map(([status, status_str]) => {
												return (
													<tr key={status}>
														<td>{status}</td>
														<td>{status_str}</td>
													</tr>
												);
											})                      
										}
									</tbody>
								</table>
							);
						},
						"editor": (type_info, updates) => {
							return <SimpleTableInput
								rows={type_info.statuses || DEFAULT_TICKET_STATUSES}
								nCols={2}
								onAction={(action, row, rows) => {updates["statuses"] = rows}}
							/>
						},
						"size": {"s": 100, "l": 30}
					},
					{
						"title": "Lanes",
						"render": (type_info) => {
							return <div className="tw-flex tw-flex-row tw-flex-wrap tw-space-2 tw-gap-2">
								{
									type_info.lanes?.map((status) => {
										return (
											<div key={status} className="tag-tertiary">
												<span>{getTicketStatusString(status, type_info.statuses)}</span>
											</div>
										)
									})
								}
							</div>
						},
						"editor": (type_info, updates) => {
							return <TagsInput 
								selected={
									type_info.lanes?.map(
										(status) => {
											return {
												"title": getTicketStatusString(status, type_info.statuses),
												"value": status
											}
										}
									)
								}
								onSelectedChange={
									(selected) => {
										updates["lanes"] = selected.map((lane) => lane.value)
									}
								}
								suggestions={
									type_info.statuses.map(([value, title]) => {
										return {"title": title, "value": value}
									})
								}
							/>              
						}
					},
					{
						"title": "Tags",
						"render": (type_info) => {
							return <div className="w3-flex-row w3-array-list-8 ">
								{
									Object.keys(type_info.tag_types || {}).length
									?	Object.entries(type_info.tag_types).map(([tag, tag_type]) => {
											return (
												<div key={tag} className="tag-tertiary" style={{"backgroundColor": getRandomColor(tag)}}>
													{idToTitle(tag)}{tag_type ? `[${tag_type}]` : ""}
												</div>
											)
										})
									: "-"
								}
							</div>
						},
						"editor": (type_info, updates) => {
							return <TagsWithTypesEditor 
								tag_types={type_info.tag_types} 
								onUpdated={(tag_types) => {updates.tag_types = tag_types}}
							/>
						}
					}
				]}
				rows={ticket_type_entries}
				callbacks={{
					"onUpdate": async (row, updates) => {
						/* update ticket type */
						/* few new row checks */
						if(row.__is_new){
							updates.statuses = updates.statuses || DEFAULT_TICKET_STATUSES;
						}
						updates.ticket_type = updates.ticket_type || row.ticket_type;
						const resp = await axios.post(
							`/api/admin/org/${org_id}/tickets?action=update_ticket_type`,
							updates
						);
						if(resp.data.errors) return [null, resp.data.errors];
						ticket_types[resp.data.ticket_type] = resp.data.ticket_type_info;
						setTicketTypes({...ticket_types}); // update all ticket types broadcasted
						return [{"ticket_type": resp.data.ticket_type, ...resp.data.ticket_type_info}];
					},
					"onDelete": async (row) => {
						const resp = await axios.post(
							`/api/admin/org/${org_id}/tickets?action=delete_ticket_type`,
							{"ticket_type": row.ticket_type}
						);
						if(resp.data.errors) return [false, resp.data.errors];
						/* delete from broadcasted state too */
						delete ticket_types[row.ticket_type];
						setTicketTypes({...ticket_types});
						return [true, resp.data.errors];
					}
				}}
			/> 
			<div className="w3-flex-row">
				<button className="btn-primary-v2"
					onClick={
						() => {
							let new_ticket_type_entry = {"ticket_type": "", "statuses": DEFAULT_TICKET_STATUSES, "lanes": []};
							new_ticket_type_entry.__is_editing = true;
							new_ticket_type_entry.__is_new = true;
							setTicketTypeEntries([...ticket_type_entries, new_ticket_type_entry])
						}
					}
				>Add New Ticket Type</button>
			</div>
		</div>
	);
}

const QUICK_ASSIGNABLE_USERS = {};
const fetchUsersByIdsOnTickets = async (tickets) => {
  /* enrich ids */
  if (!tickets) return;
  if (Array.isArray(tickets) === false) tickets = [tickets];
  let user_ids_to_fetch = [];
  for (let ticket of tickets) {
    user_ids_to_fetch.push(ticket.user_id);
    ticket.assigned_user_ids &&
      user_ids_to_fetch.push(...ticket.assigned_user_ids);
  }
  let data = await getByIds({ user_ids: user_ids_to_fetch });
  for (let ticket of tickets) {
    ticket.user = data.users[ticket.user_id];
    ticket.assigned_users = ticket.assigned_user_ids?.map(
      (user_id) => data.users[user_id]
    );
    /* save for quick selection instead of search */
    ticket.assigned_users?.forEach((user) => {
      QUICK_ASSIGNABLE_USERS[user._id] = user;
    });
  }
};

const openAssignUser = (evt, org_id, onSelect, selected_users) => {
	Popup.showContextMenu(
		evt.currentTarget,
		<SuggestedField
			selection_list={Object.values(QUICK_ASSIGNABLE_USERS)}
			props={{
				"show_results_on_render": true,
				"endpoint": `/api/admin/org/${org_id}/users?action=search`,
				"params": {"is_staff": true},
				"min_search_text_length": 2,
				"results_key_path": "org_users",
				"title_format": "{name}",
				"image_key_path": null,
				"max_selections": 5,
				"placeholder": "Search Assignee",
				"search_result_className": '!tw-font-normal tw-mt-2 !tw-shadow-none tw-divide-y tw-pl-2',
				"hide_no_results": true
			}}
			className={'tw-p-2'}
			selected={selected_users}
			onSelect={onSelect}
		/>,
		{multi_clickable: true}
	)
}
  
  
/* 
	ticket_statuses -> [[0, "Open"]...] 
	bookables -> {bookable_id: {title, description, price, currency}}  
*/
const TicketFullView = ({ org_id, ticket_id, onUpdate, ticket: _ticket }) => {
  org_id = org_id || _ticket.org_id;
  ticket_id = ticket_id || _ticket._id;

  const [ticket, setTicket] = useState(
    _ticket || { _id: ticket_id, org_id: org_id }
  );
  const [ticket_statuses, setTicketStatuses] = useState(
    DEFAULT_TICKET_STATUSES
  );
  const [ticket_tag_types, setTicketTagTypes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [active_tab, setActiveTab] = useState(0);
  const [org] = useBroadcastedState("org");
  const [new_comment_text, setNewCommentText] = useState("");
  const [request_to_pay, setRequestToPay] = useState("");

  const handleTicketResponse = (ticket) => {
    if (!ticket) return;
    getByIds({
      user_ids: [
        ...(ticket.comments
          ? ticket.comments.map((comment) => comment.src_id)
          : []),
        ...ticket.assigned_user_ids,
        ...[ticket.user_id],
      ],
    }).then((data) => {
      ticket.comments?.forEach((comment) => {
        comment.user = data.users[comment.src_id];
      });
      ticket.assigned_users = ticket.assigned_user_ids
        ?.map((user_id) => data.users[user_id])
        .filter((_) => _);
      ticket.user = ticket.user || data.users[ticket.user_id];
      setRequestToPay(ticket.to_pay_str || "");
      setTicket(ticket);
    });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/admin/org/${org_id}/ticket/${ticket_id}`)
      .then((resp) => {
        handleTicketResponse(resp.data.ticket);
        if (resp.data.ticket_type_info) {
          setTicketStatuses(
            resp.data.ticket_type_info.statuses || DEFAULT_TICKET_STATUSES
          );
          setTicketTagTypes(resp.data.ticket_type_info.tag_types || null);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  /* update ticket when new ticket data is */
  useEffect(() => {
    ticket && _ticket && ticket !== _ticket && onUpdate(ticket);
  }, [_ticket, ticket]);

  const updateTicket = async (payload) => {
    setLoading(true);
    return axios
      .post(
        `/api/admin/org/${_ticket.org_id}/ticket/${ticket._id}?action=update`,
        payload
      )
      .then((resp) => {
        if (resp.data.ticket) {
          let _ticket = { ...ticket, ...resp.data.ticket };
          handleTicketResponse(_ticket);
        }
        /* toast if a payment request was sent */
        if (resp.data.payment_request_sent !== undefined) {
          Popup.toast(
            resp.data.payment_request_sent
              ? "Payment Request Sent Successfully !!"
              : "Payment was not requested"
          );
        }
      })
      .catch((err) => Popup.show("Errors", <GenericException ex={err} />))
      .finally(() => setLoading(false));
  };

  const addComment = (text) => {
    return axios
      .post(`/api/admin/org/${org_id}/ticket/${ticket_id}?action=add_comment`, {
        text: text,
      })
      .then((resp) => {
        if (resp.data.new_comments) {
          if (!ticket.comments) ticket.comments = [];
          ticket.comments.splice(0, 0, ...resp.data.new_comments);
          handleTicketResponse(ticket);
        }
      })
      .finally(() => setNewCommentText(""));
  };

  const removeAssignedUser = (user_id) => {
    const new_assigneed_users = ticket.assigned_user_ids.filter(
      (_user_id) => _user_id !== user_id
    );
    updateTicket({ assigned_user_ids: new_assigneed_users });
  };

  const openTicketEditorPopup = (evt) => {
    var popup = null;
    popup = Popup.sideSheet(
      <div className="tw-overflow-auto tw-p-2">
        <EditTicketData
          ticket={ticket}
          onUpdate={(ticket) => {
            handleTicketResponse(ticket);
            popup.close();
          }}
        />
      </div>
    );
  };

  const updateTagsInPopup = () => {
    let tags = null;
    const popup = Popup.open(
      "Edit Tags",
      <div className="tw-p-2 tw-my-4">
        <AddTagsWithValues
          tag_types={ticket_tag_types}
          selected={ticket.tags}
          onTagsChange={(_tags) => (tags = _tags)}
        />
      </div>,
      {
        ok_button: "Update",
        cancel_button: "Cancel",
        cb: (action) => {
          if (action === 1) updateTicket({ tags: tags });
        },
      }
    );
  };

  const is_request_to_pay_valid = /^\d+(\.\d{1,3})?/.test(request_to_pay);

  return (
    <div className="tw-py-4 tw-px-4 lg:tw-px-8 tw-w-500">
      <div className="hflex">
        <div>
          <p className="tw-text-xl tw-mb-1 tw-capitalize">{ticket.title}</p>
          <div className="tw-flex tw-flex-wrap tw-items-center">
            <div className="tw-text-gray-500 tw-text-sm">#{ticket._id}</div>
            <div className="tw-border tw-rounded tw-px-2 tw-ml-2 tw-text-xs tw-font-semibold tw-text-primary">
              {idToTitle(ticket._type)}
            </div>
          </div>
        </div>
        <select
          className="dropdown tw-font-medium !tw-px-2"
          value={ticket.status}
          onChange={(e) => {
            updateTicket({ status: e.target.value });
          }}
          style={getTicketStatusStyle(ticket.status)}
        >
          {ticket_statuses.map(([code, status]) => (
            <option key={code + status} value={code}>
              {status}
            </option>
          ))}
        </select>
      </div>
      <div className="tw-text-xs tw-mt-4 tw-mb-4">
        <span>
          <span className="tw-text-gray-500 tw-font-semibold">Raised on: </span>
          <DateView millis={ticket.created_at} />
        </span>

        {ticket.updated_at ? (
          <>
            <span>&nbsp;|&nbsp;</span>
            <span>
              <span className="tw-text-gray-500 tw-font-semibold">
                Last Updated:{" "}
              </span>
              <DateView millis={ticket.updated_at} />
            </span>
          </>
        ) : null}
      </div>
      <div className="tw-flex tw-flex-wrap tw-gap-4 tw-items-center">
        <button
          className="btn-primary-v2 tw-text-xs tw-flex tw-items-center !tw-rounded-lg"
          onClick={() => openTicketChat(org, ticket)}
        >
          <FontAwesomeIcon icon={faMessage} />
          &nbsp;&nbsp;Open Chat
        </button>
        <div className="tw-flex tw-items-center tw-flex-wrap">
          <span className="tw-text-xs tw-font-semibold tw-mr-2">
            Assigned To:
          </span>
          {ticket.assigned_users?.map((assigned_user) => (
            <div
              key={assigned_user._id}
              className="tw-text-sm tw-border tw-rounded tw-pl-2 tw-rounded-full tw-mr-2 tw-flex tw-items-center"
            >
              <FontAwesomeIcon
                icon={faUserTie}
                className="tw-text-xs tw-mr-1"
              />
              {assigned_user?.name || "-"}&nbsp;&nbsp;
              <FontAwesomeIcon
                icon={faTimes}
                className="tw-text-xs tw-px-2 tw-py-1 tw-cursor-pointer"
                onClick={() => removeAssignedUser(assigned_user._id)}
              />
            </div>
          ))}
          <div
            className="tw-text-primary-v2 tw-text-xs tw-font-medium tw-cursor-pointer"
            onClick={(evt) =>
              openAssignUser(
                evt,
                _ticket.org_id,
                (new_assigned_users) =>
                  updateTicket({
                    assigned_user_ids: new_assigned_users.map((u) => u._id),
                  }),
                ticket.assigned_users
              )
            }
          >
            + Assign User
          </div>
        </div>
      </div>
      <div className="tw-mt-6 tw-border-y">
        <button
          className={`tw-py-2 tw-text-sm tw-text-gray-500 tw-mr-6 ${
            active_tab === 0 ? "active-tab" : ""
          }`}
          onClick={() => setActiveTab(0)}
        >
          View Details
        </button>
        {
          /* show payments tab when only when payments */
          ticket.payments?.length ? (
            <button
              className={`tw-py-2 tw-text-sm tw-text-gray-500 tw-mr-6 ${
                active_tab === 1 ? "active-tab" : ""
              }`}
              onClick={() => setActiveTab(1)}
            >
              Payments
            </button>
          ) : null
        }
      </div>
      {active_tab === 0 ? (
        <div>
          <div className="tw-font-medium tw-text-xs tw-mt-4 tw-space-y-4">
            <div className="tw-mb-2 tw-flex tw-gap-2">
              <div
                style={{ minWidth: 120 }}
                className="tw-text-gray-700 tw-pr-8"
              >
                User:
              </div>
              <span className="tw-text-bold"><UserBadge user={ticket.user} /></span>
            </div>
            {/* quick note to customer */}
            <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
              <div style={{ minWidth: 120 }} className="tw-text-gray-700">
                Quick Note:{" "}
              </div>
              <EditableTextArea
                placeholder={"add note to customer"}
                text={ticket.quick_customer_note}
                onUpdate={(updated_note) =>
                  updateTicket({ quick_customer_note: updated_note })
                }
              />
            </div>
            {
              /* tags */
              <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                <div style={{ minWidth: 120 }} className="tw-text-gray-700">
                  Tags:{" "}
                </div>
                <div className="tw-gap-2 tw-text-xs tw-flex tw-items-center tw-flex-wrap tw-flex-row">
                  {ticket_tag_types ? (
                    <span
                      onClick={() => updateTagsInPopup()}
                      className="tw-text-primary-v2"
                    >
                      +Add Tags
                    </span>
                  ) : (
                    <InfoText text="No Tags Defined. Goto Settings > Ticket Types > Set Tags" />
                  )}
                  {Object.entries(ticket.tags || {}).map(([tag, value]) => {
                    return (
                      <div
                        className="tw-border tw-rounded-xl tw-bg-gray-300 tw-px-2 tw-py-1"
                        key={tag}
                      >
                        {tag}
                      </div>
                    );
                  })}
                </div>
              </div>
            }
            {
              /* description */
              ticket.description ? (
                <div className="tw-mb-2 tw-flex tw-gap-2">
                  <div
                    style={{ minWidth: 120 }}
                    className="tw-text-gray-700 tw-pr-8"
                  >
                    Description:
                  </div>
                  <div>
                    {ticket.description.split("\n").map((line, i) => (
                      <div key={i} className="tw-text-gray-500 ">
                        {line}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null
            }
            {
              /* booking */
              ticket.bookable_id ? (
                <>
                  <div className="tw-pt-4 tw-font-bold">
                    Booking {ticket.bookable_id}
                  </div>
                  <div className="tw-mb-2 tw-flex tw-gap-2">
                    <div
                      style={{ minWidth: 120 }}
                      className="tw-text-gray-700 tw-pr-8"
                    >
                      From:
                    </div>
                    <span className="tw-text-gray-500">
                      <DateView millis={ticket.time_a} />
                    </span>
                  </div>
                  <div className="tw-mb-2 tw-flex tw-gap-2">
                    <div
                      style={{ minWidth: 120 }}
                      className="tw-text-gray-700 tw-pr-8"
                    >
                      To:
                    </div>
                    <span className="tw-text-gray-500">
                      <DateView millis={ticket.time_b} />
                    </span>
                  </div>
                </>
              ) : null
            }
            {
              /* payment */
              ticket.amount_str || ticket.paid_amount_str ? (
                <>
                  <div className="tw-mt-4 tw-font-bold">Payment Details</div>
                  {ticket.amount_str ? (
                    <div className="tw-mb-2 tw-flex tw-gap-2">
                      <div
                        style={{ minWidth: 120 }}
                        className="tw-text-gray-700 tw-pr-8"
                      >
                        Total:
                      </div>
                      <span className="tw-text-gray-500">
                        {ticket.amount_str}
                      </span>
                    </div>
                  ) : null}
                  {ticket.to_pay_str ? (
                    <div className="tw-mb-2 tw-flex tw-gap-2">
                      <div
                        style={{ minWidth: 120 }}
                        className="tw-text-gray-700 tw-pr-8"
                      >
                        Due:
                      </div>
                      <span className="tw-text-red-500">
                        {ticket.to_pay_str}
                      </span>
                    </div>
                  ) : null}
                  {
                    // payment automation
                    ticket.payment_automation_data
                      ? ((pa_data) => (
                          <fieldset className="tw-space-y-2 tw-mb-2 tw-p-2">
                            <legend className="tw-font-bold">
                              {ticket.payment_automation_data.title}
                            </legend>
                            <div>
                              {getDisplayPrice(
                                pa_data["currency"],
                                pa_data["amount"]
                              )}{" "}
                              / {pa_data["duration"]}
                            </div>
                            <div>
                              Active From:{" "}
                              {new Date(
                                pa_data["active_from"]
                              ).toLocaleString()}
                            </div>
                            <div>
                              Next Payment:{" "}
                              {new Date(
                                ticket.next_payment_at
                              ).toLocaleString()}
                            </div>
                          </fieldset>
                        ))(ticket.payment_automation_data)
                      : null
                  }
                </>
              ) : null
            }
            {
              /* ticket data */
              ticket.data && Object.keys(ticket.data).length > 0 ? (
                <div className="tw-mt-4">{objToEl(ticket.data)}</div>
              ) : null
            }
            {
              /* payment */
              ticket.files?.length ? (
                <>
                  <div className="tw-font-bold">Attachments</div>
                  <div className="tw-mb-2 tw-flex tw-gap-2 tw-flex-row tw-flex-wrap">
                    {ticket.files.map(
                      (file) =>
                        file && (
                          <div style={{ height: "100px", maxWidth: "100%" }}>
                            <ThumbnailFileView
                              key={file.url || file}
                              file={file}
                            />
                          </div>
                        )
                    )}
                  </div>
                </>
              ) : null
            }
            {ticket.items?.length ? (
              <>
                <div className="tw-font-bold">Items</div>
                {listOfListsToTable(ticket.items)}
              </>
            ) : null}
            <div className="tw-flex tw-flex-row tw-gap-2 tw-pt-4 tw-items-center">
              <button
                className="btn-secondary"
                onClick={openTicketEditorPopup}
              >
                Edit & Send To Customer
              </button>
              {ticket.pending_user_confirmation && (
                <div
                  className="tw-text-red-800"
                  onClick={() =>
                    Popup.open(
                      "View Pending User Confirmation",
                      objToEl(ticket.pending_user_confirmation)
                    )
                  }
                >
                  Waiting For Customer Approval{" "}
                </div>
              )}
            </div>
          </div>
          <div className="tw-my-6">
            <p className="tw-py-2 tw-font-bold">Comments</p>
            <textarea
              className="input-primary tw-w-full tw-mt-2"
              placeholder="Enter Comment"
              rows={3}
              value={new_comment_text}
              onChange={(e) => setNewCommentText(e.target.value)}
            />
            <div className="tw-text-right tw-pb-4">
              <button
                className={`btn-primary-v2 ${
                  !new_comment_text?.trim() ? "tw-opacity-50" : ""
                }`}
                disabled={!new_comment_text?.trim()}
                onClick={() => {
                  addComment(new_comment_text);
                }}
              >
                Save Comment
              </button>
            </div>
            {ticket.comments?.map((comment) => (
              <div
                className="tw-py-2 tw-flex tw-flex-row"
                key={comment.created_at}
              >
                <RoundUserImage user={comment.user} />
                <div className="tw-ml-2 tw-flex-grow">
                  <div className="tw-font-medium tw-text-sm tw-mb-1">
                    {comment.user?.name}
                  </div>
                  <div>{comment.payload}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div>
          <div className="tw-mt-6 tw-pb-6 tw-border-b">
            <p className="tw-font-medium">Payment Request</p>
            <div className="hflex tw-gap-4 tw-mt-3">
              <input
                type="text"
                className="input-primary tw-grow"
                placeholder="Enter Amount"
                value={request_to_pay}
                onChange={(e) => setRequestToPay(e.target.value)}
                disabled={ticket.payment_automation_id}
              />
              <button
                className={`btn-primary-basic tw-text-xs ${
                  !is_request_to_pay_valid ? "tw-opacity-50" : ""
                }`}
                disabled={!is_request_to_pay_valid}
                onClick={() => {
                  updateTicket({ request_to_pay_str: request_to_pay });
                }}
              >
                Request For Payment
              </button>
            </div>
          </div>
          {ticket.payments?.length ? (
            <div className="tw-mb-6">
              <p className="tw-font-medium tw-pt-2 tw-pb-4">Payments Summary</p>
              {ticket.payments.map((pt, i) => (
                <div
                  className="tw-flex tw-justify-center"
                  key={i}
                  onClick={() =>
                    Popup.open("Payment Details", objToEl(pt.payment_data))
                  }
                >
                  <div className={`tw-w-2/3`}>{pt.payment_amount}</div>
                  <div className="tw-w-1/3 tw-text-xs tw-text-gray-400">
                    <DateView millis={pt.updated_at} />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <EmptyView height={"200px"} title="No payment made." />
          )}
        </div>
      )}
    </div>
  );
};

const EditBookables = ({ org_id }) => {
  const [bookables, setBookables] = useBroadcastedState("bookables", {});
  const [bookables_list, setBookablesList] = useState(null);

  useEffect(() => {
    axios
      .get(`/api/admin/org/${org_id}/tickets?fields[]=bookables`)
      .then((resp) => {
        setBookables(resp.data.bookables);
      });
  }, []);

  useEffect(() => {
    if (!bookables) return;
    setBookablesList(
      Object.entries(bookables).map(([bookable_id, bookable]) => {
        bookable.prepayment = bookable.prepayment ? "yes" : "no";
        bookable.can_cancel_before &&
          (bookable.can_cancel_before = duration2string(
            parseInt(bookable.can_cancel_before / 1000)
          ));
        bookable.freeze_after &&
          (bookable.freeze_after = duration2string(
            parseInt(bookable.freeze_after / 1000)
          ));
        return { bookable_id: bookable_id, ...bookable };
      })
    );
  }, [bookables]);

  return (
    <div className="tw-p-2">
      <div className="tw-flex tw-flex-row tw-space-x-2 tw-p-2 tw-items-center">
        <div className="tw-text-lg tw-font-semibold">Bookables</div>
        <button
          className="btn-primary-v2 tw-text-xs tw-shrink-0"
          onClick={() =>
            setBookablesList([
              { __is_new: true, __is_editing: true },
              ...bookables_list,
            ])
          } // add new
        >
          + Add Bookable
        </button>
      </div>

      <EditableTable
        cols={[
          {
            title: "Bookable ID",
            key: "bookable_id",
            editor: (bookable, updates) => {
              if (bookable.bookable_id) return null; // cannot change bookable id
            },
          },
          {
            title: "Title",
            key: "title",
          },
          {
            title: "Description",
            key: "description",
          },
          {
            title: "Timeslots",
            editor_props: { placeholder: "Ex: Monday-Saturday 9am-5pm" },
            key: "timeslots",
          },
          {
            title: "Tracks",
            key: "tracks",
            editor_props: {
              type: "number",
              placeholder: "number of counters/tracks",
            },
          },
          {
            title: "Pricing",
            editor_props: {
              placeholder: "100 USD|1h  or 5:30PM-6:30PM|100 USD|1h",
            },
            key: "pricing",
          },
          {
            title: "Interval",
            editor_props: { placeholder: "Slot intervals, Ex: 1h, 15m..." },
            key: "interval",
          },
          {
            title: "Prepayment",
            key: "prepayment",
            selection: [
              ["yes", "Yes"],
              ["", "No"],
            ],
          },
          {
            title: "Can Update Before",
            editor_props: { placeholder: "Ex: 1d, 1h" },
            key: "can_cancel_before",
          },
          {
            title: "Freeze After",
            editor_props: { placeholder: "Ex: 1d, 1h" },
            key: "freeze_after",
          },
          {
            title: "Timezone",
            key: "timezone_delta",
            editor_props: { placeholder: "Ex: 5h30m, -6h" },
          },
          {
            title: "Images",
            render: (bookable) => {
              return <Gallery files={bookable.images} />;
            },
            editor: (bookable, updates) => {
              return (
                <FileUploader
                  files={bookable.images}
                  allowed_mime_types={["image/jpeg"]}
                  onFilesUpdated={(images) => {
                    updates.images = images;
                  }}
                />
              );
            },
            size: { s: 100, l: 30 },
          },
        ]}
        rows={bookables_list}
        callbacks={{
          onUpdate: async (row, updates) => {
            updates.bookable_id = updates.bookable_id || row.bookable_id;
            if (updates.prepayment !== undefined)
              updates.prepayment = updates.prepayment === "yes";
            if (updates.can_cancel_before)
              updates.can_cancel_before =
                string2duration(updates.can_cancel_before) * 1000;
            if (updates.freeze_after)
              updates.freeze_after =
                string2duration(updates.freeze_after) * 1000;
            const resp = await axios.post(
              `/api/admin/org/${org_id}/tickets?action=update_bookable`,
              updates
            );
            bookables[resp.data.bookable_id] = resp.data.bookable;
            setBookables({ ...bookables }); // update all bookables
            return [resp.data.bookable, resp.data.errors];
          },
          onDelete: async (row) => {
            const resp = await axios.post(
              `/api/admin/org/${org_id}/tickets?action=delete_bookable`,
              { bookable_id: row.bookable_id }
            );
            if (resp.data.errors) return [false, resp.data.errors];
            return [true, resp.data.errors];
          },
        }}
      />
    </div>
  );
};

/* Payment Automations */
const AttachNewPaymentAutomation = ({
  payment_automations,
  updates,
  onChange,
}) => {
  const [editing_payment_automation_data, setEditingPaymentAutomationData] =
    useState(null);
  return (
    <div>
      <select
        defaultValue={updates.payment_automation_id || ""}
        onChange={(e) => {
          let pa_id = (updates.payment_automation_id = e.target.value);
          let pa_data = payment_automations[pa_id];
          onChange();
          if (!pa_data) {
            setEditingPaymentAutomationData(null);
            return;
          }
          /* set some defaults */
          pa_data["active_from"] = getStartOfDay().toISOString().split("T")[0];
          setEditingPaymentAutomationData({
            ...pa_data,
            __is_new: true,
            __is_editing: true,
            __updates: (updates.payment_automation_data = { ...pa_data }),
          });
        }}
        className="dropdown"
      >
        <option value="">No Payment Automation</option>
        {Object.keys(payment_automations).map((pa_id) => {
          return (
            <option key={pa_id} value={pa_id}>
              {payment_automations[pa_id].title}
            </option>
          );
        })}
      </select>
      {editing_payment_automation_data && (
        <div>
          <EditableTable
            cols={[
              {
                title: "Title",
                key: "title",
              },
              {
                title: "Amount",
                key: "amount_str",
              },
              {
                title: "Duration",
                key: "duration",
                editor: (pa_data, _updates) => {
                  return (
                    <select
                      defaultValue={_updates.duration || pa_data.duration}
                      onChange={(e) => {
                        _updates.duration = e.target.value;
                      }}
                      className="w3-select"
                    >
                      <option value="1week">1 Week</option>
                      <option value="2weeks">2 Week</option>
                      <option value="1month">1 Month</option>
                      <option value="3months">3 Months</option>
                      <option value="6months">6 Months</option>
                      <option value="1year">1 Year</option>
                    </select>
                  );
                },
              },
              {
                title: "Active From",
                editor: (pa_data, _updates) => {
                  return (
                    <input
                      type="date"
                      defaultValue={_updates.active_from}
                      onChange={(e) => {
                        _updates.active_from = e.target.value;
                      }}
                      className="w3-input"
                    />
                  );
                },
              },
              {
                title: "Ends At",
                editor: (pa_data, _updates) => {
                  return (
                    <input
                      type="date"
                      onChange={(e) => {
                        _updates.ends_at = e.target.value;
                      }}
                      className="w3-input"
                    />
                  );
                },
              },
              {
                title: "Prepayment",
                key: "prepayment",
                selection: [
                  ["yes", "Yes"],
                  ["", "No"],
                ],
              },
            ]}
            rows={[editing_payment_automation_data]}
          />
        </div>
      )}
    </div>
  );
};

function EditTicketData({ ticket, onUpdate }) {
  const [bookables, setBookables] = useBroadcastedState("bookables");
  const [ticket_types, setTicketTypes] = useBroadcastedState("ticket_types");
  const [payment_automations, setPaymentAutomations] = useBroadcastedState(
    "payment_automations"
  );
  const [is_loading, setIsLoading] = useState(false);
  const current_user = useCurrentUser();
  const [errors, setErrors] = useState(null);
  const container_el = useRef(null);

  useEffect(() => {
    let to_fetch_fields = [
      !bookables && "bookables",
      !ticket_types && "ticket_types",
      !payment_automations && "payment_automations",
    ].filter((_) => _);
    if (!to_fetch_fields.length) return;
    setIsLoading(true);
    axios
      .get(`/api/admin/org/${ticket.org_id}/tickets`, {
        params: { fields: to_fetch_fields },
      })
      .then((resp) => {
        setBookables(resp.data.bookables || {});
        setTicketTypes(resp.data.ticket_types || {});
        setPaymentAutomations(resp.data.payment_automations || {});
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {ticket.__is_editing = true; ticket.__is_new = true}, []); // in edit mode
  /* handlers */
  const doUpdateTicket = (updates) => {
    if (ticket.__is_updating) return;
    ticket.__is_updating = true;
    /* cleanup updates */
    const params = { ...updates };
    if (params.org_user) params.user_id = popKey(params, "org_user").user_id;
    if (params.items)
      params.items = params.items.map((item) => item.filter((_) => _)); // remove empty items

    /* cleanup paymen automation data */
    let payment_automation_data = popKey(params, "payment_automation_data");
    if (params.payment_automation_id) {
      payment_automation_data.active_from = new Date(
        payment_automation_data.active_from + "T00:00:00"
      ).getTime();
      payment_automation_data.ends_at =
        payment_automation_data.ends_at &&
        new Date(payment_automation_data.ends_at + "T00:00:00").getTime();
      params.payment_automation = {
        ...payment_automation_data,
        payment_automation_id: popKey(params, "payment_automation_id"),
      };
    }
    // set in local timezone
    params.time_a = params.time_a && new Date(params.time_a).getTime();
    params.time_b = params.time_b && new Date(params.time_b).getTime();
    params.tz_offset_millis = LOCAL_TZ_OFFSET_MILLIS;
    axios
      .post(
        ticket._id
          ? `/api/admin/org/${ticket.org_id}/ticket/${ticket._id}?action=update`
          : `/api/admin/org/${ticket.org_id}/ticket/?action=create`,
        params
      )
      .then((resp) => {
        setErrors(resp.data.errors);
        resp.data.ticket && onUpdate(resp.data.ticket);
      })
      .catch((ex) => {setErrors(ex);})
      .finally(() => (ticket.__is_updating = false));
  };

  /* scroll to top when errors change */
  useEffect(
    () => {
      errors && getScrollParent(container_el.current).scrollIntoView?.();
    }, [errors]
  );

  if (is_loading) return <LoadingView height={"300px"} />;
  return (
    <div ref={container_el}>
      {errors && <GenericException ex={errors} />}
      <EditableTable
        cols={[
          { title: "Title", key: "title", size: { m: 100, s: 100 } },
          /* TYPE */
          ticket_types && Object.keys(ticket_types).length
            ? {
                title: "Ticket Type",
                key: "_type",
                selection: Object.keys(ticket_types).map((type) => [
                  type,
                  idToTitle(type),
                ]),
              }
            : null,
          /* USER ID */
          !ticket.user_id
            ? {
                title: "For User",
                editor: (ticket, updates) => {
                  /* ticket does not have a user_id, creating a new user, hence settings `updates.user_id` */
                  updates.org_user = ticket.user || ticket.org_user;
                  return (
                    <SuggestedField
                      selected={updates.org_user ? [updates.org_user] : []}
                      props={{
                        show_results_on_render: true,
                        endpoint: `/api/admin/org/${ticket.org_id}/users?action=search`,
                        min_search_text_length: 2,
                        results_key_path: "org_users",
                        title_format: "{name}",
                        image_format: "{image}",
                        description_format: "{phone_number}",
                        max_selections: 1,
                        placeholder: "For User",
                      }}
                      onSelect={(org_users) => {
                        updates.org_user = org_users?.[0];
                      }}
                    />
                  );
                },
              }
            : null,
          /* Assigned Users for new ticket only */
          !ticket.user_id
            ? {
                title: "Assign To users",
                editor: (ticket, updates) => {
                  /* ticket does not have a user_id, creating a new user, hence settings `updates.user_id` */
                  updates.assigned_user_ids = [current_user._id];
                  return (
                    <SuggestedField
                      props={{
                        endpoint: `/api/admin/org/${ticket.org_id}/users?action=search`,
                        params: { is_staff: true },
                        min_search_text_length: 2,
                        results_key_path: "org_users",
                        title_format: "{name}",
                        image_key_path: null,
                        max_selections: 5,
                        placeholder: "Search Assignee",
                        search_result_className:
                          "!tw-font-normal tw-mt-2 !tw-shadow-none tw-divide-y tw-pl-2",
                        hide_no_results: true,
                      }}
                      className={"tw-border tw-p-2"}
                      selected={[current_user]}
                      onSelect={(selected_users) =>
                        (updates.assigned_user_ids = selected_users.map(
                          (u) => u._id
                        ))
                      }
                    />
                  );
                },
              }
            : null,
          {
            title: "Description",
            key: "description",
            editor: (ticket, updates) => {
              return (
                <textarea
                  className="input-primary"
                  defaultValue={updates.description || ticket.description}
                  onChange={(e) => (updates.description = e.target.value)}
                />
              );
            },
            size: { m: 100, s: 100 },
          },
          /* ITEMS */
          {
            title: "Items",
            render: (ticket) => {
              return listOfListsToTable(ticket.items);
            },
            editor: (ticket, updates) => {
              return (
                <SimpleTableInput
                  nCols={3}
                  rows={ticket.items || []}
                  placeholders={["Item", "Quantity", "Price"]}
                  onAction={(action, row, rows) => {
                    updates.items = rows;
                  }}
                />
              );
            },
            size: { m: "100%", s: "100%" },
          },
          /*BOOKING */
          ...(Object.keys(bookables || {}).length && !ticket.payment_automation_id
            ? [
                {
                  key: "bookable_id",
                  title: "Bookable",
                  editor: (ticket, updates) => {
                    if (updates.payment_automation_id) return null;
                    return (
                      <select
                        defaultValue={ticket.bookable_id || ""}
                        className="dropdown tw-w-full"
                        onChange={(e) => {
                          updates.bookable_id = e.target.value;
                          // if there is a bookable, remove payment automation update
                          if (
                            updates.bookable_id &&
                            updates.payment_automation_id
                          ) {
                            updates.payment_automation_id = null;
                          }
                          ticket.rerender();
                        }}
                      >
                        <option value="">No Booking</option>
                        {Object.keys(bookables).map((bookable_id) => {
                          return (
                            <option key={bookable_id} value={bookable_id}>
                              {bookables[bookable_id].title}
                            </option>
                          );
                        })}
                      </select>
                    );
                  },
                  section: "booking",
                  size: { m: 100, s: 100 },
                },
                {
                  title: "From",
                  key: "time_a",
                  editor: (ticket, updates) => {
                    if (updates.bookable_id === "") return null;
                    if (!(ticket.bookable_id || updates.bookable_id)) return null;
                    return (
                      <input
                        type="datetime-local"
                        className="input-primary"
                        defaultValue={
                          ticket.time_a
                            ? new Date(ticket.time_a - LOCAL_TZ_OFFSET_MILLIS)
                                .toISOString()
                                .slice(0, 16)
                            : ""
                        }
                        onChange={(e) => (updates.time_a = e.target.value)}
                      />
                    );
                  },
                  section: "booking",
                },
                {
                  title: "Until",
                  key: "time_b",
                  editor: (ticket, updates) => {
                    if (updates.bookable_id === "") return null;
                    if (!(ticket.bookable_id || updates.bookable_id)) return null;
                    let _time_a = updates.time_a || ticket.time_a;
                    let duration =
                      (updates.time_b || ticket.time_b || 0) - _time_a;
                    return (
                      <input
                        type="text"
                        className="input-primary"
                        placeholder="Ex: 1h, 1h30m, 1d"
                        defaultValue={
                          duration > 0 ? duration2string(duration / 1000) : ""
                        }
                        onChange={(e) =>
                          (updates.time_b =
                            _time_a + string2duration(e.target.value) * 1000)
                        }
                      />
                    );
                  },
                  section: "booking",
                },
              ]
            : []),
          /* PAYMENT */
          Object.keys(payment_automations || []).length &&
          !ticket.payment_automation_id &&
          !ticket.amount_str // no automation and no amount, enable adding a new
            ? [
                {
                  title: "Payment Automation",
                  editor: (ticket, updates) => {
                    if (updates.bookable_id) return null;
                    return (
                      <AttachNewPaymentAutomation
                        payment_automations={payment_automations}
                        updates={updates}
                        onChange={() => {
                          /* if there is a payment automation, remove bookable update */
                          if (
                            updates.bookable_id &&
                            updates.payment_automation_id
                          ) {
                            updates.bookable_id = null;
                          }
                          ticket.rerender();
                        }}
                      />
                    );
                  },
                  section: "payment",
                  size: { m: 100, s: 100 },
                },
              ]
            : [],
          {
            title: "Total amount",
            key: "amount_str",
            /* cannot edit for this for payment automations */
            editor: (ticket, updates) => {
              if (ticket.payment_automation_id || updates.payment_automation_id)
                return null;
            },
            section: "payment",
          },
          {
            title: "Send Payment Request",
            key: "to_pay_str",
            editor: (ticket, updates) => {
              /* cannot edit for this for payment automations */
              if (ticket.payment_automation_id || updates.payment_automation_id)
                return null;
            },
            section: "payment",
          },
          {
            title: "Images",
            render: (ticket) => {
              return <Gallery files={ticket.files} />;
            },
            editor: (ticket, updates) => {
              return (
                <FileUploader
                  files={ticket.files}
                  allowed_mime_types={[
                    "image/jpeg", "image/png", "application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "application/msword", "text/csv"
                  ]}
                  onFilesUpdated={(files) => {
                    updates.files = files;
                  }}
                />
              );
            },
            size: { s: 100, l: 30 },
          },
          {
            /* just a check botton "Requires Customer Confirmation" */
            editor: (ticket, updates) => {
              return (
                <div className="tw-space-y-4 tw-flex tw-items-center tw-flex-row">
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <input
                      type="checkbox"
                      defaultChecked={!!ticket.pending_user_confirmation}
                      onChange={(e) =>
                        (updates.requires_user_confirmation = e.target.checked)
                      }
                      className="input-primary"
                      id="pending_user_confirmation_checkbox"
                    />
                    <label htmlFor="pending_user_confirmation_checkbox" className="checkbox-label">
                      Requires Customer Confirmation
                      <div className="tw-text-secondary-text tw-text-xs">(For amount/items)</div>
                    </label>
                  </div>
                  <div className="tw-ml-auto">
                    <button
                      className="btn-primary-v2 tw-p-2"
                      onClick={() => doUpdateTicket(updates)}
                    >
                      Update
                    </button>
                  </div>
                </div>
              );
            },
            size: { l: 100, m: 100, s: 100 },
          }
        ]}
        options={{
          sections: {
            payment: { title: "Payment Details" },
            booking: { title: "Booking Details" },
          },
        }}
        rows={[ticket]}
      />
    </div>
  );
}

export {
  getTicketStatusString,
  getTicketStatusStyle,
  TicketLanes,
  TicketsCalendar,
  TicketsList,
  TicketsPage,
  TicketFullView,
  EditTicketData,
  fetchUsersByIdsOnTickets,
};
