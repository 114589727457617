import React, { useEffect, useState } from "react";

function SingleSelectionTab({ values, setValue, defaultValue, valueClassName }) {
    const [selected_value, setSelectedValue] = useState(defaultValue)

    useEffect(() => {
        if (selected_value) {
            setValue(values[selected_value])
        }
    }, [selected_value])

    return (
        <div className='tw-rounded tw-flex tw-flex-wrap'>
            {
                Object.keys(values).map(value => {
                    return <div key={value}>
                        <div className={`tw-border tw-text-xs tw-p-1 ${selected_value === value ? "tw-bg-primary tw-text-white" : "tw-text-black"} ${valueClassName}`
                        } onClick={() => setSelectedValue(value)}>{value}</div>
                    </div>
                })
            }
        </div>
    )
}

export default SingleSelectionTab