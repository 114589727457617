// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-product-title {
	line-height: 1.5em;
}

.date-picker::-webkit-inner-spin-button,
.date-picker::-webkit-calendar-picker-indicator {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	cursor: pointer;
}

.app-plus-button {
	background-color: #e5f0fd;
}

.app-search-bar-sticky {
	margin-top: 0px;
}

.app-qty-change > svg {
	height: 25px;
}

.card {
	background-color: #fff;
	border-radius: 0.5rem;
	padding: 1.5rem;
	box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.font-inter {
	font-family: "Inter", sans-serif;
	font-weight: 700;
}

.sidebar-item:hover svg path,
.sidebar-icon-active svg path {
	fill: #ffb93e;
}

.products-sidesheet-container > div > div > .sticky-row:last-child {
	position: sticky;
	bottom: 0;
	left: 0;
	right: 0;
}

.dpoints-sidesheet-container > div > div >.sticky-row:last-child {
	position: sticky;
	bottom: 0;
	left: 0;
	right: 0;
}`, "",{"version":3,"sources":["webpack://./src/css/app.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;AACnB;;AAEA;;CAEC,kBAAkB;CAClB,OAAO;CACP,MAAM;CACN,WAAW;CACX,YAAY;CACZ,SAAS;CACT,UAAU;CACV,eAAe;AAChB;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,sBAAuC;CACvC,qBAAuC;CACvC,eAA2B;CAC3B,yCAAiC;AAClC;;AAEA;CACC,gCAAgC;CAChC,gBAAgB;AACjB;;AAEA;;CAEC,aAAa;AACd;;AAEA;CACC,gBAAgB;CAChB,SAAS;CACT,OAAO;CACP,QAAQ;AACT;;AAEA;CACC,gBAAgB;CAChB,SAAS;CACT,OAAO;CACP,QAAQ;AACT","sourcesContent":[".app-product-title {\n\tline-height: 1.5em;\n}\n\n.date-picker::-webkit-inner-spin-button,\n.date-picker::-webkit-calendar-picker-indicator {\n\tposition: absolute;\n\tleft: 0;\n\ttop: 0;\n\twidth: 100%;\n\theight: 100%;\n\tmargin: 0;\n\tpadding: 0;\n\tcursor: pointer;\n}\n\n.app-plus-button {\n\tbackground-color: #e5f0fd;\n}\n\n.app-search-bar-sticky {\n\tmargin-top: 0px;\n}\n\n.app-qty-change > svg {\n\theight: 25px;\n}\n\n.card {\n\tbackground-color: theme(\"colors.white\");\n\tborder-radius: theme(\"borderRadius.lg\");\n\tpadding: theme(\"spacing.6\");\n\tbox-shadow: theme(\"boxShadow.sm\");\n}\n\n.font-inter {\n\tfont-family: \"Inter\", sans-serif;\n\tfont-weight: 700;\n}\n\n.sidebar-item:hover svg path,\n.sidebar-icon-active svg path {\n\tfill: #ffb93e;\n}\n\n.products-sidesheet-container > div > div > .sticky-row:last-child {\n\tposition: sticky;\n\tbottom: 0;\n\tleft: 0;\n\tright: 0;\n}\n\n.dpoints-sidesheet-container > div > div >.sticky-row:last-child {\n\tposition: sticky;\n\tbottom: 0;\n\tleft: 0;\n\tright: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
