import React from 'react';
import { EmptyView } from 'base/ui/status';
import { getStartOfDay } from 'base/utils/common';
import { timeRangeToString } from 'base/ui/date';

function SelectDeliveryTime({
    delivery_route,
    delivery_time_ranges,
    selected_delivery_time_range,
    onDeliveryTimeRangeSelected
}){
    delivery_time_ranges = delivery_time_ranges || delivery_route.delivery_time_ranges;  
    selected_delivery_time_range = selected_delivery_time_range || delivery_route.selected_delivery_time_range;
    if(!delivery_time_ranges?.length){
        return  <EmptyView
            title="No Delivery Slots announced. We will notify you when a delivery slot is announced"
            height="300px"
        />
    }
    const next_available_delivery_from = delivery_route.next_available_delivery_from || getStartOfDay().getTime();
    return (
        <div className='w3-row w3-relative'  style={{"padding": "8px 8px 128px 8px"}}>
            <div className='w3-margin-bottom-32 w3-list-16'>
                {
                    delivery_time_ranges.map(
                        (delivery_time_range) => {
                            let [time_range_prefix, time_range_str] = timeRangeToString(delivery_time_range);
                            return (
                                <label className={`w3-flex w3-padding-8 ${delivery_time_range[0] < next_available_delivery_from ? 'w3-text-red': ''}`}
                                    key={delivery_time_range[0]}
                                >
                                    <div className='w3-col s10 w3-padding-sides-8 '>
                                        {time_range_prefix} {time_range_str}
                                    </div>
                                    <div className='w3-margin-left-auto  w3-flex w3-flex-vcenter'>
                                        <input 
                                            className='w3-radio w3-input'
                                            type="radio"
                                            defaultChecked={selected_delivery_time_range?.[0] === delivery_time_range[0]}
                                            name={delivery_route._id + "_slot"}
                                            onChange={(evt) => evt.target.checked && onDeliveryTimeRangeSelected(delivery_time_range)} 
                                        />
                                    </div>
                                </label>
                            )
                        }
                    )
                }
            </div>
        </div>
    )
}

export default SelectDeliveryTime