import React, { useEffect, useRef, useState } from "react";
import { PHONE_NUMBER_CODES } from "../../../constants";

const NeedPhonePopup = ({default_values, invalid_phone_number, submit_btn_text, onSubmit, description}) => {
    const ctx = useRef({country_phone_code: PHONE_NUMBER_CODES[0]}).current;
    const {country_phone_code, phone_number} = default_values || {};
    const [error, setError] = useState(invalid_phone_number);

    useEffect(() => {
        if (default_values) {
            Object.assign(ctx, default_values);
        }
    }, [default_values])

    const submit = () => {
        let {country_phone_code, phone_number} = ctx;
        phone_number = phone_number?.trim();
        if (!country_phone_code || !phone_number) {
            setError(true)
        } else {
            onSubmit({country_phone_code, phone_number})
        } 
    }

    return (
        <div className='tw-p-4'>
            <label className='tw-text-sm tw-text-gray-600 tw-mb-6'>{description || 'Please provide your Whatsapp Phone Number to continue.'}</label>
            <div className='tw-rounded tw-border tw-flex tw-p-1 tw-w-full tw-mt-6'>
                <select className="tw-outline-none tw-text-md w3-padding-8 tw-mr-1" 
                    onChange={(e) => ctx.country_phone_code = e.target.value}
                    defaultValue={country_phone_code}
                >
                    {PHONE_NUMBER_CODES.map(code => (
                        <option key={code} value={code}>+{code}</option>
                    ))}
                </select>
                <input type='number' className='tw-flex-1 tw-outline-0 tw-text-md tw-border-l tw-pl-4 tw-appearance-none' 
                    placeholder='Phone Number' 
                    defaultValue={phone_number}
                    onChange={(e) => ctx.phone_number = e.target.value}
                />
            </div>
            {error ? <div className='tw-text-red-600 tw-text-xs tw-mt-1'>Please enter a valid Phone Number</div> : null}
            <button
                className={`tw-bg-secondary-lighter tw-w-full tw-py-2 tw-rounded tw-text-sm tw-mt-8 tw-text-white`}
                onClick={submit}
            >{submit_btn_text || 'Submit'}</button>
        </div>
    )
}

export default NeedPhonePopup;
