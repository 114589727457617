import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

function SelectDeliveryPoint({
    delivery_route, selected_delivery_point:_selected_delivery_point,
    onDeliveryPointSelected
}){
    const [selected_area, setSelectedArea] = useState(_selected_delivery_point?.area || undefined);

    return (
        <div className='w3-row w3-relative'  style={{"padding": "8px 8px 32px 8px"}}>
            <div className='w3-margin-bottom-32'>
            {
                delivery_route.delivery_points.map(
                    (dpoint, i) => {
                        let prev_dpoint = i && delivery_route.delivery_points[i-1];
                        return (
                            <React.Fragment key={dpoint.name}>
                                { /* area header */
                                    dpoint.area && (!prev_dpoint || prev_dpoint.area !== dpoint.area)
                                    ?   <div
                                            className='w3-flex w3-flex-vcenter w3-padding-8 w3-margin-topbottom w3-bold w3-border-top w3-large'
                                            
                                            onClick={
                                                () => {
                                                    setSelectedArea(
                                                        selected_area === dpoint.area
                                                        ? undefined
                                                        : dpoint.area
                                                    )
                                                }
                                            }
                                        >
                                            {dpoint.area}
                                            <FontAwesomeIcon
                                                className='w3-margin-left-auto w3-padding'
                                                icon={
                                                    dpoint.area === selected_area
                                                    ? faChevronDown
                                                    : faChevronRight
                                                }
                                            />
                                        </div>
                                    :   null
                                }
                                <label 
                                    className={
                                        `w3-flex w3-padding-8 tw-gap-4  ${dpoint.area ? "w3-padding-left": ""} ${dpoint.area && (dpoint.area !== selected_area) ? " w3-hide": ""}` 
                                    }
                                >
                                    <div className='w3-list tw-grow'>
                                        <div className='tw-flex tw-items-center tw-gap-4'>
                                            <div className='w3-bold'>{dpoint.name}</div>
                                            {
                                                dpoint.description
                                                ?   <span className='w3-round w3-tag'>{dpoint.description}</span>
                                                :   null
                                            }
                                        </div>
                                        {
                                            dpoint.address
                                            ?   <div className='w3-text-grey w3-small'>
                                                    {dpoint.address}
                                                </div>
                                            :   null
                                        }
                                    </div>
                                    <div className='w3-margin-left-auto w3-flex w3-flex-vcenter'>
                                        <input 
                                            className='w3-radio w3-input'
                                            type="radio"
                                            name={delivery_route._id + "_dpoint"}
                                            defaultChecked={_selected_delivery_point?.name === dpoint.name}
                                            onChange={
                                                (evt) => evt.target.checked && onDeliveryPointSelected(dpoint)
                                            }
                                        />
                                    </div>
                                </label>
                            </React.Fragment>
                        );
                    }
                    
                )
            }
            </div>
        </div>
    )
}

export default SelectDeliveryPoint;