import {screens} from 'tailwindcss/defaultTheme'
import { broadcaster, setBroadcastHookState } from 'base/utils/events';

function getScreensData() {
    return Object.entries(screens).reduce((result, [name, width]) => {
        const max_width = Number(width.split('px')[0]) - 1;
  
        const match_width = window.matchMedia(`(min-width: ${width})`);
        const match_max_width = window.matchMedia(`(max-width: ${max_width}px)`);
  
        result[name] = match_width?.matches;
        result[`max-${name}`] = match_max_width?.matches;
  
        return result;
    }, {})
}
  
// will be deregistered on window close
function onWindowResize() {
  setBroadcastHookState("tw_screen_size", getScreensData())
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

broadcaster.add_event_listener("viewport", onWindowResize);

(function() {onWindowResize();})();
